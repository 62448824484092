import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TextField,
} from "@mui/material"
import type { Organization } from "@phc-health/connect-query"
import { OrganizationStatusEnum } from "@phc-health/connect-query"
import { useCallback, useState } from "react"
import { useCreateOrganization } from "../../hooks/useCreateOrganization"

const DialogStyled = styled(Dialog)({
  ".MuiDialog-paper": {
    height: 220,
    justifyContent: "space-between",
  },
})

const ErrorText = styled("p")(() => ({
  paddingTop: "10px",
}))

const IconButtonStyled = styled(IconButton)({
  padding: 0,
  marginTop: -3,
})

interface AddOrganizationModalProps {
  orgsList: Organization[] | undefined
  isOpen: boolean
  toggle: () => void
}

const AddOrganizationModal = ({
  isOpen,
  toggle,
}: AddOrganizationModalProps) => {
  const { mutate, isError, isPending, error, reset } = useCreateOrganization()
  const [value, setValue] = useState<string>("")

  const handleConfirm = useCallback(() => {
    mutate(
      {
        organization: {
          name: value,
          displayName: value,
          status: OrganizationStatusEnum.ORGANIZATION_ACTIVE,
        },
      },
      {
        onSuccess: () => {
          toggle()
          reset()
        },
      }
    )
  }, [mutate, reset, toggle, value])

  const handleCancel = useCallback(() => {
    toggle()
    setValue("")
  }, [toggle])

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  return (
    <DialogStyled open={isOpen} title={"Add Organization"}>
      <DialogTitle>
        <span>{"Add Organization"}</span>
        <IconButtonStyled aria-label="close" size="small" onClick={toggle}>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitle>
      <TextField
        label="Organization Name"
        id="organization-input"
        onChange={onChange}
        value={value}
        error={isError}
        disabled={isPending}
      />
      {isError && <ErrorText>{error.message}</ErrorText>}
      <Stack spacing={2} direction="row-reverse">
        <Button variant="contained" onClick={handleConfirm} disabled={!value}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Stack>
    </DialogStyled>
  )
}

export default AddOrganizationModal
