import { styled } from "@mui/material"
import { extraColors } from "../../utils/theme"

const StyledTag = styled("span")({
  padding: "1px 6px",
  borderRadius: "60px",
  border: `1px solid ${extraColors.purpleDark}`,
  background: extraColors.purpleLight,
  color: extraColors.purpleDark,
  fontSize: 10,
  fontWeight: 600,
  width: "fit-content",
  height: "fit-content",
  display: "inline-flex",
})

export const BetaTag = () => <StyledTag>Beta</StyledTag>
