import { Box, Button, Skeleton, styled } from "@mui/material"
import type { NodeType } from "@portabletext/react"
import { PortableText } from "@portabletext/react"

import { SaveAlt } from "@mui/icons-material"
import { filterBlockArray, getFootnotes } from "@phc/common"
import type React from "react"
import { useMemo, useRef } from "react"
import { useNavigate, useParams } from "react-router"
import { useCapServiceContext } from "../../components/CriticalThreats/capServiceContext"
import { IconStyles } from "../../components/LocationDetails/LocationRisk/Actions/ActionsShared"
import { MainContent } from "../../components/MainContent"
import { GeotagsContainer } from "../../components/Map/Cards/GeotagsContainer"
import { PageContainer } from "../../components/PageContainer"
import { NotFoundContent } from "../../components/PageNotFound"
import {
  BodyContent,
  Content,
  Title,
  TopContentContainer,
} from "../../components/PostUI"
import type { PrintHandle } from "../../components/PrintTemplate"
import { PrintTemplate } from "../../components/PrintTemplate"
import { Tag } from "../../components/Shared/Tag"
import { capitalizeFirstLetter } from "../../utils/helpers/stringHelper"
import { trackEvent } from "../../utils/mixpanel"
import { theme } from "../../utils/theme"
import { Disclaimer } from "./Disclaimer"
import { Footnotes } from "./Footnotes"
import { PostDates, getPublishedAtDate } from "./PostDates"
import { useDynamicComponents } from "./hooks/useDynamicComponents"

const PageContainerStyled = styled(PageContainer)({
  border: "none",
})

/**
 * COMPONENTS
 */

const SinglePost: React.FC = () => {
  const { slug } = useParams()
  const printRef = useRef<PrintHandle>(null)
  const navigate = useNavigate()
  const { useSinglePost, sanityDataset } = useCapServiceContext()
  const { data: post, isLoading } = useSinglePost(slug ?? "")
  const postContent = post?.[0]

  const isDraft = postContent?._id.startsWith("drafts.")
  const title = isDraft
    ? `${postContent?.title ?? ""} (Draft)`
    : (postContent?.title ?? "")

  const { footnotesOrder } = useMemo(
    () => getFootnotes(filterBlockArray(postContent?.body ?? [])),
    [postContent?.body]
  )

  const dynamicComponents = useDynamicComponents(footnotesOrder, sanityDataset)

  const tags = useMemo(() => {
    return postContent?.categories?.map(item => item.title)
  }, [postContent?.categories])

  if (isLoading) {
    return <ContentLoading />
  }

  if (!post?.length) {
    return (
      <MainContent
        headerProps={{
          title: "Page Not Found",
        }}
      >
        <NotFoundContent />
      </MainContent>
    )
  }

  const publishedAtTimestamp = getPublishedAtDate({
    postPublishedAt: postContent?.publishedAt,
    postUpdatedAt: postContent?.updatedAt,
    postUpdatedAtAlternate: postContent?._updatedAt,
  })

  const postType =
    postContent?._type === "headlines" ? "Analysis" : (postContent?._type ?? "")

  return (
    <MainContent
      headerProps={{
        title:
          postType !== "guidance" && postType !== "Analysis"
            ? "FAQ"
            : capitalizeFirstLetter(postType),
      }}
    >
      <TopContentContainer>
        <Button
          variant="text"
          sx={{
            ...theme.typography.small1Bold,
            padding: 0,
          }}
          onClick={() => void navigate(-1)}
        >
          {"<"} Back
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<SaveAlt sx={{ ...IconStyles }} />}
          onClick={() => {
            if (printRef.current) {
              trackEvent("CLICK_SAVE_PDF")
              printRef.current.handlePrintClick()
            }
          }}
        >
          Save PDF
        </Button>
      </TopContentContainer>
      <PrintTemplate ref={printRef}>
        <PageContainerStyled>
          <Content>
            <BodyContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <PostDates
                  postPublishedAt={postContent?.publishedAt}
                  postUpdatedAt={postContent?._updatedAt}
                  postUpdatedAtAlternate={postContent?.updatedAt}
                />
                <Tag sx={{ fontSize: 13 }}>{tags}</Tag>
              </Box>
              <Title>{title}</Title>
              {postContent?._type === "headlines" ? (
                <GeotagsContainer geotags={postContent.geotags} />
              ) : null}
              <PortableText
                value={postContent?.body ?? []}
                components={dynamicComponents}
                onMissingComponent={onMissingComponent}
              />
              <Footnotes footnotesOrder={footnotesOrder} />
              {/* Workaround for not double publishing disclaimer and not being able to update previously published articles
                anything after 9/22/2022, 1pm PST will need the disclaimer below
              */}
              {publishedAtTimestamp.toDate().getTime() > 1663846357394 ? (
                <Disclaimer />
              ) : null}
            </BodyContent>
          </Content>
        </PageContainerStyled>
      </PrintTemplate>
    </MainContent>
  )
}

const ContentLoading: React.FC = () => {
  return (
    <MainContent
      headerProps={{
        title: "",
      }}
    >
      <PageContainer>
        <Content>
          <BodyContent>
            <Skeleton width={700} />
            <Skeleton width={700} height={550} />
          </BodyContent>
        </Content>
      </PageContainer>
    </MainContent>
  )
}

export const onMissingComponent = (
  message: string,
  options: {
    type: string
    nodeType: NodeType
  }
) => {
  // ignore comments
  if (options.nodeType === "mark" && options.type === "comment") {
    return null
  }
  console.warn(message, options)
}

export default SinglePost
