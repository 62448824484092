import type { Asset } from "@phc-health/connect-query"
import type React from "react"
import { createContext, useCallback, useContext, useState } from "react"
import type { AssetCSVRow } from "./assetSchema"

export enum UploadMode {
  APPEND = "append",
  SYNC = "sync",
}

export interface UploadRowError {
  row: number
  message: string
}

interface BulkAssetUploadContextState {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  uploadMode: UploadMode
  setUploadMode: React.Dispatch<React.SetStateAction<UploadMode>>
  newAssets: Asset[] | undefined
  setNewAssets: React.Dispatch<React.SetStateAction<Asset[] | undefined>>
  assetsToBeRemoved: Asset[] | undefined
  setAssetsToBeRemoved: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  uploadedFile: File | undefined
  setUploadedFile: React.Dispatch<React.SetStateAction<File | undefined>>
  downloadableErrorFile: Blob | undefined
  setDownloadableErrorFile: React.Dispatch<
    React.SetStateAction<Blob | undefined>
  >
  uploadIsComplete: boolean
  setUploadIsComplete: React.Dispatch<React.SetStateAction<boolean>>
  totalUploadedRowCount: number
  setTotalUploadedRowCount: React.Dispatch<React.SetStateAction<number>>
  totalJobs: number
  setTotalJobs: React.Dispatch<React.SetStateAction<number>>
  inProgressJobs: number
  setInProgressJobs: React.Dispatch<React.SetStateAction<number>>
  completedJobs: number
  setCompletedJobs: React.Dispatch<React.SetStateAction<number>>
  error?: string
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
  clearUpload: () => void
  handleModalClose: () => void
  preparedAssetErrors: AssetCSVRow[]
  setPreparedAssetErrors: React.Dispatch<React.SetStateAction<AssetCSVRow[]>>
  resetJobProgress: () => void
}

const BulkAssetUploadContext =
  createContext<BulkAssetUploadContextState | null>(null)

export const useBulkAssetContext = () => {
  const context = useContext(BulkAssetUploadContext)
  if (!context) {
    throw new Error(
      "useBulkAssetContext must be used within a BulkAssetContextProvider"
    )
  }
  return context
}

export const BulkAssetContextProvider: React.FC<{
  handleModalClose: () => void
  children: React.ReactNode
}> = ({ children, handleModalClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadMode, setUploadMode] = useState<UploadMode>(UploadMode.APPEND)
  const [newAssets, setNewAssets] = useState<Asset[]>()
  const [assetsToBeRemoved, setAssetsToBeRemoved] = useState<Asset[]>()
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [downloadableErrorFile, setDownloadableErrorFile] = useState<Blob>()
  const [uploadIsComplete, setUploadIsComplete] = useState(false)
  const [totalUploadedRowCount, setTotalUploadedRowCount] = useState(0)
  const [totalJobs, setTotalJobs] = useState(0)
  const [inProgressJobs, setInProgressJobs] = useState(0)
  const [completedJobs, setCompletedJobs] = useState(0)
  const [error, setError] = useState<string>()
  const [preparedAssetErrors, setPreparedAssetErrors] = useState<AssetCSVRow[]>(
    []
  )

  const resetJobProgress = useCallback(() => {
    setTotalJobs(0)
    setInProgressJobs(0)
    setCompletedJobs(0)
  }, [])

  const clearUpload = useCallback(() => {
    setError(undefined)
    setNewAssets(undefined)
    setAssetsToBeRemoved(undefined)
    setUploadedFile(undefined)
    setPreparedAssetErrors([])
  }, [])

  return (
    <BulkAssetUploadContext.Provider
      value={{
        isLoading,
        setIsLoading,
        uploadMode,
        setUploadMode,
        newAssets,
        setNewAssets,
        assetsToBeRemoved,
        setAssetsToBeRemoved,
        uploadedFile,
        setUploadedFile,
        downloadableErrorFile,
        setDownloadableErrorFile,
        uploadIsComplete,
        setUploadIsComplete,
        totalUploadedRowCount,
        setTotalUploadedRowCount,
        totalJobs,
        setTotalJobs,
        inProgressJobs,
        setInProgressJobs,
        completedJobs,
        setCompletedJobs,
        error,
        setError,
        clearUpload,
        handleModalClose,
        preparedAssetErrors,
        setPreparedAssetErrors,
        resetJobProgress,
      }}
    >
      {children}
    </BulkAssetUploadContext.Provider>
  )
}
