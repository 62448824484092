import { AssetType } from "@phc-health/connect-query"
import Papa from "papaparse"
import type { AssetCSVRow } from "./assetSchema"
import { useBulkAssetContext } from "./BulkAssetUploadContext"

export const useBuildErrorCSV = () => {
  const { setDownloadableErrorFile } = useBulkAssetContext()

  const buildErrorCSV = (csvErrorRows: AssetCSVRow[]) => {
    const rowsWithEmptyTypesRemoved = removeUnspecifiedTypes(csvErrorRows)
    const csvString = Papa.unparse(rowsWithEmptyTypesRemoved)
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
    setDownloadableErrorFile(blob)
  }

  return buildErrorCSV
}

const removeUnspecifiedTypes = (csvErrorRows: AssetCSVRow[]) => {
  return csvErrorRows.map(row => {
    return {
      ...row,
      Type:
        row.Type === AssetType.ASSET_TYPE_UNSPECIFIED ? undefined : row.Type,
    }
  })
}
