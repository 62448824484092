import type { Role, User } from "@phc-health/connect-query"
import { useCallback, useMemo, useState } from "react"
import validator from "validator"
import { useUpdateUser } from "../../hooks/useUpdateUser"
import { EditUserForm } from "./EditUserForm"
import { UserFormLoading } from "./UserFormShared"
import { UserManagementDialog } from "./UserManagementDialog"
import { useListAllTrials } from "./hooks/useManageAssetServiceUsers"

/**
 * INTERFACES
 */

interface EditUserDialogProps {
  user?: User
  onClose: () => void
  roles?: Role[]
}

/**
 * COMPONENTS
 */

export const EditUserDialog: React.FC<EditUserDialogProps> = ({
  user,
  onClose,
}) => {
  const { mutate } = useUpdateUser()
  const [updatedUser, setUpdatedUser] = useState<User | undefined>(user)
  const { data: trialData } = useListAllTrials()

  const userTrialStatus = useMemo(() => {
    return trialData?.organizationsUsersTrials.flatMap(org =>
      org.usersTrials.flatMap(usr =>
        usr.user?.primaryEmailAddress === user?.userId ? usr : []
      )
    )
  }, [trialData?.organizationsUsersTrials, user?.userId])

  console.log("USER: ", userTrialStatus)

  const handleSave = useCallback(() => {
    mutate(
      {
        user: updatedUser,
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }, [mutate, onClose, updatedUser])

  const canSave = useMemo(() => {
    return (
      updatedUser?.displayName &&
      !validator.isEmpty(updatedUser.displayName) &&
      updatedUser.userId &&
      validator.isEmail(updatedUser.userId) &&
      updatedUser.role &&
      updatedUser.status
    )
  }, [updatedUser])

  return (
    <UserManagementDialog
      isOpen
      onClose={onClose}
      title={"Edit User"}
      handleSave={handleSave}
      canSave={!!canSave}
    >
      {!updatedUser ? (
        <UserFormLoading />
      ) : (
        <EditUserForm
          updatedUser={updatedUser}
          setUpdatedUser={setUpdatedUser}
        />
      )}
    </UserManagementDialog>
  )
}
