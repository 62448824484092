import { UserSchema, type User } from "@phc-health/connect-query"
import type { ChangeEvent } from "react"
import { useCallback, useMemo } from "react"
import { useRoles } from "../../hooks/useRoles"

import { create } from "@bufbuild/protobuf"
import { TextField } from "@mui/material"
import { FormContainer, UserFormLoading } from "./UserFormShared"
import { UserRoleDropdown } from "./UserRoleDropdown"

/**
 * INTERFACES
 */

interface EditUserFormProps {
  newUser?: Partial<User>
  setUpdatedUser: (user: User) => void
}

/**
 * COMPONENTS
 */

export const AddUserForm: React.FC<EditUserFormProps> = ({
  newUser,
  setUpdatedUser,
}) => {
  const { data } = useRoles()

  const userRoleValue = useMemo(() => {
    return data?.roles.find(r => r.name === newUser?.role)
  }, [data?.roles, newUser?.role])

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const user = create(UserSchema, {
        displayName: event.target.value,
      })
      setUpdatedUser({ ...newUser, ...user })
    },
    [setUpdatedUser, newUser]
  )

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const user = create(UserSchema, {
        userId: event.target.value,
      })
      setUpdatedUser({ ...newUser, ...user })
    },
    [setUpdatedUser, newUser]
  )

  const handleRoleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const user = create(UserSchema, {
        role: event.target.value,
      })
      setUpdatedUser({ ...newUser, ...user })
    },
    [setUpdatedUser, newUser]
  )

  if (!data?.roles) return <UserFormLoading />

  return (
    <FormContainer>
      <TextField
        label="Name"
        required
        value={newUser?.displayName || ""}
        onChange={handleNameChange}
      />
      <TextField
        label="Email"
        required
        value={newUser?.userId || ""}
        onChange={handleEmailChange}
      />
      <UserRoleDropdown
        userRoleName={userRoleValue?.name}
        roles={data.roles}
        onChange={handleRoleChange}
        userEmail={newUser?.userId}
      />
    </FormContainer>
  )
}
