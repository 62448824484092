import {
  Autocomplete,
  Box,
  Button,
  Chip,
  TextField,
  type AutocompleteRenderGetTagProps,
  type FilterOptionsState,
} from "@mui/material"
import type { AssetGroup } from "@phc-health/connect-query"
import { AssetGroupSchema } from "@phc-health/connect-query"

import { create } from "@bufbuild/protobuf"
import { DeleteForever } from "@mui/icons-material"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import { extraColors } from "../../../utils/theme"
import { MIXED_TEXT } from "./EditAssetsDialog"
import { GroupDeleteDialog } from "./GroupDeleteDialog"

export interface GroupWithLabel {
  group: AssetGroup
  label?: string
}

interface AssetGroupAutocompleteProps {
  groups: AssetGroup[]
  setGroups: React.Dispatch<React.SetStateAction<AssetGroup[] | undefined>>
  selectedGroups?: AssetGroup[]
  setSelectedGroups: (assetGroups: AssetGroup[]) => void
  showMixed?: boolean
}

interface GroupTagsProps {
  value: GroupWithLabel[]
  setSelectedGroups: (assetGroups: AssetGroup[]) => void
  getTagProps: AutocompleteRenderGetTagProps
}

export const AssetGroupAutocomplete: React.FC<AssetGroupAutocompleteProps> = ({
  groups,
  setGroups,
  selectedGroups,
  setSelectedGroups,
  showMixed,
}) => {
  const [groupToDelete, setGroupToDelete] = useState<GroupWithLabel>()

  const groupMap = useMemo(() => {
    return groups.reduce((byName, group) => {
      return byName.set(group.name, group)
    }, new Map<string, AssetGroup>())
  }, [groups])

  const selectedGroupMap = useMemo(() => {
    return selectedGroups?.reduce((byName, group) => {
      byName.set(group.name, group)
      return byName
    }, new Map<string, AssetGroup>())
  }, [selectedGroups])

  const groupsWithLabels = useMemo(() => {
    const withLabels = groups.map(group => ({
      group: group,
      label: group.name,
    }))

    // MIXED_TEXT is added to this list and filtered out at display time to avoid autocomplete mui browser warning
    withLabels.push({
      group: create(AssetGroupSchema, {
        name: MIXED_TEXT,
        assetGroupId: MIXED_TEXT,
      }),
      label: MIXED_TEXT,
    })

    return withLabels
  }, [groups])

  const selectedGroupsWithLabels = useMemo(() => {
    const selected =
      selectedGroups?.map(group => {
        return { group: group, label: group.name }
      }) || []

    if (showMixed && !selected.find(s => s.label === MIXED_TEXT)) {
      const mixedGroup = create(AssetGroupSchema, {
        assetGroupId: MIXED_TEXT,
        name: MIXED_TEXT,
      })
      selected.push({ group: mixedGroup, label: MIXED_TEXT })
    }

    return selected
  }, [selectedGroups, showMixed])

  const onChangeAutocomplete = useCallback(
    (newValue: readonly GroupWithLabel[]) => {
      // When a value changes, check to see if there are any values in newValue that don't
      // exist in the groups list or the selected groups list.
      const updatedSelectedGroups = [...(selectedGroups || [])]
      const updatedGroups = [...groups]

      newValue.forEach(g => {
        const newGroup = create(AssetGroupSchema, {
          assetGroupId: g.group.assetGroupId,
          name: g.group.name,
        })

        // If they aren't in the selected groups list, add them.
        if (!selectedGroupMap?.has(newGroup.name)) {
          updatedSelectedGroups.push(newGroup)
          setSelectedGroups(updatedSelectedGroups)
        }

        // If they aren't in the full groups list, add them.
        if (!groupMap.has(newGroup.name)) {
          updatedGroups.push(newGroup)
          setGroups(updatedGroups)
        }
      })
    },
    [
      groupMap,
      groups,
      selectedGroupMap,
      selectedGroups,
      setGroups,
      setSelectedGroups,
    ]
  )

  return (
    <>
      <Autocomplete
        disableClearable
        multiple
        id="tags-outlined"
        autoHighlight
        options={groupsWithLabels}
        filterSelectedOptions
        value={selectedGroupsWithLabels}
        isOptionEqualToValue={(option, value) => {
          return option.label === value.label
        }}
        renderTags={(value, getTagProps) => (
          <>
            {showMixed && (
              <Chip
                label={MIXED_TEXT}
                key={MIXED_TEXT}
                sx={{ color: extraColors.medium, maxHeight: "22px" }}
              />
            )}
            <GroupTags
              value={value}
              getTagProps={getTagProps}
              setSelectedGroups={setSelectedGroups}
            />
          </>
        )}
        getOptionLabel={(group: GroupWithLabel) =>
          group.label || group.group.name
        }
        renderOption={(props, option) => (
          <GroupDropdownOption
            key={option.group.assetGroupId}
            option={option}
            props={props}
            setGroupToDelete={setGroupToDelete}
          />
        )}
        filterOptions={(options, params) =>
          filterOptions(
            options,
            params,
            selectedGroupsWithLabels,
            selectedGroupMap
          )
        }
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Groups" />
        )}
        onChange={(_, newValue) => {
          onChangeAutocomplete(newValue)
        }}
      />
      {groupToDelete && (
        <GroupDeleteDialog
          groupToDelete={groupToDelete}
          setGroupToDelete={setGroupToDelete}
          setGroups={setGroups}
        />
      )}
    </>
  )
}

const GroupDropdownOption: React.FC<{
  option: GroupWithLabel
  props: React.HTMLAttributes<HTMLLIElement>
  setGroupToDelete: (
    value: React.SetStateAction<GroupWithLabel | undefined>
  ) => void
}> = ({ option, props, setGroupToDelete }) => {
  return (
    <Box
      component="li"
      sx={{ "& > img": { mr: 2, flexShrink: 0 }, alignItems: "center" }}
      {...props}
      key={option.group.assetGroupId}
    >
      <>{option.label || option.group.name}</>
      <Button
        sx={{ padding: 0, minWidth: "unset", alignSelf: "center" }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          setGroupToDelete(option)
        }}
      >
        <DeleteForever
          sx={{
            color: extraColors.purpleMiddle,
            height: "18px",
          }}
        />
      </Button>
    </Box>
  )
}

const GroupTags: React.FC<GroupTagsProps> = ({
  value,
  setSelectedGroups,
  getTagProps,
}) => {
  return (
    <>
      {value.map((g, index) =>
        g.group.assetGroupId === MIXED_TEXT ? null : (
          <Chip
            {...getTagProps({ index })}
            sx={{ maxHeight: "22px" }}
            key={index}
            onDelete={() => {
              const selectedTags: AssetGroup[] = value
                .filter((_, i) => i !== index)
                .map(v => {
                  return create(AssetGroupSchema, {
                    name: v.group.name,
                    assetGroupId: v.group.assetGroupId,
                  })
                })
              setSelectedGroups(...[selectedTags])
            }}
            label={g.group.name}
          />
        )
      )}
    </>
  )
}

const filterOptions = (
  options: GroupWithLabel[],
  params: FilterOptionsState<GroupWithLabel>,
  selectedGroupsWithLabels: GroupWithLabel[] | undefined,
  selectedGroupMap?: Map<string, AssetGroup>
) => {
  const { inputValue } = params

  const filtered: GroupWithLabel[] = options.filter(
    option =>
      !selectedGroupMap?.has(option.group.name) &&
      option.group.name.toLowerCase().includes(inputValue.toLowerCase()) &&
      option.label !== MIXED_TEXT
  )

  filtered.sort((a, b) => a.label?.localeCompare(b.label || "") || -1)

  const isExisting =
    options.some(option => inputValue === option.label) ||
    selectedGroupsWithLabels?.some(option => inputValue === option.label)

  // If the value isn't in the list of groups, suggest a new value in the dropdown
  if (inputValue !== "" && !isExisting) {
    filtered.push({
      group: create(AssetGroupSchema, { name: inputValue }),
      label: `Add "${inputValue}"`,
    })
  }

  return filtered
}
