import { Skeleton, Typography, styled } from "@mui/material"
import type React from "react"
import { Link } from "react-router"
import { ROUTES } from "../../../../utils/constants"
import { getLocationId } from "../../../../utils/helpers"
import { BORDER_RADIUS, extraColors } from "../../../../utils/theme"
import { useListAssets } from "../../../WatchedLocations/hooks/useAssetService"
import SvgVisibleOutlined from "../../../icons/VisibleOutlined"
import { LocationCard } from "../../LocationCard/LocationCard"
import { getMapBreakpoint } from "../../Shared"

const Card = styled("div")({
  backgroundColor: extraColors.translucentWhite,
  boxShadow: "inset -1px -1px 0px #F1F1F5",
  padding: 16,
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  gridGap: 10,
  border: `1px solid ${extraColors.disabled}`,
  borderRadius: BORDER_RADIUS,
  maxWidth: 360,
  maxHeight: 165,
})

const Header = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}))

const SavedCards = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: 8,
  gridAutoFlow: "row",
  maxWidth: 336,
  [getMapBreakpoint(theme)]: {
    gridAutoFlow: "column",
    minWidth: "max-content",
    padding: "8px 0px",
  },
}))

export const LocationItems: React.FC = () => {
  const { data: assetData, isLoading } = useListAssets({ includeGlobal: false })

  if (isLoading) return <LoadingCards />

  if (!assetData.assets.length) {
    return <NoWatchedLocations />
  }
  // todo: lazy load cards on scroll
  const renderLimit = 75
  return (
    <SavedCards>
      {/* // TODO: limit to 100 cards, and then add see more */}
      {assetData.assets.slice(0, renderLimit).map(asset => {
        const locationId = getLocationId(asset)
        if (!locationId) return null
        return (
          <LocationCard
            key={asset.assetId}
            id={locationId}
            assetId={asset.assetId}
            isMapCard={false}
          />
        )
      })}
      {assetData.assets.length > renderLimit && (
        <Typography
          component={Link}
          to={ROUTES.ASSETS}
          variant="small1Bold"
          color={extraColors.dark}
        >
          See +{assetData.assets.length - renderLimit} more
        </Typography>
      )}
    </SavedCards>
  )
}

const NoWatchedLocations: React.FC = () => {
  return (
    <Card sx={{ maxWidth: "346px", maxHeight: "fit-content" }}>
      <div>
        <Typography variant="body1" color={extraColors.dark} p={2}>
          Once selected, use the{" "}
          <SvgVisibleOutlined style={{ marginBottom: -5, marginRight: 6 }} />
          <Typography variant="small1Bold">Watch</Typography> button to save
          your locations.
        </Typography>

        <Typography variant="body1" p={2} color={extraColors.dark}>
          You can receive notification updates and monitor their risk assessment
          in this panel or from the Assets screen.
        </Typography>
      </div>
    </Card>
  )
}

const LoadingCards: React.FC = () => {
  return (
    <Card sx={{ maxWidth: "346px" }}>
      <Skeleton variant="rounded" width={8} height={13} />
      <Header>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Header>
      <Skeleton variant="rectangular" width={140} height={80} />
    </Card>
  )
}
