import { useCallback, useEffect, useRef } from "react"
import type { URLSearchParamsInit } from "react-router"
import { useSearchParams as useRRSearchParams } from "react-router"
import {
  isLocationDetailsTab,
  type LocationDetailsTab,
} from "../components/LocationDetails/LocationDetailsTabs"
import { TRUE } from "../utils/constants"
import { removeNullAndUndefined } from "../utils/helpers"

export type SetSearchParams = ReturnType<
  typeof useSearchParams
>["setSearchParams"]

interface Params {
  id?: string
  mapboxId?: string
  title?: string
  subtitle?: string
  organizationId?: string
  organizationName?: string
  userId?: string
  userName?: string
  assetId?: string
  fitBounds?: boolean
  centroid?: string
  affectedByCriticalThreat?: "true" | undefined
  assetType?: string
  group?: string
  searchText?: string
  tab?: LocationDetailsTab
}

export const useSearchParams = () => {
  const [searchParams, setRRSearchParams] = useRRSearchParams()

  // set function was causing map to rerender... we can't have that
  // potential footgun: race condition if you call this too soon 🤷
  const setSearchRef = useRef(setRRSearchParams)
  useEffect(() => {
    setSearchRef.current = setRRSearchParams
  }, [setRRSearchParams])

  const setSearchParams = useCallback((newParams: Partial<Params> | null) => {
    if (!newParams) {
      setSearchRef.current(undefined)
      return
    }
    const currentParams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    )
    const equal = Object.entries(newParams).every(
      ([key, value]) => currentParams[key] === value
    )
    if (equal) return
    setSearchRef.current(
      removeNullAndUndefined({
        ...newParams,
      }) as URLSearchParamsInit,
      {
        replace: true,
      }
    )
  }, [])

  const tab = searchParams.get("tab")
  const locationDetailsTab = isLocationDetailsTab(tab) ? tab : undefined

  const params: Params = {
    id: searchParams.get("id") ?? undefined,
    mapboxId: searchParams.get("mapboxId") ?? undefined,
    title: searchParams.get("title") ?? undefined,
    subtitle: searchParams.get("subtitle") ?? undefined,
    organizationId: searchParams.get("organizationId") ?? undefined,
    organizationName: searchParams.get("organizationName") ?? undefined,
    userId: searchParams.get("userId") ?? undefined,
    userName: searchParams.get("userName") ?? undefined,
    assetId: searchParams.get("assetId") ?? undefined,
    fitBounds: searchParams.get("fitBounds") === TRUE ? true : undefined,
    centroid: searchParams.get("centroid") ?? undefined,
    affectedByCriticalThreat:
      searchParams.get("affectedByCriticalThreat") === TRUE ? TRUE : undefined,
    assetType: searchParams.get("assetType") ?? undefined,
    group: searchParams.get("group") ?? undefined,
    searchText: searchParams.get("searchText") ?? undefined,
    tab: locationDetailsTab,
  }

  return {
    ...params,
    setSearchParams,
    params,
  }
}
