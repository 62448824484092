import { useParams } from "react-router"
import { useGetAsset } from "../components/WatchedLocations/hooks/useAssetService"
import { getLocationId } from "../utils/helpers"
import { useSearchParams } from "./useSearchParams"

/**
 * Hook for location details pages to retrieve
 * the locationId based on first the asset ID
 * and if not at asset, the id search param.
 * @returns The PHC locationId.
 */
export const useLocationId = () => {
  const { assetId } = useParams<{ assetId: string }>()
  const { id } = useSearchParams()

  const { data, isLoading } = useGetAsset(assetId)
  if (isLoading) return

  const locationId = getLocationId(data?.asset) || id
  return locationId
}
