import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query"
import {
  AccessManagementService,
  AccessManagementServiceQuery,
} from "@phc-health/connect-query"
import { useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useUpdateOrganization() {
  const transport = useTransport(ACCESS_API)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(AccessManagementServiceQuery.updateOrganization, {
    transport,
    onError: err => {
      console.error(err)
      enqueueSnackbar("Failed to update organization", {
        variant: "error",
      })
    },
    onSettled: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey({
            schema: AccessManagementService.method.updateOrganization,
            transport,
            cardinality: "finite",
          }),
        })
        enqueueSnackbar("Organization updated successfully", {
          variant: "success",
        })
      } catch (error) {
        console.error(error)
        enqueueSnackbar("Failed to update organization", {
          variant: "error",
        })
      }
    },
  })
}
