import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Button, styled } from "@mui/material"
import type React from "react"
import { Link, generatePath } from "react-router"
import { ROUTES, stringifySearchParams } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"

const ButtonStyled = styled(Button)(({ theme }) => ({
  ...theme.typography.body2Bold,
  padding: 0,
  marginTop: "auto",
  justifyContent: "end",
  alignItems: "end",
  color: extraColors.status.teal,
  width: "fit-content",
  height: "auto",
  justifySelf: "center",
  "&:hover": {
    color: extraColors.status.tealDark,
  },
})) as typeof Button

interface ViewButtonProps {
  id: string
  assetId?: string
  title?: string
  subtitle?: string
}

export const ViewButton: React.FC<ViewButtonProps> = ({
  id,
  assetId,
  title,
  subtitle,
}) => {
  return (
    <ButtonStyled
      component={Link}
      to={
        assetId
          ? generatePath(ROUTES.ASSET, {
              assetId: assetId || "",
            })
          : {
              pathname: ROUTES.LOCATION_DETAILS,
              search: stringifySearchParams({
                id,
                title,
                subtitle,
              }),
            }
      }
      data-testid="saved-location-details-button"
    >
      <ChevronRightIcon sx={{ color: extraColors.purple }} />
    </ButtonStyled>
  )
}
