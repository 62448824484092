import { create } from "@bufbuild/protobuf"
import { EditOutlined, PersonAdd } from "@mui/icons-material"
import { MenuItem, Select, styled, useTheme } from "@mui/material"
import type { User } from "@phc-health/connect-query"
import { UserSchema, UserStatusEnum } from "@phc-health/connect-query"
import { useMemo, useState } from "react"
import SvgTriangle from "../../assets/Triangle"
import { isPHCEmail, useIsPHCEmployee } from "../../contexts/IsEmployeeContext"
import { usePermissions } from "../../hooks/usePermissions"
import { useRoles } from "../../hooks/useRoles"
import { useSearchParams } from "../../hooks/useSearchParams"
import { useUpdateUser } from "../../hooks/useUpdateUser"
import { useUsers } from "../../hooks/useUsers"
import { getUserStatusNameFromStatus } from "../../utils/helpers/identityHelpers"
import { BaseGrid } from "../BaseGrid/BaseGrid"
import Button from "../Shared/Button"
import { AddUserDialog } from "./AddUserDialog"
import { EditUserDialog } from "./EditUserDialog"
import { getRolesByAvailablility } from "./UserFormShared"

const PersonAddStyled = styled(PersonAdd)({
  marginRight: 10,
})

const EditOutlinedStyled = styled(EditOutlined)({
  fontSize: 14,
  marginLeft: 4,
  marginBottom: 2,
})

const BaseGridContainer = styled("div")({
  ".MuiInputBase-root": {
    height: 40,
  },
  "MuiDataGrid-withBorderColor": {
    border: "none",
  },
})

const SelectContainer = styled("div")({
  ".MuiOutlinedInput-root": {
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
  },
})

const UserTable: React.FC = () => {
  const theme = useTheme()
  const { organizationId } = useSearchParams()
  const orgId = organizationId ?? ""
  const { isEmployee } = useIsPHCEmployee()

  const { isLoading, data } = useUsers({
    parent: orgId,
    // todo: return to TABLE_ROWS default when server side sorting is implemented
    pageSize: 100,
  })

  const { mutate } = useUpdateUser()

  const rolesData = useRoles()
  const permissions = usePermissions()

  const [addUserModalOpen, setAddUserModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>()

  const onClickEdit = (user: User) => {
    setSelectedUser({ ...user })
  }

  const onCloseEditUserModal = () => {
    setSelectedUser(undefined)
  }

  const onCloseAddUserModal = () => {
    setAddUserModalOpen(false)
  }

  const visibleUsers = useMemo(() => {
    if (isEmployee) return data?.users

    return data?.users.filter(user => !isPHCEmail(user.userId))
  }, [data?.users, isEmployee])

  const rows = useMemo(() => {
    return (visibleUsers ?? []).map(user => ({
      id: user.name,
      name: user.displayName,
      role: user.role,
      status: user.status,
      email: user.userId,
      displayName: user.displayName,
      nameWithPath: user.name,
    }))
  }, [visibleUsers])

  return (
    <BaseGridContainer>
      <BaseGrid
        data-testid="user-table"
        placeholder="Search Users"
        checkbox={false}
        loading={isLoading}
        rowCount={visibleUsers?.length ?? 0}
        data-hidetoolbar={true}
        columns={[
          { field: "", headerName: "", flex: 0, width: 90 },
          { field: "name", headerName: "Name", flex: 1 },
          {
            field: "role",
            headerName: "Role",
            width: 450,
            renderCell: rowData => {
              if (!rolesData.data?.roles) return null

              const isPHCAdmin =
                !!permissions.phcAdminUser.organizations.users.write
              const isAdmin =
                isPHCAdmin ||
                !!permissions.standardAdminUser.organizations.users.write

              const rolesByAvailablility = getRolesByAvailablility(
                rolesData.data.roles,
                isAdmin,
                isPHCAdmin,
                rowData.id.toString()
              )

              return (
                <SelectContainer>
                  <Select
                    data-testid={`user-table-role-select-${rowData.row.id}`}
                    value={rowData.row.role}
                    disabled={!isAdmin}
                    IconComponent={props => (
                      <SvgTriangle {...props} style={{ top: 16 }} />
                    )}
                    onChange={event => {
                      const user = data?.users.find(u => u.name === rowData.id)
                      if (!user) {
                        throw new Error("User not found when changing role")
                      }
                      if (user.role === event.target.value) {
                        return
                      }

                      mutate({
                        user: {
                          ...user,
                          role: event.target.value || "",
                        },
                        updateMask: {
                          paths: ["role"],
                        },
                      })
                    }}
                  >
                    {rolesByAvailablility.map((entry, _) => {
                      return (
                        <MenuItem
                          key={entry[0].name}
                          value={entry[0].name}
                          divider
                          disableRipple
                          disabled={!entry[1]}
                        >
                          {entry[0].displayName}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </SelectContainer>
              )
            },
          },
          {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: rowData => {
              const rawStatus = rowData.row.status
              const status = getUserStatusNameFromStatus(rawStatus)
              return (
                <div
                  style={
                    rawStatus === UserStatusEnum.USER_INVITED
                      ? { color: theme.palette.text.disabled }
                      : {}
                  }
                >
                  {status}
                </div>
              )
            },
          },
          {
            field: "memberControl",
            headerName: "",
            cellClassName: "memberControlCell",
            sortable: false,
            filterable: false,
            width: 150,
            renderCell: cellValues => {
              const user = create(UserSchema, {
                displayName: cellValues.row.displayName,
                role: cellValues.row.role,
                status: cellValues.row.status,
                userId: cellValues.row.email,
                name: cellValues.row.nameWithPath,
              })

              return (
                <Button
                  sx={{
                    ...theme.typography.small1Bold,
                  }}
                  className="row-control-remove-button"
                  onClick={onClickEdit.bind(this, user)}
                >
                  Edit <EditOutlinedStyled />
                </Button>
              )
            },
          },
        ]}
        rows={rows}
        filter={false}
        headerActionButton={
          <AddUserButton setAddUserModalOpen={setAddUserModalOpen} />
        }
        disableMultipleRowSelection
      />
      {selectedUser && (
        <EditUserDialog
          user={selectedUser}
          onClose={onCloseEditUserModal}
          roles={rolesData.data?.roles}
        />
      )}
      <AddUserDialog
        onClose={onCloseAddUserModal}
        roles={rolesData.data?.roles}
        isOpen={addUserModalOpen}
      />
    </BaseGridContainer>
  )
}

const AddUserButton: React.FC<{
  setAddUserModalOpen: (val: boolean) => void
}> = ({ setAddUserModalOpen }) => {
  const permissions = usePermissions()
  const canAddUser =
    permissions.phcAdminUser.organizations.users.write ||
    permissions.standardAdminUser.organizations.users.write

  return (
    <>
      {canAddUser && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddUserModalOpen(true)}
          style={{ width: "160px", textTransform: "uppercase" }}
        >
          <PersonAddStyled /> Add User
        </Button>
      )}
    </>
  )
}

export default UserTable
