import type { RouteObject } from "react-router"
import { Navigate } from "react-router"
import { AdminSettings } from "./components/AccountManagement/AdminSettings"
import { AlertsPage } from "./components/Alerts/Alerts"
import { CriticalThreat } from "./components/CriticalThreats/CriticalThreat"
import { CriticalThreats } from "./components/CriticalThreats/CriticalThreats"
import { Documentation } from "./components/Documentation/Documentation"
import { Map } from "./components/Map/MapPage"
import { Settings } from "./components/Settings/Settings"
import { WatchedLocations } from "./components/WatchedLocations/WatchedLocations"
import { Analyses } from "./pages/Analyses"
import { Asset } from "./pages/Asset"
import { Authenticate } from "./pages/Authenticate"
import { ErrorPage } from "./pages/ErrorPage"
import { Faq } from "./pages/Faq"
import Guidance from "./pages/Guidance"
import ManageOrganization from "./pages/ManageOrganization"
import { NotFound } from "./pages/NotFound"
import SignIn from "./pages/SignIn"
import SinglePost from "./pages/SinglePost/SinglePost"
import Sources from "./pages/Sources"
import { Texts } from "./pages/Texts"
import Users from "./pages/Users"
import { WatchedLocation } from "./pages/WatchedLocation"
import { WorkforcePlanning } from "./pages/WorkforcePlanning"
import { ROUTES } from "./utils/constants"

export const RouteConfig: RouteObject[] = [
  {
    path: ROUTES.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: ROUTES.BASE,
    element: <Navigate replace to={ROUTES.MAP} />,
  },
  { path: ROUTES.MAP, element: <Map />, index: true },
  {
    path: ROUTES.HOME,
    element: <Navigate replace to={ROUTES.MAP} />,
  },
  { path: ROUTES.GUIDANCE, element: <Guidance /> },
  { path: ROUTES.ANALYSES, element: <Analyses /> },
  { path: ROUTES.ALERTS, element: <AlertsPage /> },
  {
    path: ROUTES.WORKFORCE_PLANNING,
    element: <WorkforcePlanning />,
  },
  {
    path: ROUTES.PREFERENCES,
    element: <Settings />,
  },
  {
    path: ROUTES.DOCUMENTATION,
    element: <Documentation />,
  },
  {
    path: ROUTES.ASSETS,
    element: <WatchedLocations />,
  },
  { path: ROUTES.ASSET, element: <Asset /> },
  {
    path: ROUTES.LOCATION_DETAILS,
    element: <WatchedLocation />,
  },
  { path: ROUTES.GUIDANCE_POST, element: <SinglePost /> },
  { path: ROUTES.ANALYSIS_POST, element: <SinglePost /> },
  { path: ROUTES.FAQ, element: <Faq /> },
  { path: ROUTES.FAQ_POST, element: <SinglePost /> },
  {
    path: ROUTES.CRITICAL_THREATS,
    element: <CriticalThreats />,
  },
  {
    path: ROUTES.CRITICAL_THREAT,
    element: <CriticalThreat />,
  },
  { path: ROUTES.SOURCES, element: <Sources /> },
  {
    path: ROUTES.ORGANIZATIONS,
    element: <AdminSettings />,
  },
  {
    path: ROUTES.USERS,
    element: <Users />,
  },
  { path: ROUTES.PREFERENCES, element: <Settings /> },
  {
    path: ROUTES.USERS,
    element: <Users />,
  },
  {
    path: ROUTES.MANAGE_ORGANIZATION,
    element: <ManageOrganization />,
  },
  { path: ROUTES.ERROR, element: <ErrorPage /> },
  { path: ROUTES.LOGIN, element: <Authenticate /> },
  { path: ROUTES.PRIVACY_POLICY, element: <Texts /> },
  { path: ROUTES.TERMS_AND_CONDITIONS, element: <Texts /> },
  { path: "*", element: <NotFound /> },
] // TODO: refactor ROUTES config to handle child routes
