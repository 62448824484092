import { useAuth0 } from "@auth0/auth0-react"
import { styled, useMediaQuery } from "@mui/material"
import { LicenseInfo } from "@mui/x-license"
import { useQueryClient } from "@tanstack/react-query"
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk"
import type React from "react"
import { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router"
import { MobileHeader } from "./components/AppHeader/MobileHeader"
import { ASK_ANCHOR_ID } from "./components/Ask/AskDialog"
import { CriticalThreatBanner } from "./components/Banners/CriticalThreatBanner"
import { StaleDataBanner } from "./components/Banners/StaleDataBanner"
import { CapServiceContextProvider } from "./components/CriticalThreats/capServiceContext"
import DrawerNav from "./components/DrawerNav"
import { TermsModal } from "./components/TermsModal"
import { TermsOfServiceModal } from "./components/TermsOfService/TermsOfServiceModal"
import { IsEmployeeContextProvider } from "./contexts/IsEmployeeContext"
import { useOrganization } from "./hooks/useOrganization"
import { useOrganizationId } from "./hooks/useOrganizationId"
import { usePermissions } from "./hooks/usePermissions"
import { useStoreInitialUrl } from "./hooks/useStoreInitialUrl"
import { useUser } from "./hooks/useUser"
import { ROUTES } from "./utils/constants"
import { useMixpanel } from "./utils/mixpanel"
import { theme } from "./utils/theme"

const UNPROTECTED_ROUTES: string[] = [
  ROUTES.SIGN_IN,
  ROUTES.TERMS_AND_CONDITIONS,
  ROUTES.PRIVACY_POLICY,
]

const Layout = styled("div")({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  gridTemplateAreas: `'nav banner'
                      'nav content'`,
  alignContent: "start",
  justifyContent: "start",
  width: "100%",
  height: "100%",
  zIndex: 2,
})

const Content = styled("div")({
  display: "grid",
  position: "relative",
  padding: "0 24px",
  [theme.breakpoints.down("md")]: {
    padding: "0 12px",
  },
})

const ContentGradient = styled("div")({
  background:
    "linear-gradient(270deg, #FFBF34 5.83%, #810099 52.08%, #18345E 94.08%)",
  opacity: 0.8,
  height: 3,
  position: "absolute",
  zIndex: 1,
  width: "100%",
})

const LayoutContainer = styled("div")({
  position: "relative",
  zIndex: 999,
  height: "100vh",
  display: "grid",
  gridTemplateRows: "1fr",
  [theme.breakpoints.down("md")]: {
    gridTemplateRows: "min-content 1fr",
  },
})

const RequireAuth: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const location = useLocation()
  const client = useQueryClient()

  const isUnprotectedRoute = UNPROTECTED_ROUTES.includes(location.pathname)

  if (isAuthenticated || isLoading || isUnprotectedRoute) {
    return null
  }

  // Redirect them to the sign in page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  client.clear()
  return (
    <Navigate
      to={{ pathname: ROUTES.SIGN_IN, search: location.search }}
      state={{ from: location }}
    />
  )
}

LicenseInfo.setLicenseKey(import.meta.env.MUI_LICENSE)

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { isAuthenticated, isLoading, error, user: authUser } = useAuth0()
  const ldClient = useLDClient()
  const organizationId = useOrganizationId()
  const { data: user } = useUser({
    orgId: organizationId.data,
    email: authUser?.email,
  })
  const orgId = useOrganizationId()

  const { data: organization } = useOrganization(orgId.data ?? "")

  const location = useLocation()
  const permissions = usePermissions()

  useMixpanel()
  // this hook captures page views across the application and sends the data to mixpanel

  useStoreInitialUrl()

  useEffect(() => {
    if (isAuthenticated && user) {
      //set the user for launch darkly
      void ldClient?.identify({
        kind: "user",
        key: user.userId,
        name: user.name,
        organization: organization?.name ?? "",
        role: user.role.split("/")[1],
      })
    }
  }, [isAuthenticated, ldClient, organization?.name, user])

  if (error && location.pathname !== ROUTES.ERROR) {
    return <Navigate to={{ pathname: ROUTES.ERROR, search: location.search }} />
  }

  if (isLoading || permissions.isLoading) {
    return null
  }

  return (
    <IsEmployeeContextProvider userEmail={authUser?.email}>
      <CapServiceContextProvider>
        <LayoutContainer>
          <RequireAuth />
          {isAuthenticated && isMobile && <MobileHeader />}
          <Layout>
            <ContentGradient data-testid="content-gradient" />
            <ProtectedLayoutComponents />
            <Content
              id={ASK_ANCHOR_ID}
              sx={{
                gridTemplateRows: "auto 1fr",
                gridArea: "content",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </LayoutContainer>
      </CapServiceContextProvider>
    </IsEmployeeContextProvider>
  )
}

const ProtectedLayoutComponents: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { showTrialTermsOfService } = useFlags()
  const { isAuthenticated, user: authUser } = useAuth0()

  const isFirstTimeUser =
    !!authUser?.["https://thepublichealthco.com/v1/is_first_login"]

  if (!isAuthenticated) return null

  return (
    <>
      <div style={{ gridArea: "nav" }}>{!isMobile && <DrawerNav />}</div>
      {showTrialTermsOfService ? (
        <TermsOfServiceModal />
      ) : (
        <TermsModal isFirstTimeUser={isFirstTimeUser} />
      )}
      <div style={{ gridArea: "banner", alignContent: "start" }}>
        <StaleDataBanner />
        <CriticalThreatBanner />
      </div>
    </>
  )
}

export default App
