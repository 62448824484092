import type { Geotag } from "./cms-schema/sanityTypesManual"

export const SANITY_PROJECT_ID = "i46wuz36"
export type PHC_ENV =
  | "int"
  | "sbx"
  | "stg"
  | "prd"
  | (string & Record<never, never>)
export const ENV_PROCESS: PHC_ENV =
  typeof process !== "undefined" ? process.env.ENV || "sbx" : "undefined"

/** @deprecated
 * This endpoint doesn't exist anymore
 */
export const getApiDomain = (env: PHC_ENV) =>
  `https://rest.${env}.thepublichealthco.com` as const

/** @deprecated
 * Get value from environment variable
 */
export const getAuth0Domain = (env: PHC_ENV) =>
  `thepublichealthco-${env}.us.auth0.com` as const

export const getPharosDomain = (env: PHC_ENV) =>
  `https://pharos.${env}.thepublichealthco.com` as const

export const getAssetServiceDomain = (env: PHC_ENV) =>
  `https://preferences.${env}.thepublichealthco.com` as const

export const getNotificationsDomain = (env: PHC_ENV) =>
  `https://notifications.${env}.thepublichealthco.com` as const

export const getAccessDomain = (env: PHC_ENV) =>
  `https://access.${env}.thepublichealthco.com` as const

export const getLayersDomain = (env: PHC_ENV) =>
  `https://layers.${env}.thepublichealthco.com` as const

export const getCapDomain = (env: PHC_ENV) =>
  `https://cap.${env}.thepublichealthco.com` as const

export const getNavigatorDomain = (env: PHC_ENV) =>
  `https://navigator.${env}.thepublichealthco.com` as const

export const GLOBAL_GEOTAG = {
  _key: "000",
  _type: "geotag",
  locationId: "000",
  id: "000",
  name: "Global",
  geotagType: "adminBoundary",
} satisfies Geotag
