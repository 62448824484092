import { Box, Button, Typography } from "@mui/material"
import { useLocation } from "react-router"
import { useMapContext } from "../../../contexts/MapContext"
import { ROUTES } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"

interface RelatedIntelligenceProps {
  alertCount: number | undefined
  analysesCount: number | undefined
  locationId: string
}

export const RelatedIntelligence = ({
  alertCount,
  analysesCount,
  locationId,
}: RelatedIntelligenceProps) => {
  const { setOpenAlertDrawer, setSelectedLocationId } = useMapContext()
  const location = useLocation()
  const isHomeCard = location.pathname === ROUTES.HOME

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
    >
      <Typography variant="small1Bold">Related Intelligence</Typography>
      <Button
        variant="text"
        onClick={() => {
          setOpenAlertDrawer(true)
          setSelectedLocationId(locationId)
        }}
        disabled={isHomeCard}
        sx={{
          padding: 0,
        }}
      >
        <Typography
          variant="small1Bold"
          sx={{
            color: extraColors.purple,
          }}
        >
          {alertCount ? `Alerts (${alertCount.toLocaleString()})` : null}
          {alertCount && analysesCount ? " | " : null}
          {analysesCount
            ? ` Analyses (${analysesCount.toLocaleString()})`
            : null}
        </Typography>
      </Button>
    </Box>
  )
}
