import styled from "@emotion/styled"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import { LinearProgress, Typography } from "@mui/material"
import type React from "react"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { extraColors } from "../../../../utils/theme"
import { fadeInOutAnimation } from "../../../Settings/notifications/Notifications"
import { useBulkAssetContext } from "./BulkAssetUploadContext"

const DropzoneContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${extraColors.disabled}`,
  borderStyle: "dashed",
  borderRadius: 12,
  alignItems: "center",
  padding: 24,
  width: "100%",
  cursor: "pointer",
})

const UploadingContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
})

export const CSVDropzone: React.FC<{
  handleCSV: (file: File) => Promise<void>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isPending: boolean
}> = ({ handleCSV, setIsLoading, isPending }) => {
  const { completedJobs, inProgressJobs, setError, setUploadedFile } =
    useBulkAssetContext()
  const isLoading = (completedJobs || inProgressJobs) > 0

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      if (!file) return

      setUploadedFile(file)

      if (file.type !== "text/csv") {
        setError("File failed to upload. File type must be CSV.")
        return
      }

      handleCSV(file)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false)
        })
    },
    [handleCSV, setError, setIsLoading, setUploadedFile]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isLoading ? <UploadingStatus isPending={isPending} /> : <LandingZone />}
    </DropzoneContainer>
  )
}

const UploadingStatus: React.FC<{ isPending: boolean }> = ({ isPending }) => {
  return (
    <UploadingContainer>
      <Typography
        variant="body2Bold"
        sx={{
          color: extraColors.navy,
          animation: `${fadeInOutAnimation} 2.5s ease infinite`,
        }}
      >
        Uploading...
      </Typography>
      <LoadingProgressBar isPending={isPending} />
    </UploadingContainer>
  )
}

const LoadingProgressBar: React.FC<{
  isPending: boolean
}> = ({ isPending }) => {
  const { totalJobs, completedJobs, inProgressJobs } = useBulkAssetContext()

  return (
    <LinearProgress
      sx={{
        mt: 1,
        width: "inherit",
      }}
      color="info"
      variant={isPending ? "buffer" : "determinate"}
      value={
        completedJobs > 0 ? normalizeProgress(completedJobs, totalJobs) : 0
      }
      valueBuffer={
        inProgressJobs > 0 ? normalizeProgress(inProgressJobs, totalJobs) : 0
      }
    />
  )
}

const LandingZone = () => {
  return (
    <>
      <CloudUploadOutlinedIcon
        sx={{ height: "42px", width: "42px", color: extraColors.disabled }}
      />
      <Typography variant="body2">
        Drag and drop your csv or{" "}
        <Typography variant="body2Bold" sx={{ color: extraColors.purple }}>
          browse
        </Typography>
      </Typography>
    </>
  )
}

const normalizeProgress = (progress: number, totalJobs: number) =>
  (progress / totalJobs) * 100
