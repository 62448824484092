import { styled } from "@mui/material"
import type React from "react"
import { useEffect } from "react"
import { generatePath, useNavigate } from "react-router"
import { ROUTES } from "../../utils/constants"
import { extraColors } from "../../utils/theme"
import { SectionContainer } from "../LocationDetails/LocationRisk/Shared"
import { MainContent } from "../MainContent"
import { CriticalThreatHeader } from "./CriticalThreatHeader"
import { DEFAULT_TRANSFORM_SCALE } from "./RelatedContent/Shared"
import { useCapServiceContext } from "./capServiceContext"

const SectionContainerStyled = styled(SectionContainer)({
  cursor: "pointer",
  transition: "transform 250ms ease, box-shadow 250ms ease",
  transformOrigin: "center top",
  willChange: "transform",
  transform: DEFAULT_TRANSFORM_SCALE,
  "&:hover": {
    border: `1px solid ${extraColors.purpleMiddle}`,
    boxShadow: `5px 5px 16px -3px ${extraColors.shadow}`,
  },
})

export const CriticalThreats: React.FC = () => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()
  const navigate = useNavigate()

  // Navigate to individual critical threat page when there is only 1 critical threat
  useEffect(() => {
    if (data?.length && data.length === 1 && data[0]?.slug?.current) {
      void navigate(
        generatePath(ROUTES.CRITICAL_THREAT, {
          slug: data[0].slug.current,
        }),
        { replace: true }
      )
    }
  }, [data, navigate])

  // TODO: Add empty state

  return (
    <MainContent
      headerProps={{
        title: "Critical Threat Monitoring",
      }}
      disableLeftGutter
    >
      {data?.map(threat => {
        return (
          <SectionContainerStyled
            key={threat._id}
            onClick={() =>
              threat.slug?.current &&
              void navigate(
                generatePath(ROUTES.CRITICAL_THREAT, {
                  slug: threat.slug.current,
                })
              )
            }
          >
            <CriticalThreatHeader
              criticalThreat={threat}
              showThreatLink={true}
            />
          </SectionContainerStyled>
        )
      })}
    </MainContent>
  )
}
