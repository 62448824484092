import type {
  Asset,
  BaseEvent,
  MapboxLocation,
} from "@phc-health/connect-query"
import type { AlertLevel } from "@phc/common"
import {
  determineSourceLayerContext,
  getMapboxGeocodeResult,
  getTileSetResponse,
  mapboxConfig,
  type SourceLayerContext,
  type TilesetFeatureCollection,
} from "@phc/common"

// This is only allowed to use in the dayjsUTC helper export
import dayjs from "dayjs"
import type { Path, URLSearchParamsInit } from "react-router"
import { createSearchParams } from "react-router"

import { MAPBOX_ACCESS_TOKEN } from "./env"
import type { SearchParams } from "./types"

/**
 * Use dayjsUTC in place of dayjs for consistency across platform
 */
export const dayjsUTC = (...args: Parameters<typeof dayjs.utc>) =>
  dayjs.utc(...args)

export function getAssetName(asset: Asset | undefined) {
  const placeName = asset?.baseEvent?.geotags[0]?.mapboxLocation?.placeName

  // always asset name or place name till first comma
  const title = asset?.name || placeName?.split(",")[0]
  // always place name, but regex to remove title if it starts the same
  const subtitle =
    title && placeName?.replace(new RegExp(`^${title},?`), "").trim()
  return {
    title: title ?? "",
    subtitle: subtitle ?? "",
  }
}

export function getLocationNameFromMapboxLocation(
  location?: MapboxLocation,
  locationId?: string
) {
  if (!location || !locationId) {
    return {
      title: "",
      subtitle: "",
    }
  }

  const isCountry = locationId.length <= 3

  if (isCountry) {
    return {
      title: location.country,
      subtitle: "",
    }
  }

  const isSubNational = locationId.length <= 5

  if (isSubNational) {
    return {
      title: location.region,
      subtitle: location.country,
      subtitleShort: location.country,
    }
  }

  const county = `${location.region}, ${location.country}`

  return {
    title: location.district,
    subtitle: county,
    subtitleShort: county,
  }
}

/**
 * Rules:
 *
 * - If country, no subtitle
 * - If subnational, return country
 * - If subdivision, return parent feature name (feature[1]), country
 */
const getTilesetSubtitle = (
  context: SourceLayerContext,
  country: string | undefined,
  parentName: string | undefined
) => {
  switch (context) {
    case "country":
      return ""
    case "subnational":
      return country ?? ""
    case "subdivision":
      if (!parentName || parentName === country) {
        return country ?? ""
      }
      return `${parentName}${country ? `, ${country}` : ""}`
  }
}

export const getCountryCodeFromCoords = async ({
  lng,
  lat,
  accessToken,
}: {
  lng: number
  lat: number
  accessToken: string | undefined
}) => {
  const response = await getMapboxGeocodeResult({
    query: {
      lng,
      lat,
    },
    accessToken,
  })

  const countryCode =
    response?.features[0]?.context?.find((context: { id: string }) =>
      context.id.includes("country")
    )?.short_code ?? response?.features[0]?.properties?.short_code

  return countryCode
}

/**
 * Since features are sorted by id length, we can pick the feature we want by
 * - last feature for country
 * - second to last for subnational
 * - first for subdivision
 */
const pickFeatureByContext = (
  features: TilesetFeatureCollection["features"],
  context: SourceLayerContext
) => {
  switch (context) {
    case "country":
      return features[features.length - 1]
    case "subnational":
      return features[features.length - 2]
    case "subdivision":
      return features[0]
  }
}

interface GetLocationNameParams {
  lng: number
  lat: number
  countryCode: string
  locationId: string
}
export const getLocationNameFromTileset = async ({
  lng,
  lat,
  countryCode,
  locationId,
}: GetLocationNameParams) => {
  const accessToken = MAPBOX_ACCESS_TOKEN ?? ""

  const response = await getTileSetResponse(
    {
      lng,
      lat,
    },
    accessToken,
    countryCode
  )

  const context = determineSourceLayerContext(locationId)

  if (!context) {
    console.error("Could not determine context for locationId", locationId)
    return {
      title: "",
      subtitle: "",
    }
  }

  if (response?.features.length === 0) {
    console.error(
      "No features found for locationId at this country code",
      locationId,
      countryCode
    )
    return {
      title: "",
      subtitle: "",
    }
  }
  const features = response?.features
    .sort(
      (a, b) =>
        Number(b.properties.location_code) - Number(a.properties.location_code)
    )
    .filter(feature => {
      return mapboxConfig.allBySource
        .get(countryCode)
        ?.find(layer => layer.id === feature.properties.tilequery.layer)
    })
  if (!features?.length) {
    return {
      title: "",
      subtitle: "",
    }
  }

  const feature = pickFeatureByContext(features, context)
  if (!feature) return { title: "", subtitle: "" }

  const { properties } = feature
  const { name, location_name } = properties
  const title = location_name || name
  const subtitle = getTilesetSubtitle(
    context,
    properties.country,
    features[1]?.properties.location_name || features[1]?.properties.name
  )

  return {
    title,
    subtitle,
    feature,
    features,
  }
}

export const cleanSubtitle = (title: string, subtitle: string) =>
  subtitle.replace(title, "").replace(/^,/, "").trim()

export const objectKeys = <Obj extends object>(obj: Obj) =>
  Object.keys(obj) as (keyof Obj)[]

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
}

export function getEnumKey<O extends object>(obj: O, key: number) {
  return enumKeys(obj)
    .filter(k => obj[k] === key)
    .map(k => k)[0]
}

export function removeNullAndUndefined<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined
    )
  ) as T
}

export function createRoute<Pathname extends string>(
  pathname: Pathname,
  newParamsOnly?: boolean
) {
  return (newParams?: SearchParams) => {
    const currentParams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    )

    const params = newParamsOnly
      ? { ...newParams }
      : { ...currentParams, ...newParams }

    return {
      pathname,
      search: createSearchParams(
        removeNullAndUndefined(params) as URLSearchParamsInit
      ).toString(),
    } satisfies {
      pathname: Pathname
    } & Omit<Path, "hash">
  }
}

export const convertStringToTitleCase = (str: string | undefined) => {
  return str?.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()) ?? ""
}

export const getFeature = async (
  coords: { lng: number; lat: number },
  accessToken: string,
  countryShortCode: string
) => {
  const feature = await getTileSetResponse(
    coords,
    accessToken,
    countryShortCode
  )
  if (!feature?.features.length) {
    console.error("No features found")
    return []
  }
  return feature.features
}

export const hashData = async (data: string): Promise<string> => {
  // Convert the data string to an ArrayBuffer
  const textEncoder = new TextEncoder()
  const encodedData = textEncoder.encode(data)

  // Hash the data using SHA-256
  const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData)

  // Convert the hash ArrayBuffer to a hexadecimal string
  const hashHex = Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, "0"))
    .join("")

  return hashHex
}

export const getLocationId = (
  protoWithBaseEvent: { baseEvent?: BaseEvent } | undefined
) => {
  return protoWithBaseEvent?.baseEvent?.geotags[0]?.locationId
}

export const sortAlertLevel = (a: AlertLevel, b: AlertLevel) => {
  const alertLevels: AlertLevel[] = ["warning", "watch", "advisory"]
  return alertLevels.indexOf(a) - alertLevels.indexOf(b)
}
