import { Typography } from "@mui/material"
import type { GridColDef } from "@mui/x-data-grid-pro"
import { useMemo } from "react"
import { Link, generatePath, useNavigate } from "react-router"
import { BaseGrid } from "../components/BaseGrid/BaseGrid"
import { useCapServiceContext } from "../components/CriticalThreats/capServiceContext"
import { MainContent } from "../components/MainContent"
import { PageContainer } from "../components/PageContainer"
import { ROUTES } from "../utils/constants"
import { dayjsUTC } from "../utils/helpers"
import { extraColors } from "../utils/theme"
import type { CMSRowData } from "../utils/types"

interface Row {
  id: string
  title: string
  publishedAt: Date
  slug: string
}

const columns = [
  {
    field: "publishedAt" as const,
    headerName: "Date",
    headerAlign: "left",
    type: "date",
    minWidth: 100,
    display: "flex",
    renderCell: ({ value }) => {
      const date = value as string
      return (
        <Typography variant="body2" sx={{ color: extraColors.hint }}>
          {dayjsUTC(date).local().format("MM/DD/YYYY")}
        </Typography>
      )
    },
  },
  {
    field: "title" as const,
    headerName: "Title",
    minWidth: 200,
    display: "flex",
    flex: 4,
    renderCell: ({ value, row }) => (
      <Link
        style={{ textDecoration: "none" }}
        to={generatePath(ROUTES.FAQ_POST, {
          slug: row.slug || "",
        })}
      >
        <Typography variant="body2">{value}</Typography>
      </Link>
    ),
  },
  { field: "slug" as const, filterable: false },
] satisfies GridColDef<Row>[]

export const Faq = () => {
  const navigate = useNavigate()
  const { useFaqs } = useCapServiceContext()
  const { data, isLoading } = useFaqs()

  const rows = useMemo(
    () =>
      data?.map(item => {
        //if the _id starts with "drafts." it's a draft
        const isDraft = item._id.startsWith("drafts.")
        //add draft to the title if it's a draft
        const title = isDraft ? `${item.title ?? ""} (Draft)` : item.title

        return {
          id: item._id,
          title: title ?? "",
          publishedAt: new Date(item.publishedAt ?? item.updatedAt ?? ""), // Add a fallback value here
          slug: item.slug?.current ?? "",
        } satisfies Row
      }),
    [data]
  )

  const handleCellClick = (rowData: { row: CMSRowData; field: string }) => {
    void navigate(
      generatePath(ROUTES.FAQ_POST, {
        slug: rowData.row.slug || "",
      })
    )
  }

  return (
    <MainContent
      headerProps={{
        title: "FAQ",
      }}
      disableLeftGutter
    >
      <PageContainer>
        <BaseGrid
          search={true}
          filter={false}
          checkbox={false}
          columnVisibilityModel={{
            slug: false,
          }}
          loading={isLoading && !data}
          rows={rows ?? []}
          columns={columns}
          onCellClick={handleCellClick}
          autoHeight
          initSortModel={{
            field: "publishedAt",
            sort: "desc",
          }}
          noTextOverlay="No FAQs found"
          striped
        />
      </PageContainer>
    </MainContent>
  )
}
