import {
  GroupsTwoTone,
  HandymanOutlined,
  Language,
  MyLocationTwoTone,
  NewspaperOutlined,
  StackedBarChartOutlined,
} from "@mui/icons-material"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { IconButton, Typography, useMediaQuery } from "@mui/material"
import Button from "@mui/material/Button"
import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import type { CSSObject, Theme } from "@mui/material/styles"
import { styled, useTheme } from "@mui/material/styles"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
  type Ref,
} from "react"
import { Link, useLocation } from "react-router"
import SvgSiren from "../assets/navigation/Siren"
import { useAppLogo } from "../hooks/useAppLogo"
import { ROUTES } from "../utils/constants"
import { objectKeys } from "../utils/helpers"
import { BORDER_RADIUS, extraColors } from "../utils/theme"
import { AskNavButton } from "./Ask/AskNavButton"

const drawerWidth = 240
const ICON_COLOR = extraColors.purpleMiddle

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  cursor: "pointer",
  border: "none",
  "& .MuiPaper-root": {
    border: "none",
    boxShadow: "0px 3px 7px 1px rgba(0, 0, 0, 0.2)",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .phc-logo": {
      height: 37,
      width: 52,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .phc-logo": {
      height: 20,
      width: 30,
    },
  }),
}))

const DrawerButton = styled(IconButton)<{ isopen: number }>(
  ({ isopen, theme }) => ({
    display: "flex",
    alignSelf: isopen ? "flex-end" : "center",
    marginRight: isopen ? 12 : 0,
    justifyContent: isopen ? "center" : "flex-start",
    padding: 3,
    borderRadius: "100%",
    bottom: isopen ? 28 : 0,
    backgroundColor: extraColors.white,
    color: ICON_COLOR,
    "&:hover": {
      background: extraColors.purpleSubtle,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })
)

const NavContainer = styled(List)(() => ({
  zIndex: 999,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}))

const NavItemList = styled("nav")(() => ({
  display: "grid",
  gridAutoFlow: "row",
  gap: 24,
  color: ICON_COLOR,
}))

const NavItem = styled(ListItem)<{ isopen: number }>(({ isopen }) => ({
  height: 38,
  width: isopen ? "auto" : 38,
  color: extraColors.navy,
  marginLeft: -6,
}))

const NavItemButton = styled(ListItemButton)({
  minWidth: 46,
  minHeight: 38,
  paddingLeft: 9,
  borderRadius: BORDER_RADIUS,
  "&.Mui-selected": {
    background: extraColors.purpleSubtle,
    boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.12)",
  },
  "&:hover": {
    background: extraColors.purpleSubtle,
  },
}) as typeof ListItemButton

const NavItemIcon = styled(ListItemIcon)<{ iconcolor: string }>(
  ({ iconcolor }) => ({
    fill: iconcolor,
    color: iconcolor,
    padding: 0,
    minWidth: 38,
  })
)

const LogoButton = styled(Button)<{ isopen: number }>(({ isopen }) => ({
  maxWidth: 140,
  display: "flex",
  cursor: "pointer",
  height: isopen ? 88 : 10,
  width: isopen ? "100%" : 18,
  "&:hover": {
    background: "transparent",
  },
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: "100vw",
  top: 60,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    top: 0,
    width: drawerWidth,
  },
})

const closedMixin = (theme: Theme): CSSObject => ({
  top: 60,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  width: "0px",
  [theme.breakpoints.up("md")]: {
    top: 0,
    width: `calc(${theme.spacing(8)} + 2px)`,
  },
})

const Image = styled("img")(() => ({
  width: "inherit",
}))

const TopContainer = styled("div")<{ isopen: number }>(({ isopen, theme }) => ({
  height: 88,
  display: "flex",
  justifyContent: "center",
  flexDirection: isopen ? "row" : "column",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}))

const NAV_ITEMS = {
  MAP: {
    title: "Map",
    icon: Language,
  },
  ASSETS: {
    title: "Locations",
    icon: MyLocationTwoTone,
  },
  ALERTS: {
    title: "Alerts",
    icon: WarningAmberIcon,
  },
  ANALYSES: {
    title: "Analysis",
    icon: NewspaperOutlined,
  },
  GUIDANCE: {
    title: "Guidance",
    icon: StackedBarChartOutlined,
  },
  WORKFORCE_PLANNING: {
    title: "Workforce Planning",
    icon: GroupsTwoTone,
  },
  CRITICAL_THREATS: {
    title: "Critical Threats",
    icon: SvgSiren,
  },
  TOOLS: {
    title: "Tools",
    icon: HandymanOutlined,
  },
} satisfies Partial<
  Record<keyof typeof ROUTES, { title: string; icon: React.FC }>
>

const DrawerNav = (_: unknown, ref: Ref<{ toggle(): void }>) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const [isOpen, setIsOpen] = useState(md || false)
  const appLogo = useAppLogo()

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useImperativeHandle(ref, () => ({
    toggle() {
      toggleDrawer()
    },
  }))

  return (
    <div style={{ gridArea: "nav" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: md ? drawerWidth : 0, // hide drawer on mobile viewports
          marginTop: md ? "64px" : 0,
        }}
        open={isOpen}
      >
        <NavContainer>
          <TopContainer isopen={+isOpen}>
            <LogoButton href={ROUTES.MAP} isopen={+isOpen}>
              <Image
                src={isOpen ? appLogo?.full : appLogo?.short}
                alt={appLogo?.altText}
              />
            </LogoButton>
            <DrawerButton onClick={toggleDrawer} isopen={+isOpen}>
              {isOpen ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </DrawerButton>
          </TopContainer>
          <NavigationItems isOpen={isOpen} setIsOpen={setIsOpen} />
          <AskNavButton isNavOpen={isOpen} setNavOpen={setIsOpen} />
        </NavContainer>
      </Drawer>
    </div>
  )
}

export default forwardRef(DrawerNav)

const Line = styled("div")({
  display: "flex",
  width: "100%",
  height: 1,
  background: extraColors.light,
})

const NavigationItems: React.FC<{
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { showEventTool } = useFlags()
  const location = useLocation()

  const handleLink = (key: keyof typeof ROUTES) => {
    return ROUTES[key]
  }

  return (
    <NavItemList
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <Line />
      {objectKeys(NAV_ITEMS)
        .filter(item => (showEventTool ? true : item !== "TOOLS"))
        .map(item => {
          const Icon = NAV_ITEMS[item].icon
          return (
            <NavItem key={item} isopen={+isOpen}>
              <NavItemButton
                component={Link}
                to={{
                  pathname: handleLink(item),
                  search: "",
                }}
                selected={location.pathname.includes(handleLink(item))}
                onClick={() => {
                  if (isMobile) {
                    setIsOpen(false)
                  }
                }}
              >
                <NavItemIcon
                  iconcolor={
                    location.pathname.includes(handleLink(item))
                      ? ICON_COLOR
                      : extraColors.navy
                  }
                >
                  <Icon fontSize="small" />
                </NavItemIcon>
                {isOpen && (
                  <Typography variant={"body1"}>
                    {NAV_ITEMS[item].title}
                  </Typography>
                )}
              </NavItemButton>
            </NavItem>
          )
        })}
    </NavItemList>
  )
}
