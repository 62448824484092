import { useAuth0 } from "@auth0/auth0-react"
import { styled, Typography } from "@mui/material"
import mixpanel from "mixpanel-browser"
import type React from "react"
import { useLocation } from "react-router"
import clipboard from "../assets/clipboard.png"
import Button from "../components/Shared/Button"
import { usePermissions } from "../hooks/usePermissions"
import { useSearchParams } from "../hooks/useSearchParams"
import { useUser } from "../hooks/useUser"

const Main = styled("main")(({ theme }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  overflow: "scroll",
  width: "100%",
  height: "100%",
}))

const NothingToSeeHere = styled("div")(() => ({
  display: "grid",
  gap: 16,
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  justifyItems: "center",
  textAlign: "center",
  minHeight: "100vh",
  fontSize: 64,
}))

const Image = styled("img")(() => ({
  height: 60,
}))
export const ErrorPage: React.FC = () => {
  const location = useLocation()
  const params = useSearchParams()
  const auth0 = useAuth0()
  const { logout, user: authUser } = auth0
  const user = useUser({
    orgId: params.organizationId,
    email: authUser?.email,
  })
  const permissions = usePermissions()
  // surface every error imaginable
  console.error({ location, params, auth0, user, permissions })

  return (
    <>
      <Main data-testid="404-page">
        <NothingToSeeHere>
          <Typography component="h1" variant="h4">
            Sign in Error
          </Typography>
          <Image src={clipboard} alt={"clipboard"} />
          <Typography>
            We are having trouble authenticating your account.
            <br />
            Please try again or contact support.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              mixpanel.reset()
              logout({
                logoutParams: { returnTo: window.location.origin },
              }).catch(console.error)
            }}
          >
            Return to sign in
          </Button>
        </NothingToSeeHere>
      </Main>
    </>
  )
}
