import { LineChart, type LineSeriesType } from "@mui/x-charts"
import { useMemo } from "react"
import type { TabularData } from "./useAskApi"

export const AskCharts = ({ tabularData }: { tabularData?: TabularData }) => {
  const datasetByLocalDateString = useMemo(
    () =>
      tabularData?.data.reduce<Record<string, Record<string, string | number>>>(
        (acc, data) => {
          const date = new Date(data.start_time)
          const dateStr = date.toLocaleDateString()
          if (!acc[dateStr]) {
            acc[dateStr] = {
              date: date.getTime(),
            }
          }
          if (typeof data.value !== "number") {
            console.log(data)
            throw new Error("value is not string")
          }
          acc[dateStr] = {
            ...acc[dateStr],
            [data.location_osm_id.toString()]: data.value,
          }
          return acc
        },
        {}
      ) ?? {},
    [tabularData?.data]
  )
  const mapByLocationOsmId = useMemo(
    () =>
      tabularData?.data.reduce<Record<string, TabularData["data"]>>(
        (acc, data) => {
          if (!acc[data.location_osm_id]) {
            acc[data.location_osm_id] = []
          }
          acc[data.location_osm_id]?.push(data)
          return acc
        },
        {}
      ),
    [tabularData?.data]
  )
  const series = useMemo(
    () =>
      Object.entries(mapByLocationOsmId ?? {}).map(([locationOsmId, data]) => {
        return {
          type: "line",
          label: data[0]?.source_location,
          showMark: false,
          dataKey: locationOsmId.toString(),
          id: locationOsmId.toString(),
          valueFormatter: v => {
            return v?.toLocaleString() ?? ""
          },
        } satisfies LineSeriesType
      }),
    [mapByLocationOsmId]
  )
  if (!tabularData) return null
  return (
    <div style={{ width: "100%", height: 400 }}>
      <LineChart
        disableAxisListener
        dataset={Object.values(datasetByLocalDateString)}
        series={series}
        xAxis={[
          {
            scaleType: "time",
            dataKey: "date",
            valueFormatter: (d: number) => {
              return new Date(d).toLocaleDateString()
            },
            label:
              tabularData.diseaseCode +
              " " +
              tabularData.type +
              " " +
              tabularData.unit,
          },
        ]}
        yAxis={[
          {
            label: "Count",
          },
        ]}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        tooltip={{
          trigger: series.length < 5 ? "axis" : "none",
        }}
        experimentalMarkRendering
      />
    </div>
  )
}
