import { Link, styled, Typography } from "@mui/material"
import { generatePath, Link as RouterLink } from "react-router"
import { ROUTES } from "../../../utils/constants"
import {
  IntelligenceCard,
  type IntelligenceCardProps,
} from "./IntelligenceCard"

const Content = styled(Typography)(() => ({
  position: "relative",
  overflow: "hidden",
  lineHeight: "18px",
  // 5 lines of text
  height: "calc(18px * 5)",
})) as typeof Typography

const ViewAnalysisLink = styled(Link)(({ theme }) => ({
  ...theme.typography.tinyLink,
  position: "absolute",
  bottom: 0,
  right: 0,
  background: "linear-gradient(90deg, transparent 0%, white 6px)",
  display: "inline",
  padding: "0 4px",
  paddingLeft: "20px",
  "&:before": {
    content: '"..."',
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "8px",
    right: 0,
  },
})) as typeof Link

interface AnalysisCardProps extends IntelligenceCardProps {
  content: string | undefined
  slug?: string
}

export const AnalysisCard = ({
  title,
  geotags,
  content,
  date,
  slug,
  className,
  id,
  threat,
}: AnalysisCardProps) => {
  return (
    <IntelligenceCard
      className={className}
      date={date}
      geotags={geotags}
      title={title}
      id={id}
      threat={threat}
      slug={generatePath(ROUTES.ANALYSIS_POST, {
        slug: slug ?? "",
      })}
    >
      <Content variant="small1" component="p">
        {content}{" "}
        <ViewAnalysisLink
          component={RouterLink}
          to={generatePath(ROUTES.ANALYSIS_POST, { slug: slug ?? "" })}
        >
          View Analysis
        </ViewAnalysisLink>
      </Content>
    </IntelligenceCard>
  )
}
