import CloseIcon from "@mui/icons-material/Close"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { styled, Typography } from "@mui/material"
import { extraColors } from "../../../../utils/theme"
import { useBulkAssetContext } from "./BulkAssetUploadContext"

const UploadedFileContainer = styled("div")({
  display: "flex",
  borderRadius: 3,
  padding: 6,
  width: "100%",
  justifyContent: "space-between",
})

const FileNameContainer = styled("div")({
  display: "flex",
  gap: 6,
})

const CloseIconStyled = styled(CloseIcon)({
  height: "16px",
  width: "16px",
  color: extraColors.hint,
  cursor: "pointer",
})

const ErrorText = styled(Typography)({
  fontStyle: "italic",
  color: extraColors.medium,
  marginBottom: "8px",
  alignSelf: "start",
})

export const FileUploadDetails: React.FC = () => {
  const { uploadedFile, clearUpload, error } = useBulkAssetContext()
  if (!uploadedFile) return

  const borderColor = error
    ? extraColors.status.redMiddle
    : extraColors.disabled

  const backgroundColor = error
    ? extraColors.status.redSubtle
    : extraColors.subtle

  return (
    <>
      <UploadedFileContainer
        sx={{
          border: `1px solid ${borderColor}`,
          backgroundColor,
        }}
      >
        <FileNameContainer>
          <UploadFileIcon
            sx={{ height: "16px", width: "16px", color: extraColors.dark }}
          />
          <Typography variant="small1" sx={{ color: extraColors.medium }}>
            {uploadedFile.name}
          </Typography>
        </FileNameContainer>
        <CloseIconStyled onClick={clearUpload} />
      </UploadedFileContainer>
      {error && <ErrorText variant="small1">{error}</ErrorText>}
    </>
  )
}
