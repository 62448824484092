import { useSnackbar } from "notistack"
import PQueue from "p-queue"
import { useCallback } from "react"
import { useInvalidateAssetQueries } from "../../hooks/useAssetService"
import { useCreateAssets } from "../hooks/useCreateAsset"
import { useRemoveAsset } from "../hooks/useRemoveAsset"
import { UploadMode, useBulkAssetContext } from "./BulkAssetUploadContext"

export const useBulkCreateAssets = () => {
  const {
    setTotalJobs,
    setInProgressJobs,
    setCompletedJobs,
    setError,
    newAssets,
    assetsToBeRemoved,
    error,
    uploadMode,
    resetJobProgress,
  } = useBulkAssetContext()

  const { mutateAsync: createBulk, isPending: bulkIsPending } =
    useCreateAssets()
  const { mutateAsync: remove, isPending: removeIsPending } = useRemoveAsset({
    hideToast: true,
    skipQueryInvalidation: true,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { invalidateAssetQueries } = useInvalidateAssetQueries()

  const bulkCreateAssets = useCallback(async () => {
    setError(undefined)

    if (!newAssets) return

    setTotalJobs(newAssets.length)
    setInProgressJobs(0)
    setCompletedJobs(0)

    try {
      const batchSize = 300
      const totalAssets = newAssets.length
      let currentIndex = 0

      while (currentIndex < totalAssets) {
        const endIndex = Math.min(currentIndex + batchSize, totalAssets)
        const batchAssets = newAssets.slice(currentIndex, endIndex)
        setInProgressJobs(endIndex)

        try {
          await createBulk({ assets: batchAssets })
          setCompletedJobs(endIndex)
        } catch (e) {
          console.error("Error creating bulk assets:", e)
          if (e instanceof Error) {
            setError(e.message)
          }
        }

        currentIndex += batchSize
      }

      if (uploadMode === UploadMode.SYNC && assetsToBeRemoved) {
        const queue = new PQueue({ concurrency: 50 })
        const removePromises = assetsToBeRemoved.map(async asset =>
          queue.add(() => remove({ assetId: asset.assetId }))
        )
        await Promise.allSettled(removePromises)
      }
    } catch (e) {
      console.error("Unexpected error:", e)
      if (e instanceof Error) {
        setError(e.message)
      }
    } finally {
      resetJobProgress()

      enqueueSnackbar(
        error
          ? "Unable to save your locations."
          : "Your new locations have been saved.",
        {
          variant: error ? "error" : "success",
        }
      )

      await invalidateAssetQueries()
    }
  }, [
    setError,
    newAssets,
    setTotalJobs,
    setInProgressJobs,
    setCompletedJobs,
    uploadMode,
    assetsToBeRemoved,
    createBulk,
    remove,
    resetJobProgress,
    enqueueSnackbar,
    error,
    invalidateAssetQueries,
  ])

  return { bulkCreateAssets, bulkIsPending, removeIsPending }
}
