import { useAuth0 } from "@auth0/auth0-react"
import { timestampFromDate } from "@bufbuild/protobuf/wkt"
import { Dialog, styled, Typography } from "@mui/material"
import type { TOSAgreement } from "@phc-health/connect-query"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import { useEffect, useRef } from "react"
import { TRUE } from "../../utils/constants"
import { getThirtyDaysFromNowDate } from "../../utils/helpers/dateHelper"
import { extraColors } from "../../utils/theme"
import { useUpdateUserTrial } from "../AccountManagement/hooks/useManageAssetServiceUsers"
import Button from "../Shared/Button"
import {
  useGetUserTermsOfService,
  useUpdateUserTermsOfService,
} from "./hooks/useTermsOfService"
import { ReactComponent as DefaultTOS } from "./markdowns/terms.md"
import { ReactComponent as TrialTOS } from "./markdowns/trial_tos.md"

// Update these constants when the default or trial terms of service documents are updated
const TRIAL_TOS_DOC_DATE = new Date("2024-01-23")
const TRIAL_TOS_DOC_NAME = "trial_terms_of_service_2024-01-23"
const DEFAULT_TOS_DOC_DATE = new Date("2022-12-06")
const DEFAULT_TOS_DOC_NAME = "default_terms_of_service_2022-12-06"

export const DialogStyled = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    height: "fit-content",
    maxHeight: "100%",
    width: "fit-content",
    maxWidth: 714,
  },
})) as typeof Dialog

const ButtonStyled = styled(Button)({
  width: "fit-content",
  padding: "8px 30px",
  alignSelf: "end",
  marginTop: 30,
})

const ScrollContainer = styled("div")({
  maxHeight: 500,
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: 6,
    background: extraColors.subtle,
    borderRadius: 6,
  },
  "::-webkit-scrollbar-thumb": {
    background: extraColors.disabled,
    borderRadius: 6,
    width: 6,
  },
  padding: "10px 30px",
  background: extraColors.light,
  borderRadius: 6,
})

interface TOSDocElements {
  docName: string
  docDate: Date
  docHeader: React.ReactNode
  docMarkdown: React.ReactNode
}

export const TermsOfServiceModal: React.FC = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    user: authUser,
  } = useAuth0()

  const isFirstTimeUser =
    !!authUser?.["https://thepublichealthco.com/v1/is_first_login"]

  const { data: tosData, isLoading: tosDataLoading } =
    useGetUserTermsOfService()
  const docByTrialStatus = getDocByTrialStatus(tosData?.isTrialUser)
  const { showTrialTermsOfService } = useFlags()

  const { mutate, isPending: tosUpdateIsPending } =
    useUpdateUserTermsOfService()
  const { mutate: updateUserTrial, isPending: trialUpdateIsPending } =
    useUpdateUserTrial()

  const showTOS = getSignatureRequired(
    docByTrialStatus,
    tosData?.userTos?.tosAgreement
  )

  const userConvertedToTrialRef = useRef(false)
  localStorage.setItem(
    "userConvertedToTrial",
    userConvertedToTrialRef.current.toString()
  )

  const user = tosData?.userTos?.user
  const userNeedsToBeConvertedToTrial = isFirstTimeUser && !tosData?.isTrialUser
  const isLoading =
    auth0Loading || trialUpdateIsPending || tosDataLoading || tosUpdateIsPending

  const hideTerms =
    !showTrialTermsOfService ||
    !tosData ||
    !showTOS ||
    isLoading ||
    !isAuthenticated ||
    userNeedsToBeConvertedToTrial

  useEffect(() => {
    if (
      localStorage.getItem("userConvertedToTrial") === TRUE ||
      !isAuthenticated ||
      !userNeedsToBeConvertedToTrial ||
      isLoading
    ) {
      return
    }

    userConvertedToTrialRef.current = true
    localStorage.setItem(
      "userConvertedToTrial",
      userConvertedToTrialRef.current.toString()
    )

    const updateUserTrialStatus = () => {
      if (!user?.userId) return
      const now = new Date()
      const thirtyDaysInFuture = getThirtyDaysFromNowDate(now)
      try {
        updateUserTrial({
          userTrial: {
            user: {
              userId: user.userId,
            },
            isTrialUser: true,
            trialStartedAt: timestampFromDate(now),
            trialEndedAt: timestampFromDate(thirtyDaysInFuture),
          },
        })
      } catch (_error) {
        userConvertedToTrialRef.current = false
        localStorage.setItem(
          "userConvertedToTrial",
          userConvertedToTrialRef.current.toString()
        )
      }
    }

    // If the user is a first time user and is not a trial user, make them a trial user
    // TODO: Remove this once we're setting trial status on the backend at creation time of auth0 users
    // via access management service
    updateUserTrialStatus()
  }, [
    isAuthenticated,
    isLoading,
    updateUserTrial,
    user?.userId,
    userNeedsToBeConvertedToTrial,
  ])

  if (hideTerms) return null

  // TODO: re-enable TOS signing when we have updated terms for non-trial users
  if (!tosData.isTrialUser) return null

  return (
    <DialogStyled open={showTOS} title="Terms and Conditions">
      {docByTrialStatus.docHeader}
      <ScrollContainer>{docByTrialStatus.docMarkdown}</ScrollContainer>
      <ButtonStyled
        variant="contained"
        title="Accept"
        onClick={() =>
          mutate({
            tosAgreement: {
              tosCreatedAt: timestampFromDate(docByTrialStatus.docDate),
              tosDocName: docByTrialStatus.docName,
            },
          })
        }
        disabled={tosUpdateIsPending}
      >
        Accept
      </ButtonStyled>
    </DialogStyled>
  )
}

function getSignatureRequired(
  docElements: TOSDocElements,
  tosAgreement?: TOSAgreement
) {
  if (!tosAgreement) return true

  const { tosSignedAt, tosCreatedAt, tosDocName } = tosAgreement

  // If any of these fields are missing, then we need to get a signature.
  const isMissingTermsFields = !tosSignedAt || !tosCreatedAt || !tosDocName

  // We'll have multiple versions of the terms of service documents produced over time.
  // Make sure that the date of the one we're currently serving has been signed.
  const currentDocumentNotSigned =
    tosDocName !== docElements.docName ||
    tosCreatedAt?.seconds !== timestampFromDate(docElements.docDate).seconds

  if (isMissingTermsFields || currentDocumentNotSigned) return true

  // Show the modal if signature is out of date. This is guaranteed if the
  // signature is older than the creation date of the current terms document.
  if (tosCreatedAt.seconds > tosSignedAt.seconds) return true

  return false
}

function getDocByTrialStatus(isTrialUser?: boolean): TOSDocElements {
  return isTrialUser
    ? {
        docName: TRIAL_TOS_DOC_NAME,
        docDate: TRIAL_TOS_DOC_DATE,
        docHeader: (
          <>
            <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
              30 Day Pharos Trial
            </Typography>
            <Typography variant="body1Bold" sx={{ margin: "20px 0" }}>
              Please accept the following terms of use agreement to start your
              evaluation.
            </Typography>
          </>
        ),
        docMarkdown: <TrialTOS />,
      }
    : {
        docName: DEFAULT_TOS_DOC_NAME,
        docDate: DEFAULT_TOS_DOC_DATE,
        docHeader: (
          <>
            <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
              Terms and Conditions
            </Typography>
            <Typography variant="body1Bold" sx={{ margin: "20px 0" }}>
              By clicking "Accept," I state that I have read and understood the
              Terms and Conditions.
            </Typography>
          </>
        ),
        docMarkdown: <DefaultTOS />,
      }
}
