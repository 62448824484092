import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Typography } from "@mui/material"
import { IndustryType } from "@phc-health/connect-query"
import { useCallback, useMemo } from "react"
import {
  createSearchParams,
  generatePath,
  useMatch,
  useNavigate,
} from "react-router"
import { useLayersByLocationId } from "../../../../hooks/useLayersApi"
import { useSearchParams } from "../../../../hooks/useSearchParams"
import { ROUTES, stringifySearchParams } from "../../../../utils/constants"
import {
  getLocationId,
  getLocationNameFromMapboxLocation,
} from "../../../../utils/helpers"
import { convertBucketToAsset } from "../../../../utils/helpers/assetHelper"
import { trackEvent } from "../../../../utils/mixpanel"
import { useCreateAsset } from "../../../WatchedLocations/AssetManagement/hooks/useCreateAsset"
import { useRemoveAsset } from "../../../WatchedLocations/AssetManagement/hooks/useRemoveAsset"
import { useListAssets } from "../../../WatchedLocations/hooks/useAssetService"
import { LOCATION_DETAILS_TABS } from "../../LocationDetailsTabs"
import { ActionMenuItem, IconStyles } from "./ActionsShared"

export const WatchAssetMenuItem: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useSearchParams()
  const { data: assetsData } = useListAssets({
    includeGlobal: false,
  })
  const { mutateAsync } = useRemoveAsset()
  const assetMatch = useMatch(ROUTES.ASSET)
  const assetId = assetMatch?.params.assetId
  const { mutateAsync: create } = useCreateAsset()

  const saved = useMemo(() => {
    if (assetId)
      // if this is an asset route, look for matching asset id
      return assetsData.assets.find(asset => asset.assetId === assetId)

    // If it's not on an asset route yet but we've saved it, look for assets with matching location id.
    // This won't be necessary once we're returning the asset id on the create response.
    return assetsData.assets.find(asset => getLocationId(asset) === id)
  }, [assetId, assetsData.assets, id])

  const { data } = useLayersByLocationId(id || getLocationId(saved))
  const bucket = data?.buckets[0]

  const createAsset = useCallback(async () => {
    if (!bucket) return
    const newAsset = await convertBucketToAsset(bucket)
    const created = await create({ asset: newAsset })
    trackEvent("MANAGE_LOCATION", {
      action: "add",
      locationId: getLocationId(newAsset),
      industry: IndustryType[newAsset.industry],
    })

    if (!created.asset) return

    void navigate(
      {
        pathname: generatePath(ROUTES.ASSET, {
          assetId: created.asset.assetId,
        }),
        search: createSearchParams({
          tab: LOCATION_DETAILS_TABS[0].path,
        }).toString(),
      },
      {
        replace: true,
      }
    )
  }, [bucket, create, navigate])

  const removeAsset = useCallback(async () => {
    if (!saved) return

    const locationId = getLocationId(saved)
    const { title, subtitle } = getLocationNameFromMapboxLocation(
      saved.baseEvent?.mapboxLocation,
      locationId
    )

    await mutateAsync({ assetId: saved.assetId }).catch(console.error)
    trackEvent("MANAGE_LOCATION", {
      action: "delete",
      locationId,
    })

    // redirect to location route since the asset route isn't valid anymore
    void navigate(
      {
        pathname: ROUTES.LOCATION_DETAILS,
        search: stringifySearchParams({
          id: locationId,
          subtitle,
          title,
          tab: LOCATION_DETAILS_TABS[0].path,
        }),
      },
      { replace: true }
    )
  }, [saved, mutateAsync, navigate])

  if (!assetId && !id) return

  return (
    <ActionMenuItem
      onClick={
        !saved
          ? () => {
              void createAsset()
            }
          : removeAsset
      }
    >
      {!saved ? (
        <VisibilityIcon sx={{ ...IconStyles }} />
      ) : (
        <VisibilityOffIcon sx={{ ...IconStyles }} />
      )}

      <Typography>
        {!saved ? "Start Watching Location" : "Stop Watching Location"}
      </Typography>
    </ActionMenuItem>
  )
}
