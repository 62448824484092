import { useAuth0 } from "@auth0/auth0-react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import type { ButtonProps } from "@mui/material"
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import { useFlags } from "launchdarkly-react-client-sdk"
import mixpanel from "mixpanel-browser"
import type { Path } from "react-router"
import { Link } from "react-router"
import { useOrganization } from "../../hooks/useOrganization"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { usePermissions } from "../../hooks/usePermissions"
import {
  PARSONS_ORG_NAME,
  ROUTES,
  stringifySearchParams,
} from "../../utils/constants"
import { extraColors, theme } from "../../utils/theme"
import { SanityDatasetToggle } from "./SanityDatasetToggle"

const ButtonStyled = styled(Button)(({ theme: t }) => ({
  ...t.typography.body2,
  padding: "0px 8px",
  color: extraColors.black,
}))

const NavLink: React.FC<
  ButtonProps & {
    to: Omit<Path, "hash">
    pathname?: string
    dataTestId?: string
  }
> = ({ to, children, dataTestId }) => (
  <Button
    variant="text"
    color="inherit"
    component={Link}
    to={to}
    disableRipple={true}
    sx={{
      ...theme.typography.body2,
      "&:hover": {
        backgroundColor: "transparent",
      },
    }}
    data-testid={dataTestId}
  >
    {children}
  </Button>
)

const UserContainer = styled("div")(() => ({
  display: "flex",
  "@media print": {
    display: "none",
  },
}))

const UserButton = styled(Button)({
  ...theme.typography.body1Bold,
  justifyContent: "end",
  display: "flex",
  background: extraColors.light,
  color: extraColors.dark,
  borderRadius: 100,
  boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.12)",
  padding: "0px 9px",
  "&:hover, &:focus, &:active": {
    background: extraColors.light,
    color: extraColors.dark,
    boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.12)",
    textDecoration: "none",
  },
})

const MenuItemStyled = styled(MenuItem)({
  justifyContent: "center",
})

const ManageLink: React.FC<{
  organizationReadControl: boolean | undefined
}> = ({ organizationReadControl }) => {
  const organizationId = useOrganizationId()
  const organization = useOrganization(organizationId.data ?? "")

  return (
    <NavLink
      to={
        organizationReadControl
          ? { pathname: ROUTES.ORGANIZATIONS, search: "" }
          : {
              pathname: ROUTES.USERS,
              search: stringifySearchParams({
                organizationId: organizationId.data ?? "",
                organizationName: organization.data?.displayName ?? "",
              }),
            }
      }
      pathname={location.pathname}
    >
      Admin
    </NavLink>
  )
}

export const UserNavigation: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { showCmsDatasetToggle } = useFlags()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { logout, user } = useAuth0()
  const client = useQueryClient()
  const permissions = usePermissions()
  const handleClose = () => setAnchorEl(null)
  const organizationId = useOrganizationId()
  const organization = useOrganization(organizationId.data ?? "")
  const isParsons = organization.data?.name === PARSONS_ORG_NAME

  if (!user) return null

  return (
    <UserContainer data-testid="user-dropdown" className={className}>
      {isMobile ? (
        <Avatar
          style={{
            width: 36,
            height: 36,
            cursor: "pointer",
          }}
          alt={user.name}
          src={user.picture}
          imgProps={{ referrerPolicy: "no-referrer" }}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
          }}
        />
      ) : (
        <UserButton
          variant="text"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
          }}
          startIcon={
            <Avatar
              style={{
                width: 23,
                height: 23,
              }}
              alt={user.name}
              src={user.picture}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
          }
          endIcon={<KeyboardArrowDownIcon />}
          disableRipple={true}
          data-testid="header-user"
        >
          {user.name}
        </UserButton>
      )}

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 35,
          horizontal: 23,
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {permissions.currentUser.preferences.read && (
          <MenuItemStyled onClick={handleClose}>
            <NavLink to={{ pathname: ROUTES.PREFERENCES, search: "" }}>
              Settings
            </NavLink>
          </MenuItemStyled>
        )}

        {(permissions.organizations.read ||
          permissions.currentUser.organization.users.read) && (
          <MenuItemStyled onClick={handleClose}>
            <ManageLink
              organizationReadControl={permissions.organizations.read}
            />
          </MenuItemStyled>
        )}
        {!isParsons && (
          <MenuItemStyled onClick={handleClose}>
            <NavLink to={{ pathname: ROUTES.DOCUMENTATION, search: "" }}>
              API Documentation
            </NavLink>
          </MenuItemStyled>
        )}

        <MenuItemStyled onClick={handleClose}>
          <NavLink to={{ pathname: ROUTES.FAQ, search: "" }}>FAQ</NavLink>
        </MenuItemStyled>

        <MenuItemStyled
          onClick={() => {
            handleClose()
            // @ts-expect-error Freshdesk widget is not typed and is added in the index.html
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            FreshworksWidget?.("open")
          }}
        >
          <ButtonStyled variant="text" color="inherit" disableRipple={true}>
            Help
          </ButtonStyled>
        </MenuItemStyled>

        {showCmsDatasetToggle && (
          <MenuItemStyled>
            <SanityDatasetToggle />
          </MenuItemStyled>
        )}

        <MenuItemStyled
          onClick={() => {
            handleClose()
            mixpanel.reset()
            client.clear()
            logout({
              logoutParams: { returnTo: window.location.origin },
            }).catch(console.error)
          }}
        >
          <ButtonStyled variant="text" color="inherit" disableRipple={true}>
            Log out
          </ButtonStyled>
        </MenuItemStyled>
      </Menu>
    </UserContainer>
  )
}
