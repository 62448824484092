import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import { gridClasses } from "@mui/x-data-grid-pro"
import type { AlertWithReferences } from "@phc/common"
import { AlertLevel } from "@phc/common"
import type React from "react"
import { useMemo } from "react"
import { LEGAL_STATEMENT } from "../../utils/constants"
import { convertStringToTitleCase } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"
import { AlertDefinitionBanner } from "../Banners/AlertDefinitionBanner"
import { BaseGrid } from "../BaseGrid/BaseGrid"
import { SelectFilter } from "../BaseGrid/SelectFilter"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { MainContent } from "../MainContent"
import { PageContainer } from "../PageContainer"
import { ALERT_TABLE_COLUMNS } from "./AlertTableColumns"

const ALL_ALERTS_TEXT = "All alerts"

const StyledBaseGrid = styled(BaseGrid)(({ theme }) => ({
  [`.${gridClasses.row}`]: {
    padding: "9px 0px",
    backgroundColor: extraColors.white,
    position: "relative",
    width: "calc(100% - 6px)",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderLeftStyle: "solid",
      borderLeftWidth: "6px",
      borderLeftColor: extraColors.light,
      // let clicks through
      pointerEvents: "none",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "6px 0px 0px 6px",
      borderLeftStyle: "solid",
      borderLeftWidth: "6px",
      borderLeftColor: "inherit",
      // let clicks through
      pointerEvents: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 3px)",
      borderLeftWidth: "3px",
      "&:before": {
        borderLeftWidth: "3px",
      },
      "&:after": {
        borderLeftWidth: "3px",
        borderRadius: "3px 0px 0px 3px",
      },
    },
    [`&.${AlertLevel.ADVISORY}`]: {
      borderLeftColor: extraColors.status.greenLight,
    },
    [`&.${AlertLevel.WATCH}`]: {
      borderLeftColor: extraColors.status.orangeLight,
    },
    [`&.${AlertLevel.WARNING}`]: {
      borderLeftColor: extraColors.status.redLight,
    },
    [`.${gridClasses.virtualScroller}`]: {
      background: extraColors.light,
    },
  },
})) as typeof BaseGrid

export const AlertsPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  const { useAlerts } = useCapServiceContext()
  const { data: alerts, isLoading } = useAlerts(true)
  const columns = useMemo(() => ALERT_TABLE_COLUMNS(isMobile), [isMobile])
  // get categories, convert to title case and filter our empty values
  const categoryList = useMemo(
    () =>
      [
        ...new Set(
          alerts?.map(row =>
            convertStringToTitleCase(row.categories?.[0]?.title ?? "")
          )
        ),
      ].filter(val => val !== ""),
    [alerts]
  )

  return (
    <MainContent
      headerProps={{
        title: <>{isMobile && <WarningAmberIcon />} Alerts</>,
        documentTitle: "Alerts",
      }}
      disableLeftGutter
    >
      <AlertDefinitionBanner />
      <PageContainer>
        <StyledBaseGrid<AlertWithReferences>
          search
          filter={false}
          exported
          checkbox={false}
          columnVisibilityModel={
            {
              // columns included just for export
              title: false,
              recommendation: false,
              justification: false,
              link: false,
              // Hide columns for mobile
              geotag: !isTablet,
              category: !isMobile,
              alertLevel: !isMobile,
              actions: !isMobile,
              confidence: !isMobile,
            } satisfies Partial<
              Record<(typeof columns)[number]["field"], boolean>
            >
          }
          loading={isLoading && !alerts}
          rows={alerts ?? []}
          getRowId={row => row._id}
          columns={columns}
          initSortModel={{
            field: "date",
            sort: "desc",
          }}
          noTextOverlay="No alerts found"
          getRowHeight={() => "auto"}
          // add color to row based on alert level
          getRowClassName={params => params.row.alertLevel ?? ""}
          striped
          subNav={
            <SelectFilter
              ALL_ITEMS_TEXT={ALL_ALERTS_TEXT}
              filterField={"category"}
              items={categoryList}
            />
          }
        >
          <div style={{ paddingTop: 16 }}>
            <Typography variant="small1Bold" display="block">
              Confidential and Proprietary
            </Typography>
            <Typography variant="small1">{LEGAL_STATEMENT}</Typography>
          </div>
        </StyledBaseGrid>
      </PageContainer>
    </MainContent>
  )
}
