import { useAuth0 } from "@auth0/auth0-react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useCallback, useEffect, useMemo } from "react"
import usePersistentContext from "../../hooks/usePersistentContext"
import { NAVIGATOR_URL } from "../../utils/constants"

interface RagDataSource {
  id: string
  source_id: string
  title: string
  subject: string
  description: string
  creator: string
  publisher: string
  url: string
  publish_time: string
  source: string
  rights: string
  location_osm_id: string
  threats: string[]
}

interface RagResponse {
  data: RagDataSource[]
  rag_context: {
    /** HTML string */
    answer: string
    query: string
  }
}

export interface AskHistoryEntry {
  date: string
  /* @deprecated */
  result?: RagResponse
  ragResponse?: RagResponse
  tabularResponse?: TabularData[]
}

const navigatorAxios = axios.create({
  baseURL: NAVIGATOR_URL,
})

/**
 * This hook adds an auth interceptor to axios that uses the auth0 hook to get the access token
 * It must be called before any axios requests are made
 */
export const useNavigatorAuthInterceptor = () => {
  const { getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const authInterceptor = navigatorAxios.interceptors.request.use(
      async config => {
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
        return config
      }
    )
    return () => {
      navigatorAxios.interceptors.request.eject(authInterceptor)
    }
  }, [getAccessTokenSilently])
}

export const useAskHistory = () => {
  const [localStorage, setLocalStorage] = usePersistentContext(["ask-history"])
  const askHistory = useMemo(
    () => (localStorage ? (JSON.parse(localStorage) as AskHistoryEntry[]) : []),
    [localStorage]
  )
  const setHistory = useCallback(
    async (newResult: Omit<AskHistoryEntry, "date">) => {
      const currentHistory = localStorage
        ? (JSON.parse(localStorage) as AskHistoryEntry[])
        : []
      // if history is over 50, remove the first entry
      if (currentHistory.length > 50) {
        currentHistory.shift()
      }
      // migrate currentHistory field result to ragResponse
      currentHistory.forEach(entry => {
        if (entry.result) {
          entry.ragResponse = entry.result
          delete entry.result
        }
      })
      const newHistory = [
        ...currentHistory,
        {
          date: new Date().toISOString(),
          ragResponse: newResult.ragResponse,
          tabularResponse: newResult.tabularResponse,
        },
      ] satisfies AskHistoryEntry[]
      await setLocalStorage(JSON.stringify(newHistory))
    },
    [localStorage, setLocalStorage]
  )
  return useMemo(
    () => ({
      askHistory,
      setHistory,
    }),
    [askHistory, setHistory]
  )
}

const ASK_MUTATION_KEY = ["ask"]

// TODO: will need to use indexedDB once localstorage data gets above 5mb (probably with tabular data)
// TODO: tabular data for measles blows past this limit
// but i think we can at least keep this same api so the UI logic won't need to change
// TODO: clear history button
export const useAskApi = () => {
  const { setHistory } = useAskHistory()
  return useMutation({
    mutationKey: ASK_MUTATION_KEY,
    mutationFn: async (query: string) => {
      const ragPromise = getRagResponse(query)
      const tabularPromise = askQueryToTabularData(query)
      const [ragResponse, tabularResponse] = await Promise.allSettled([
        ragPromise,
        tabularPromise,
      ])
      // Log issues if any promises are rejected
      if (ragResponse.status === "rejected") {
        console.error("RAG Response Error:", ragResponse.reason)
        throw ragResponse.reason
      }
      if (tabularResponse.status === "rejected") {
        console.error("Tabular Response Error:", tabularResponse.reason)
      }

      return {
        ragResponse: ragResponse.value,
        tabularResponse:
          tabularResponse.status === "fulfilled"
            ? tabularResponse.value
            : undefined,
      }
    },
    onSettled: async result => {
      if (!result) return
      await setHistory({
        ragResponse: result.ragResponse,
        tabularResponse: result.tabularResponse,
      })
    },
  })
}

export const useIsAsking = () => {
  const client = useQueryClient()
  return client.isMutating({
    mutationKey: ASK_MUTATION_KEY,
  })
}

/**
 * Navigator Fetch Calls
 */

const getRagResponse = async (query: string) => {
  const result = await navigatorAxios.post<RagResponse>(
    `/rag/get_rag_response/`,
    {
      query,
      query_depth: 50,
      include_chatbot: true,
      include_context: false,
    }
  )
  return result.data
}

// Tabular Fetch Calls
const TABLE_TYPES = {
  CASES: "cases",
  VACCINATION: "vaccination",
  DEATHS: "deaths",
  HOSPITALIZATIONS: "hospitalizations",
} as const

type TableType = (typeof TABLE_TYPES)[keyof typeof TABLE_TYPES]

const TABLE_UNITS = {
  COUNT: "count",
  PERCENT: "percent",
} as const

type TableUnit = (typeof TABLE_UNITS)[keyof typeof TABLE_UNITS]

const queryThreatAnnotation = async (query: string) => {
  const result = await navigatorAxios.post<{
    disease_codes?: string[]
  }>(`/rag/query_threat_annotation/`, {
    query,
  })
  return result.data.disease_codes?.join(",")
}

const queryLocationAnnotation = async (query: string) => {
  const result = await navigatorAxios.post<{
    locations?: {
      location_id?: string
      location_source_name?: string
    }[]
    message?: string
  }>(`/rag/query_location_annotation/`, {
    query,
  })
  return result.data.locations?.map(l => l.location_id).join(",")
}

interface TabularDatum {
  id: number
  source_location: string
  location_osm_id: number
  /** ISO Date */
  start_time: string
  /** ISO Date */
  end_time: string
  value: number
  unit: TableUnit
  type: TableType
  disease_code: string
}

export interface TabularData {
  unit: TableUnit
  type: TableType
  data: TabularDatum[]
  diseaseCode: string
}

const getTabularData = async ({
  diseaseCodes,
  locationIds,
}: {
  diseaseCodes: string
  locationIds: string
}) => {
  const fetchData = async (
    unit: TableUnit,
    type: TableType
  ): Promise<TabularData | undefined> => {
    const tabularResponse = await navigatorAxios<TabularDatum[]>(
      `/rag/get_tabular_data/`,
      {
        params: {
          disease_codes: diseaseCodes,
          location_ids: locationIds,
          unit,
          type,
          paginate: false,
        },
      }
    )
    const diseaseCode = tabularResponse.data[0]?.disease_code
    if (!tabularResponse.data.length || !diseaseCode) {
      return undefined
    }
    return {
      unit,
      type,
      data: tabularResponse.data,
      diseaseCode,
    }
  }

  const fetchPromises = []

  for (const unit of Object.values(TABLE_UNITS)) {
    for (const type of Object.values(TABLE_TYPES)) {
      fetchPromises.push(fetchData(unit, type))
    }
  }

  const results = await Promise.all(fetchPromises)
  const data = results.flatMap(r => r || [])
  return data
}

const askQueryToTabularData = async (query: string) => {
  const diseaseCodes = await queryThreatAnnotation(query)
  const locationIds = await queryLocationAnnotation(query)

  if (!diseaseCodes || !locationIds) {
    return []
  }

  const data = await getTabularData({
    diseaseCodes,
    locationIds,
  })
  return data
}
