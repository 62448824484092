import { Button, styled } from "@mui/material"
import { AssetNotificationType } from "@phc-health/connect-query"
import type { Geotag } from "@phc/common"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { GLOBAL_GEOTAG } from "../../../../../common/src/constants"
import { getNonGlobalAssets } from "../../../utils/helpers/assetHelper"
import { LocationTag } from "../../LocationDetails/LocationRisk/LocationTag"
import { getGlobalEnabledSettingOfType } from "../../Settings/notifications/contexts/NotificationContext"
import { useListAssets } from "../../WatchedLocations/hooks/useAssetService"

const GAP = 8
const Container = styled("div")(() => ({
  display: "flex",
  gap: GAP,
  flexWrap: "wrap",
}))

const ShowMore = styled(Button)(({ theme }) => ({
  ...theme.typography.tinyLink,
  minHeight: "unset",
  minWidth: "unset",
  textTransform: "uppercase",
  margin: 0,
  padding: 0,
}))

/**
 * Dynamic container for geotags
 * - Renders a list of LocationTag components
 * - Show more button to expand the list
 * - Highlight relevant geotags
 * - Sort geotags by relevance on short list
 */
export const GeotagsContainer = ({
  geotags: unsortedGeotags,
}: {
  geotags: Geotag[] | undefined
}) => {
  const { data } = useListAssets({
    includeGlobal: true,
    excludeGroups: true,
  })

  const nonGlobalAssets = useMemo(
    () => getNonGlobalAssets(data.assets),
    [data.assets]
  )

  // If a customer has the globallyTaggedEnabledSetting enabled, then the global
  // pill should also be rendered in blue
  const hasGlobalSettingsEnabled = useMemo(() => {
    return getGlobalEnabledSettingOfType(
      AssetNotificationType.GLOBALLY_TAGGED_CONTENT,
      data.assets
    )
  }, [data.assets])

  const containerRef = useRef<HTMLDivElement>(null)
  const showMoreRef = useRef<HTMLButtonElement>(null)
  const tagRefs = useRef<Map<string, HTMLElement>>(new Map())
  const [overflow, setOverflow] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [numTagsHidden, setNumTagsHidden] = useState(0)
  const relevantGeotags = useMemo(
    () =>
      unsortedGeotags?.filter(geotag =>
        nonGlobalAssets.some(asset =>
          asset.baseEvent?.geotags.some(
            tag => tag.locationId === geotag.locationId
          )
        )
      ),
    [nonGlobalAssets, unsortedGeotags]
  )
  // Sort geotags by relevance if not expanded
  const geotags = useMemo(
    () =>
      [...(unsortedGeotags ?? [])].sort((a, b) => {
        // always sort global tags to the front (locationId is 000)
        if (
          a.locationId === GLOBAL_GEOTAG.locationId &&
          b.locationId !== GLOBAL_GEOTAG.locationId
        )
          return -1
        if (overflow && !expanded && relevantGeotags) {
          const aRelevant = relevantGeotags.includes(a)
          const bRelevant = relevantGeotags.includes(b)
          if (aRelevant && !bRelevant) return -1
          if (!aRelevant && bRelevant) return 1
        }
        return a.name?.localeCompare(b.name || "") || 0
      }),
    [expanded, overflow, relevantGeotags, unsortedGeotags]
  )

  useLayoutEffect(() => {
    const container = containerRef.current
    if (!container) return

    const containerWidth = container.offsetWidth
    const tags = Array(...tagRefs.current.values())
    const tagWidths = Array.from(tags).map(tag => tag.offsetWidth)
    // Calculate the total width including gaps
    const totalWidth =
      tagWidths.reduce((a, b) => a + b, 0) + GAP * (tagWidths.length - 1)
    setOverflow(totalWidth > containerWidth)

    // only show the amount of tags that will fit plus the Show More button
    if (totalWidth > containerWidth && !expanded) {
      const showMoreWidth = showMoreRef.current?.offsetWidth || 0
      const availableWidth = containerWidth - showMoreWidth
      let currentWidth = 0

      for (let i = 0; i < tagWidths.length; i++) {
        currentWidth += (tagWidths[i] ?? 0) + GAP
        if (currentWidth > availableWidth) {
          tags.slice(i).forEach(tag => {
            tag.style.visibility = "hidden"
            tag.style.position = "absolute"
          })
          setNumTagsHidden(tags.length - i)
          break
        }
      }
    } else {
      tagRefs.current.forEach(tag => {
        tag.style.visibility = "visible"
        tag.style.position = "static"
      })
    }
  }, [geotags, expanded])
  return (
    <Container ref={containerRef} onClick={e => e.stopPropagation()}>
      {geotags.map(geotag => (
        <LocationTag
          key={geotag.id}
          ref={el => {
            if (el && geotag.id) tagRefs.current.set(geotag.id, el)
          }}
          title={geotag.name || ""}
          subtitle={geotag.subtitle || ""}
          id={geotag.locationId || ""}
          style={{
            backgroundColor:
              relevantGeotags?.includes(geotag) ||
              (geotag.locationId === GLOBAL_GEOTAG.locationId &&
                hasGlobalSettingsEnabled)
                ? "rgba(176, 195, 244, 1)"
                : undefined,
          }}
        />
      ))}
      <ShowMore
        ref={showMoreRef}
        disableRipple
        style={{
          visibility: overflow ? "visible" : "hidden",
          position: overflow ? "static" : "absolute",
        }}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          setExpanded(!expanded)
        }}
      >
        {expanded ? "show less" : `+${numTagsHidden} more`}
      </ShowMore>
    </Container>
  )
}
