import { create } from "@bufbuild/protobuf"
import type {
  Asset,
  Bucket,
  Geotag,
  MapboxLocation,
} from "@phc-health/connect-query"
import {
  AssetRegion,
  AssetSchema,
  AssetType,
  BaseEventSchema,
  IndustryType,
  MapboxLocationSchema,
  PlaceType,
} from "@phc-health/connect-query"
import {
  getCentroidCoordinates,
  getTileSetResponse,
  type GeocoderResult,
} from "@phc/common"
import { getEnumKey, getLocationId } from "../helpers"

export const isAdminBoundaryFromPlaceType = (
  placeType?: PlaceType
): boolean => {
  if (!placeType) return false
  switch (placeType) {
    case PlaceType.COUNTRY:
    case PlaceType.REGION:
    case PlaceType.POSTCODE:
    case PlaceType.DISTRICT:
      return true
    case PlaceType.ADDRESS:
    case PlaceType.LOCALITY:
    case PlaceType.NEIGHBORHOOD:
    case PlaceType.PLACE:
    case PlaceType.POI:
    default:
      return false
  }
}

export const assetTypeEnumToString = (assetType?: AssetType): string => {
  switch (assetType) {
    case AssetType.AGRIBUSINESS:
      return "Farm/Agribusiness"
    case AssetType.AIRPORT:
      return "Airport"
    case AssetType.ASSET_TYPE_OTHER:
      return "Other"
    case AssetType.BAR_NIGHTCLUB:
      return "Bar/Nightclub"
    case AssetType.CALL_CENTER:
      return "Call Center"
    case AssetType.COMMAND_CENTER:
      return "Command Center"
    case AssetType.COMMERCIAL_KITCHEN:
      return "Commercial Kitchen"
    case AssetType.DATA_CENTER:
      return "Data Center"
    case AssetType.DISTRIBUTION_CENTER:
      return "Distribution Center"
    case AssetType.EDUCATIONAL_INSTITUTION:
      return "Educational Institution"
    case AssetType.FINANCIAL_INSTITUTION:
      return "Bank/Financial Institution"
    case AssetType.FITNESS_CENTER:
      return "Gym/Fitness Center"
    case AssetType.HOTEL:
      return "Hotel/Motel"
    case AssetType.INDOOR_EVENT_VENUE:
      return "Event Venue - Indoor"
    case AssetType.MANUFACTURING_PLANT:
      return "Manufacturing Plant/Factory"
    case AssetType.MEDICAL_CENTER:
      return "Medical Clinic/Hospital"
    case AssetType.MOVIE_THEATER:
      return "Movie Theater"
    case AssetType.OFFICE:
      return "Office Building"
    case AssetType.OUTDOOR_EVENT_VENUE:
      return "Event Venue - Outdoor"
    case AssetType.RESTAURANT_CAFE:
      return "Restaurant/Cafe"
    case AssetType.RETAIL_STORE:
      return "Retail Store"
    case AssetType.SPA_SALON:
      return "Spa/Salon"
    case AssetType.WAREHOUSE:
      return "Warehouse"
    case AssetType.ASSET_TYPE_UNSPECIFIED:
    case undefined:
      return "Unspecified"
  }
}

export const industryTypeEnumToString = (
  industryType?: IndustryType
): string => {
  switch (industryType) {
    case IndustryType.FINANCE:
      return "Finance"
    case IndustryType.INFORMATION:
      return "Information"
    case IndustryType.PROFESSIONAL_SERVICES:
      return "Professional Services"
    case IndustryType.RETAIL:
      return "Retail"
    case IndustryType.TRANSPORTATION_AND_WAREHOUSING:
      return "Transportation and Warehousing"
    case IndustryType.BASELINE:
      return "Baseline"
    case IndustryType.UNSPECIFIED:
    case undefined:
      return "Unspecified"
  }
}

export const placeTypeStringToEnum = (placeType?: string): PlaceType => {
  switch (placeType) {
    case "address":
      return PlaceType.ADDRESS
    case "country":
      return PlaceType.COUNTRY
    case "district":
      return PlaceType.DISTRICT
    case "locality":
      return PlaceType.LOCALITY
    case "neighborhood":
      return PlaceType.NEIGHBORHOOD
    case "place":
      return PlaceType.PLACE
    case "poi":
      return PlaceType.POI
    case "postcode":
      return PlaceType.POSTCODE
    case "region":
      return PlaceType.REGION
    case undefined:
    default:
      return PlaceType.UNSPECIFIED
  }
}

const mapContextFieldsToMapboxLocation = (
  mapboxLocation: MapboxLocation,
  fieldName: string,
  fieldValue: string
) => {
  switch (fieldName) {
    case PlaceType[PlaceType.ADDRESS].toLowerCase():
      mapboxLocation.address = fieldValue
      break
    case PlaceType[PlaceType.COUNTRY].toLowerCase():
      mapboxLocation.country = fieldValue
      break
    case PlaceType[PlaceType.DISTRICT].toLowerCase():
      mapboxLocation.district = fieldValue
      break
    case PlaceType[PlaceType.LOCALITY].toLowerCase():
      mapboxLocation.locality = fieldValue
      break
    case PlaceType[PlaceType.NEIGHBORHOOD].toLowerCase():
      mapboxLocation.neighborhood = fieldValue
      break
    case PlaceType[PlaceType.PLACE].toLowerCase():
      mapboxLocation.place = fieldValue
      break
    case PlaceType[PlaceType.POI].toLowerCase():
      mapboxLocation.poi = fieldValue
      break
    case PlaceType[PlaceType.POSTCODE].toLowerCase():
      mapboxLocation.postcode = fieldValue
      break
    case PlaceType[PlaceType.REGION].toLowerCase():
      mapboxLocation.region = fieldValue
      break
    case PlaceType[PlaceType.UNSPECIFIED].toLowerCase():
      break
  }
}

export const convertGeocoderResultToLocation = (
  geo?: GeocoderResult
): MapboxLocation => {
  const mapboxLocation = create(MapboxLocationSchema)
  if (!geo?.result) return mapboxLocation

  const { address, text, place_type, id, place_name } = geo.result
  mapboxLocation.id = id
  mapboxLocation.address = address ?? ""
  mapboxLocation.text = text
  mapboxLocation.placeType = placeTypeStringToEnum(place_type.toString())
  mapboxLocation.placeName = place_name

  geo.result.context?.forEach(context => {
    const parsedContextField = context.id.split(".")[0]
    if (!parsedContextField) {
      console.error("Could not parse context field from geocoder result")
      return
    }

    mapContextFieldsToMapboxLocation(
      mapboxLocation,
      parsedContextField,
      context.text
    )
  })

  return mapboxLocation
}

export const convertBucketToAsset = async (bucket: Bucket) => {
  const locationId = getLocationId(bucket)

  const title = bucket.baseEvent?.geotags[0]?.displayName
  const geotag = bucket.baseEvent?.geotags[0]
  const location = geotag?.externalLocationCodes

  const mapboxLocation = create(MapboxLocationSchema, {
    region: location?.worldRegionUnM49Name,
    district: location?.worldSubregionUnM49Name,
    country: location?.countryFips104Code,
    placeType: getPlaceType(geotag),
    placeName: await getPlaceName(geotag),
  })

  const baseEvent = create(BaseEventSchema, {
    geotags: [
      {
        locationId,
        wktCentroid: geotag?.wktCentroid,
        mapboxLocation,
      },
    ],
  })

  return create(AssetSchema, {
    name: title,
    baseEvent,
    assetTypes: [AssetType.ASSET_TYPE_UNSPECIFIED],
  })
}

const getPlaceType = (geotag?: Geotag) => {
  if (!geotag) return PlaceType.UNSPECIFIED
  if (geotag.locationId.length > 5) return PlaceType.DISTRICT
  if (geotag.locationId.length > 3) return PlaceType.REGION
  return PlaceType.COUNTRY
}

const getPlaceName = async (geotag?: Geotag) => {
  if (!geotag) return ""
  const location = geotag.externalLocationCodes
  const coords = getCentroidCoordinates(geotag.wktCentroid)
  if (!coords) throw new Error("Could not get centroid coordinates")
  if (!location?.countryFips104Code)
    throw new Error("Could not get country code from location")
  const mapboxResponse = await getTileSetResponse(
    coords,
    import.meta.env.MAPBOX_API_KEY,
    location.countryFips104Code
  )
  return mapboxResponse?.features
    .reverse()
    .map(feature => feature.properties.location_name)
    .join(", ")
}

export const getAssetStringToEnumMap = () => {
  const assetStringToEnumMap = new Map<string, AssetType>()

  Object.values(AssetType)
    .filter(assetType => typeof assetType !== "string")
    .forEach(assetType => {
      const enumValue = assetType
      if (typeof enumValue !== "string") {
        const stringValue = assetTypeEnumToString(enumValue)
        assetStringToEnumMap.set(stringValue, enumValue)
      }
    })

  return assetStringToEnumMap
}

export const assetTypeStringList = Object.values(AssetType)
  .map(assetType => {
    if (
      typeof assetType !== "string" &&
      assetType !== AssetType.ASSET_TYPE_UNSPECIFIED
    ) {
      return assetTypeEnumToString(assetType)
    }
    return ""
  })
  .filter(val => val !== "")
  .sort()

// add AssetType.ASSET_TYPE_UNSPECIFIED to the beginning of the list
assetTypeStringList.unshift(
  assetTypeEnumToString(AssetType.ASSET_TYPE_UNSPECIFIED)
)

export const getIndustryStringToEnumMap = () => {
  const industryStringToEnumMap = new Map<string, IndustryType>()

  Object.values(IndustryType).forEach(industry => {
    if (typeof industry !== "string") {
      const stringValue = industryTypeEnumToString(industry)
      industryStringToEnumMap.set(stringValue, industry)
    }
  })

  return industryStringToEnumMap
}

export const industryStringList = Object.values(IndustryType)
  .flatMap(industry =>
    typeof industry !== "string" ? industryTypeEnumToString(industry) : []
  )
  .sort()

const isGlobalAsset = (asset: Asset) => {
  return asset.name === getEnumKey(AssetRegion, AssetRegion.PLANET_EARTH)
}

export const getNonGlobalAssets = (assets?: Asset[]) => {
  return assets?.filter(asset => !isGlobalAsset(asset)) ?? []
}
