import { useMutation } from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../../utils/constants"
import { useInvalidateAssetQueries } from "../../hooks/useAssetService"

export const useUpdateAsset = () => {
  const { invalidateAssetQueries } = useInvalidateAssetQueries()
  const { enqueueSnackbar } = useSnackbar()
  const successMessage = "Your location has been updated."
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.updateAsset, {
    transport,
    onError: err => {
      enqueueSnackbar("Unable to update this location.", {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar(successMessage, {
        variant: "success",
      })
    },
    onSettled: async () => {
      await invalidateAssetQueries()
    },
  })
}

export const useUpdateAssets = () => {
  const { invalidateAssetQueries } = useInvalidateAssetQueries()
  const { enqueueSnackbar } = useSnackbar()
  const typeText = "locations"
  const successMessage = `Your ${typeText} have been updated.`
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.updateAssets, {
    transport,
    onError: err => {
      enqueueSnackbar(`Unable to update your ${typeText}.`, {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar(successMessage, {
        variant: "success",
      })
    },
    onSettled: async () => {
      await invalidateAssetQueries()
    },
  })
}
