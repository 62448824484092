import { CircularProgress, styled, Typography } from "@mui/material"
import { useMemo } from "react"
import { extraColors } from "../../utils/theme"
import { AskCharts } from "./AskCharts"
import { AskFinePrint } from "./AskFinePrint"
import { AskSources } from "./AskSources"
import { processAnswerContent } from "./askUtils"
import { useIsAsking, type AskHistoryEntry } from "./useAskApi"

export const AskLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.tiny,
  color: extraColors.medium,
  display: "block",
  paddingBottom: 2,
  textTransform: "uppercase",
}))

const ResultContainer = styled("div")(({ theme }) => ({
  padding: 24,
  borderRadius: 2,
  background: extraColors.subtle,
  display: "grid",
  gap: 24,
  overflow: "auto",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    padding: 12,
  },
}))

const Response = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 2,
  background: extraColors.white,
  padding: 12,
  display: "grid",
  gap: 12,
  "& p": {
    ...theme.typography.body2,
    margin: 0,
  },
  // gradient border
  zIndex: 1,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    padding: "1px", // Adjust this value to control the border width
    background:
      "linear-gradient(270deg, #18345E 5.83%, #810099 52.08%, #FFBF34 94.08%)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "xor",
    maskComposite: "exclude",
    zIndex: -1,
  },
}))

export const AskResult = ({
  selectedHistory,
}: {
  selectedHistory: AskHistoryEntry
}) => {
  const { answerContent, sources } = useMemo(
    () =>
      processAnswerContent(
        selectedHistory.ragResponse?.rag_context.answer ?? ""
      ),
    [selectedHistory.ragResponse?.rag_context.answer]
  )
  const isAsking = useIsAsking()
  if (isAsking) {
    return (
      <ResultContainer>
        <CircularProgress style={{ justifySelf: "center" }} />
      </ResultContainer>
    )
  }
  return (
    <ResultContainer>
      <div>
        <AskLabel>Question</AskLabel>
        <Typography variant="body2">
          {selectedHistory.ragResponse?.rag_context.query}
        </Typography>
      </div>
      <div>
        <AskLabel>Response</AskLabel>
        <Response
          dangerouslySetInnerHTML={{
            __html: answerContent,
          }}
        />
      </div>
      <AskSources sources={sources} />
      {/* Optional charts */}
      {selectedHistory.tabularResponse?.map(tabularData => (
        <AskCharts
          key={tabularData.diseaseCode + tabularData.type + tabularData.unit}
          tabularData={tabularData}
        />
      ))}
      <AskFinePrint />
    </ResultContainer>
  )
}
