import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import { styled } from "@mui/material/styles"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCallback, useState } from "react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { extraColors } from "../../utils/theme"
import { AskDialog } from "./AskDialog"
import { BetaTag } from "./BetaTag"

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "fit-content",
  [theme.breakpoints.up("md")]: {
    marginTop: "auto",
  },
}))

const StyledAskNavButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "showDialog",
})<{ showDialog: boolean }>(({ theme, showDialog }) => ({
  ...theme.typography.body1,
  color: extraColors.navy,
  display: "flex",
  alignItems: "center",
  gap: "6px",
  padding: "20px 0 0 4px",
  [theme.breakpoints.up("md")]: {
    ...theme.typography.body1Bold,
    justifyContent: "center",
    padding: "7px 12px",
    borderRadius: "6px",
    background: showDialog
      ? "linear-gradient(270deg, #FFF2D6 6.77%, #E6CCEB 50%, #D1D6DF 93.75%)"
      : extraColors.light,
    boxShadow:
      "0px 1px 1px 0px rgba(0, 0, 0, 0.14) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset",
  },
}))

interface AskNavButtonProps {
  isNavOpen: boolean
  setNavOpen: (open: boolean) => void
}

export const AskNavButton = ({ isNavOpen, setNavOpen }: AskNavButtonProps) => {
  const isMobile = useIsMobile()
  const { showAsk } = useFlags()
  const [showDialog, setShowDialog] = useState(false)
  const onClick = useCallback(() => {
    setShowDialog(curr => !curr)
    if (isMobile) {
      setNavOpen(false)
    }
  }, [isMobile, setNavOpen])
  if (!showAsk) {
    return null
  }
  return (
    <>
      <StyledListItem>
        <StyledAskNavButton
          disableRipple
          onClick={onClick}
          showDialog={showDialog}
        >
          <ListItemIcon
            sx={theme => ({
              minWidth: "32px",
              [theme.breakpoints.up("md")]: {
                minWidth: "unset",
              },
            })}
          >
            <AutoAwesomeOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {isNavOpen && (
            <>
              PHC Navigator <BetaTag />
            </>
          )}
        </StyledAskNavButton>
      </StyledListItem>
      <AskDialog isOpen={showDialog} setIsOpen={setShowDialog} />
    </>
  )
}
