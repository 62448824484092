import { Skeleton } from "@mui/material"
import { PortableText } from "@portabletext/react"
import { useNavigate } from "react-router"
import { useCapServiceContext } from "../components/CriticalThreats/capServiceContext"
import { MainContent } from "../components/MainContent"
import { PageContainer } from "../components/PageContainer"
import { NotFoundContent } from "../components/PageNotFound"
import {
  BodyContent,
  Content,
  Title,
  TopContentContainer,
} from "../components/PostUI"
import Button from "../components/Shared/Button"
import { AdminContentTags } from "../utils/constants"
import { theme } from "../utils/theme"
import { components } from "./SinglePost/PostComponents"

const Sources: React.FC = () => {
  const navigate = useNavigate()
  const { useAdminPost, sanityDataset } = useCapServiceContext()
  const { data, isLoading } = useAdminPost(AdminContentTags.SOURCES)

  const post = data?.[0]

  if (data && !data.length) {
    return (
      <MainContent
        headerProps={{
          title: "Page Not Found",
        }}
      >
        <NotFoundContent />
      </MainContent>
    )
  }

  return (
    <>
      <MainContent
        headerProps={{
          title: "",
        }}
      >
        <PageContainer>
          <Content>
            <TopContentContainer>
              <Button
                sx={{
                  ...theme.typography.small1Bold,
                  padding: 0,
                }}
                onClick={() => void navigate(-1)}
              >
                {"<"} Back
              </Button>
            </TopContentContainer>
            <BodyContent>
              {isLoading ? (
                <Skeleton width={800} />
              ) : (
                <Title>{post?.title ?? ""}</Title>
              )}
              {isLoading ? (
                <Skeleton width={800} height={550} />
              ) : (
                <PortableText
                  value={post?.body ?? []}
                  components={components(sanityDataset)}
                />
              )}
            </BodyContent>
          </Content>
        </PageContainer>
      </MainContent>
    </>
  )
}

export default Sources
