import { AssetType } from "@phc-health/connect-query"
import { z } from "zod"

export const assetCSVSchema = z.object({
  Name: z.string().describe("Name"),
  Type: z
    .string()
    .describe("Type")
    .optional()
    .transform(t => {
      const type = AssetType[t as keyof typeof AssetType]
      return type ? type : AssetType.ASSET_TYPE_UNSPECIFIED
    }),
  Latitude: z.string().describe("Latitude").optional(),
  Longitude: z.string().describe("Longitude").optional(),
  Address: z.string().describe("Address").optional(),
  "Threat Radius": z
    .string()
    .describe("Threat Radius")
    .optional()
    .transform(s => (s ? parseInt(s) : undefined)),
  Groups: z
    .string()
    .transform(g => g.split(",").map(s => s.trim()))
    .describe("Groups"),
  Error: z.string().optional(),
})

// This validates that address or lat/lng are provided.
// Refinements prevent shape data from being exported from zod schemas,
// so this has to happen separately from the schema model creation if we want
// to validate the presence of separate columns and access schema shapes elsewhere.
export const locationValidatedAssetSchema = assetCSVSchema
  .refine(data => !!(data.Latitude && data.Longitude) || !!data.Address, {
    message: "Either Address or both Latitude and Longitude must be provided",
    path: ["Address"],
  })
  .refine(
    data => {
      const radius = data["Threat Radius"]?.toString()
      return !radius || !isNaN(parseInt(radius))
    },
    {
      message: "Threat radius must be a number",
      path: ["Threat Radius"],
    }
  )

export type AssetCSVRow = z.infer<typeof locationValidatedAssetSchema>

export const assetSchemaArray = z.array(locationValidatedAssetSchema)
