import { Link, Typography } from "@mui/material"
import { extraColors } from "../../utils/theme"
import { AskLabel } from "./AskResult"

export const AskSources = ({ sources }: { sources: HTMLLIElement[] }) => {
  return (
    <div>
      <AskLabel>Sources</AskLabel>
      <ol style={{ margin: 0, paddingLeft: 12 }}>
        {sources.map((source, index) => {
          const link = source.querySelector("a")
          return (
            link && (
              <Typography variant="tinyLink" component="li" key={index} m={0}>
                <Link
                  variant="tinyLink"
                  color={extraColors.purpleMiddle}
                  href={link.href}
                  style={{ wordBreak: "break-word" }}
                  title={link.href}
                >
                  {link.textContent}
                </Link>
              </Typography>
            )
          )
        })}
      </ol>
    </div>
  )
}
