import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import { extraColors } from "../../../../utils/theme"

const LinkStyled = styled("a")({
  color: extraColors.purple,
  fontWeight: 600,
  textDecoration: "none",
})

export const FileDownloadLink: React.FC<{
  path: string
}> = ({ path }) => {
  return (
    <Typography
      variant="body2"
      sx={{ alignSelf: "start", color: extraColors.medium }}
    >
      Download CSV template{" "}
      <LinkStyled href={path} target="_blank" rel="noreferrer">
        here
      </LinkStyled>
    </Typography>
  )
}
