import type { Asset } from "@phc-health/connect-query"

import type { AssetCSVRow } from "./assetSchema"
import { UploadMode, useBulkAssetContext } from "./BulkAssetUploadContext"
import {
  BULK_ASSET_GROUP,
  makeAssetFromCSVAsset,
} from "./makeAssetFromCSVAsset"
import { useParseBulkCSV } from "./parseBulkCSV"

export const useParseLocationsFromCSV = ({
  assets,
  isComponentLoading,
}: {
  assets?: Asset[]
  isComponentLoading: boolean
}) => {
  const { setError, setNewAssets, setAssetsToBeRemoved, uploadMode } =
    useBulkAssetContext()
  const { parseBulkCSV } = useParseBulkCSV()
  const { prepareNewAssets } = usePrepareNewAssets()

  const handleCSV = async (file: File) => {
    try {
      const assetCSVRows = await parseBulkCSV(file)
      if (!assetCSVRows?.length) return

      if (isComponentLoading) {
        setError("Existing assets still loading, please try again")
        return
      }

      // check name against existing assets
      const uniqueAssets = assetCSVRows.filter(
        (asset: AssetCSVRow) =>
          !assets?.find(existingAsset => existingAsset.name === asset.Name)
      )

      if (!uniqueAssets.length && uploadMode === UploadMode.APPEND) {
        setError("No new unique locations detected in uploaded file.")
        return
      }

      const { successfulAssets } = await prepareNewAssets(uniqueAssets)

      setNewAssets(successfulAssets)
      setAssetsToBeRemoved(getRemovableAssets(successfulAssets, assetCSVRows))
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
    }
  }

  return { handleCSV }
}

const usePrepareNewAssets = () => {
  const {
    setTotalJobs,
    setCompletedJobs,
    setInProgressJobs,
    setPreparedAssetErrors,
    resetJobProgress,
  } = useBulkAssetContext()

  const prepareNewAssets = async (assets: AssetCSVRow[]) => {
    console.time("prepareNewAssets")

    setTotalJobs(assets.length)
    setInProgressJobs(0)
    setCompletedJobs(0)

    const promises = assets.map(async asset => {
      setInProgressJobs(prev => prev + 1)

      const {
        asset: newAsset,
        error,
        csvAsset,
      } = await makeAssetFromCSVAsset(asset)

      if (error) {
        setPreparedAssetErrors(prev =>
          prev.concat([{ ...csvAsset, Error: error }])
        )
        throw new Error(`Asset processing failed: ${error}`)
      }

      setCompletedJobs(prev => prev + 1)
      return newAsset
    })

    const results = await Promise.allSettled(promises)

    resetJobProgress()

    console.timeEnd("prepareNewAssets")

    // TODO: Remove once error handling/validation is complete
    logErrorsToConsole(results)

    const successfulAssets = results.flatMap(result =>
      result.status === "fulfilled" && result.value ? result.value : []
    )
    return { successfulAssets }
  }

  return { prepareNewAssets }
}

const logErrorsToConsole = (
  results: PromiseSettledResult<Asset | undefined>[]
) => {
  const erroredAssets = results.flatMap(result =>
    result.status === "rejected" ? result : []
  )

  if (erroredAssets.length > 0) {
    console.error("erroredAssets", erroredAssets)
  }
}

const getRemovableAssets = (
  newAssets: Asset[],
  assetCSVRows: AssetCSVRow[]
) => {
  return newAssets.filter(
    existingAsset =>
      // existing assets that are not in the new list
      !assetCSVRows.find(asset => asset.Name === existingAsset.name) &&
      // only remove assets that are in the bulk group
      existingAsset.assetGroups.map(g => g.name).includes(BULK_ASSET_GROUP)
  )
}

/**
 * Error geo coding:
 * [
    "Error: No feature found for: -122.464936,37.673739",
    "Error: No feature found for: -122.217683,37.475146",
    "Error: No feature found for: -122.416424,37.636877",
    "Error: No feature found for: -122.283784,37.558918",
    "Error: No feature found for: -122.470173,37.670553",
    "Error: No feature found for: -122.135466,37.460057",
    "Error: No feature found for: -122.48288,37.70032",
    "Error: No feature found for: -121.774363,36.915542",
    "Error: No feature found for: -121.968761,36.974784",
    "Error: No feature found for: -122.031931,37.048364",
    "Error: No feature found for: -87.126274,30.601174",
    "Error: No feature found for: -75.672877,36.037805",
    "Error: No feature found for: -70.347774,43.634242",
    "Error: No feature found for: -122.42,37.63539"
]
 */
