import type { User } from "@phc-health/connect-query"
import type { ChangeEvent } from "react"
import { useCallback, useMemo } from "react"
import { useRoles } from "../../hooks/useRoles"

import { TextField } from "@mui/material"
import { getUserStatusEnumFromString } from "../../utils/helpers/identityHelpers"
import { FormContainer, UserFormLoading } from "./UserFormShared"
import { UserRoleDropdown } from "./UserRoleDropdown"
import { UserStatusEnumDropdown } from "./UserStatusEnumDropdown"

/**
 * INTERFACES
 */

interface EditUserFormProps {
  updatedUser: User
  setUpdatedUser: (user: User) => void
}

/**
 * COMPONENTS
 */

export const EditUserForm: React.FC<EditUserFormProps> = ({
  updatedUser,
  setUpdatedUser,
}) => {
  const { data } = useRoles()
  const { displayName, userId, role, status } = updatedUser

  const userRoleValue = useMemo(() => {
    return data?.roles.find(r => r.name === role)
  }, [data?.roles, role])

  const handleRoleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUpdatedUser({
        ...updatedUser,
        role: event.target.value,
      })
    },
    [setUpdatedUser, updatedUser]
  )

  const handleStatusChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newStatus = getUserStatusEnumFromString(event.target.value)
      setUpdatedUser({
        ...updatedUser,
        status: newStatus,
      })
    },
    [setUpdatedUser, updatedUser]
  )

  if (!data?.roles) return <UserFormLoading />

  return (
    <FormContainer>
      <TextField label="Name" disabled value={displayName} />
      <TextField label="Email" disabled value={userId} />
      <UserRoleDropdown
        userRoleName={userRoleValue?.name}
        roles={data.roles}
        onChange={handleRoleChange}
        userEmail={updatedUser.userId}
      />
      <UserStatusEnumDropdown
        accountStatus={status}
        onChange={handleStatusChange}
      />
    </FormContainer>
  )
}
