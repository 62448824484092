import {
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  useGridApiContext,
  useGridApiRef,
  type DataGridProProps,
  type GridRowParams,
  type GridRowSelectionModel,
} from "@mui/x-data-grid-pro"
import type { Asset } from "@phc-health/connect-query"
import { useFlags } from "launchdarkly-react-client-sdk"
import type { ReactNode } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { generatePath, useNavigate } from "react-router"
import NoData from "../../assets/svg-components/NoData"
import { useSearchParams } from "../../hooks/useSearchParams"
import { ROUTES, TRUE } from "../../utils/constants"
import { convertStringToTitleCase } from "../../utils/helpers"
import { assetTypeStringList } from "../../utils/helpers/assetHelper"
import { BORDER_RADIUS, extraColors } from "../../utils/theme"
import { BaseGrid } from "../BaseGrid/BaseGrid"
import { SelectFilter } from "../BaseGrid/SelectFilter"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { MainContent } from "../MainContent"
import { PageContainer } from "../PageContainer"
import Button from "../Shared/Button"
import { AssetBulkDialog } from "./AssetManagement/BulkAssetUpload/AssetBulkDialog"
import { BulkAssetContextProvider } from "./AssetManagement/BulkAssetUpload/BulkAssetUploadContext"
import { EditAssetsDialog } from "./AssetManagement/EditAssetsDialog"
import { ManageAssetDialog } from "./AssetManagement/ManageAssetDialog"
import { RemoveAssetDialog } from "./AssetManagement/RemoveAssetDialog"
import { useListAssetGroups, useListAssets } from "./hooks/useAssetService"
import { LocationActionButton } from "./LocationActionButton"
import { locationColumnInfo } from "./locationColumnInfo"

const PageContainerStyled = styled(PageContainer)({
  border: "none",
  borderRadius: BORDER_RADIUS,
})

const AddLocationTextButton = styled(Button)(({ theme }) => ({
  padding: 0,
  ...theme.typography.small1Bold,
  color: extraColors.status.teal,
})) as typeof Button

const EditButton = styled(Button)(({ theme }) => ({
  height: 40,
  lineHeight: "16px",
  margin: "6px 0",
  [theme.breakpoints.up("sm")]: {
    margin: "0 10px 0 0",
  },
}))

export const WatchedLocations: React.FC = () => {
  const { data: assets, isLoading } = useListAssets({ includeGlobal: false })

  return (
    <MainContent
      headerProps={{
        title: "Locations",
      }}
    >
      <PageContainerStyled>
        <LocationsTable
          isLocationSelector={false}
          assets={assets.assets}
          assetsLoading={isLoading}
        />
      </PageContainerStyled>
    </MainContent>
  )
}
export const LocationsTable: React.FC<{
  assets?: Asset[]
  assetsLoading: boolean
  isLocationSelector: boolean
  className?: string
  selectedAssets?: Asset[]
  setDialogSelectedAssets?: React.Dispatch<
    React.SetStateAction<Asset[] | undefined>
  >
  dialogSelectedAssets?: Asset[]
}> = ({
  assets,
  assetsLoading,
  isLocationSelector,
  className,
  selectedAssets,
  setDialogSelectedAssets,
  dialogSelectedAssets,
}) => {
  const { showBulkUpload } = useFlags()
  const navigate = useNavigate()
  const { data: assetGroups } = useListAssetGroups()

  const selectedAssetGridModel =
    useMemo(() => {
      return selectedAssets?.map(asset => asset.assetId)
    }, [selectedAssets]) || []

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>(
    selectedAssetGridModel
  )
  const [editRow, setEditRow] = useState<Asset>()
  const [removeAssetIds, setRemoveAssetIds] = useState<string[]>()
  const gridApiRef = useGridApiRef()
  const [assetModalOpen, setAssetModalOpen] = useState(false)
  const [assetBulkModalOpen, setAssetBulkModalOpen] = useState(false)
  const [showAssetBulkEdit, setShowAssetBulkEdit] = useState(false)

  const openAssetModal = () => setAssetModalOpen(true)
  const clearRemoveAssetIds = () => setRemoveAssetIds(undefined)
  const { useCriticalThreats } = useCapServiceContext()
  const { data: criticalThreats, isLoading: criticalThreatsLoading } =
    useCriticalThreats()

  // this fn handles the add location menu button based on the value of the menu selection
  const openModal = (isBulk: boolean) => {
    if (isBulk) {
      setAssetBulkModalOpen(true)
    } else {
      setAssetModalOpen(true)
    }
  }

  const assetsById = useMemo(() => {
    return assets?.reduce((byId, asset) => {
      byId.set(asset.assetId, asset)
      return byId
    }, new Map<string, Asset>())
  }, [assets])

  const selectedRowSet: Set<string> = useMemo(() => {
    return selectedRows.reduce((rowSet, row) => {
      return rowSet.add(row.toString())
    }, new Set<string>())
  }, [selectedRows])

  const selectedRowAssets = useMemo(() => {
    return Array.from(
      selectedRows.flatMap(row => assetsById?.get(row.toString()) ?? [])
    )
  }, [assetsById, selectedRows])

  const handleAssetModalClose = () => {
    setEditRow(undefined)
    setAssetModalOpen(false)
  }

  const columns = locationColumnInfo(
    setEditRow,
    setAssetModalOpen,
    setRemoveAssetIds,
    isLocationSelector,
    criticalThreats
  )

  const handleCellClick = (rowData: GridRowParams<Asset>) => {
    const field = columns.find(c => c.field === "address")
    if (!field) return

    void navigate(
      generatePath(ROUTES.ASSET, {
        assetId: rowData.row.assetId,
      })
    )
  }

  // Update both the row selection model for populating checkboxes and the
  // dialog's selected asset list when a row is clicked in the location selector
  const handleLocationSelectorRowClick = useCallback(
    (rowData: GridRowParams<Asset>) => {
      const selectedAsset = assetsById?.get(rowData.id.toString())
      if (selectedRowSet.has(rowData.id.toString())) {
        setSelectedRows(selectedRows.filter(row => row !== rowData.id))

        if (selectedAsset && setDialogSelectedAssets) {
          setDialogSelectedAssets(
            dialogSelectedAssets?.filter(
              asset => asset.assetId !== selectedAsset.assetId
            )
          )
        }
      } else {
        setSelectedRows(selectedRows.concat(rowData.id.toString()))

        if (setDialogSelectedAssets && selectedAsset) {
          setDialogSelectedAssets(
            (dialogSelectedAssets || []).concat(selectedAsset)
          )
        }
      }
    },
    [
      assetsById,
      dialogSelectedAssets,
      selectedRowSet,
      selectedRows,
      setDialogSelectedAssets,
    ]
  )

  const handleRowSelection = useCallback<
    NonNullable<DataGridProProps["onRowSelectionModelChange"]>
  >(
    rowSelectionModel => {
      setSelectedRows(rowSelectionModel)

      if (setDialogSelectedAssets) {
        const selected = rowSelectionModel.flatMap(assetId => {
          const asset = assetsById?.get(assetId.toString())
          return asset ? asset : []
        })
        setDialogSelectedAssets(selected)
      }
    },
    [assetsById, setDialogSelectedAssets]
  )

  const groupList = React.useMemo(
    () =>
      [
        ...new Set(
          assets?.flatMap(row =>
            row.assetGroups.map(g => convertStringToTitleCase(g.name))
          )
        ),
      ]
        .filter(val => val !== "")
        .sort((a, b) => a.localeCompare(b)),
    [assets]
  )

  const addToSelectedAssets = useCallback(
    (newAsset: Asset) => {
      setSelectedRows(selectedRows.concat(newAsset.assetId))
      setDialogSelectedAssets?.((dialogSelectedAssets || []).concat(newAsset))
    },
    [dialogSelectedAssets, selectedRows, setDialogSelectedAssets]
  )

  return (
    <>
      <BaseGrid
        className={className}
        apiRef={gridApiRef}
        loading={assetsLoading || criticalThreatsLoading}
        exported={!isLocationSelector}
        rows={assets ?? []}
        columns={columns}
        getRowId={row => row.assetId}
        onRowClick={
          !isLocationSelector ? handleCellClick : handleLocationSelectorRowClick
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleRowSelection}
        placeholder="Search Locations"
        initSortModel={{
          field: "name",
          sort: "asc",
        }}
        columnVisibilityModel={{
          trend: false,
          severity: false,
        }}
        filter={false}
        subNav={
          <>
            <SelectFilter
              ALL_ITEMS_TEXT="All Groups"
              placeholder="Group"
              items={groupList}
              filterField={"group"}
            />
            {!isLocationSelector && (
              <SelectFilter
                ALL_ITEMS_TEXT="All Asset Types"
                placeholder="Asset Type"
                items={assetTypeStringList}
                filterField={"assetType"}
              />
            )}
            {!isLocationSelector && criticalThreats?.length && (
              <AffectedByCriticalThreatFilter />
            )}
          </>
        }
        noTextOverlay="No watched locations found"
        noRowsActionElement={
          <NoRowsActionElement openAssetModal={openAssetModal} />
        }
        noResultsActionElement={
          <NoResultsActionElement openAssetModal={openAssetModal} />
        }
        headerActionButton={
          <>
            {!showBulkUpload ? (
              <SingleLocationActionButton openAssetModal={openAssetModal}>
                Add Location
              </SingleLocationActionButton>
            ) : (
              <LocationActionButton
                openModal={(isBulk: boolean) => openModal(isBulk)}
              />
            )}
          </>
        }
        footerActionButton={
          <FooterActionButton
            isLocationSelector={isLocationSelector}
            disabled={!selectedRows.length}
            onClick={() => setShowAssetBulkEdit(true)}
          />
        }
      />
      {removeAssetIds && removeAssetIds.length > 0 && !isLocationSelector && (
        <RemoveAssetDialog
          clearRemoveAssetIds={clearRemoveAssetIds}
          assetIds={removeAssetIds}
          onClose={() => setRemoveAssetIds(undefined)}
        />
      )}
      {showAssetBulkEdit &&
        selectedRowAssets.length > 0 &&
        !isLocationSelector && (
          <EditAssetsDialog
            assets={selectedRowAssets}
            onClose={() => {
              setShowAssetBulkEdit(false)
              setSelectedRows([])
            }}
          />
        )}
      {assetModalOpen && (
        <ManageAssetDialog
          handleModalClose={handleAssetModalClose}
          asset={editRow}
          assetGroups={assetGroups?.assetGroups}
          isLocationSelector={isLocationSelector}
          addToSelectedAssets={addToSelectedAssets}
        />
      )}
      {assetBulkModalOpen && (
        <BulkAssetContextProvider
          handleModalClose={() => setAssetBulkModalOpen(false)}
        >
          <AssetBulkDialog />
        </BulkAssetContextProvider>
      )}
    </>
  )
}

const FooterActionButton: React.FC<{
  isLocationSelector: boolean
  disabled: boolean
  onClick: () => void
}> = ({ isLocationSelector, disabled, onClick }) => {
  if (isLocationSelector) return undefined
  return <EditLocationsButton disabled={disabled} onClick={onClick} />
}

const AffectedByCriticalThreatFilter: React.FC = () => {
  const { affectedByCriticalThreat, setSearchParams, params } =
    useSearchParams()
  const dataGridRef = useGridApiContext()

  useEffect(() => {
    // Apply the filter to the grid here so that it is also applied
    // when navigating here from another page
    affectedByCriticalThreat === TRUE
      ? dataGridRef.current.upsertFilterItem({
          id: "criticalThreat",
          field: "criticalThreat",
          operator: "equals",
          value: "true",
        })
      : dataGridRef.current.deleteFilterItem({
          id: "criticalThreat",
          field: "criticalThreat",
          operator: "equals",
        })
  }, [affectedByCriticalThreat, dataGridRef])

  const onChangeCriticalThreatFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSearchParams({
        ...params,
        affectedByCriticalThreat: checked ? TRUE : undefined,
      })
    },
    [params, setSearchParams]
  )

  return (
    <FormControlLabel
      sx={{
        marginLeft: "-9px",
        marginTop: "2px",
        height: "fit-content",
      }}
      control={
        <Checkbox
          checked={affectedByCriticalThreat === TRUE}
          onChange={onChangeCriticalThreatFilter}
        />
      }
      label="Affected by Critical Threat"
    />
  )
}

const SingleLocationActionButton: React.FC<{
  openAssetModal: () => void
  children?: ReactNode
}> = ({ openAssetModal, children }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <Button
      variant="contained"
      style={{
        marginLeft: isMobile ? 0 : 20,
        marginBottom: 12,
        minWidth: 150,
        height: 40,
        lineHeight: "16px",
      }}
      onClick={() => {
        openAssetModal()
      }}
    >
      {children}
    </Button>
  )
}

const NoRowsActionElement: React.FC<{ openAssetModal: () => void }> = ({
  openAssetModal,
}) => {
  return (
    <NoDataOverlay title="No Saved Locations">
      <AddLocationTextButton onClick={openAssetModal}>
        Add locations
      </AddLocationTextButton>{" "}
      to receive updates and start monitoring risk.
    </NoDataOverlay>
  )
}

const NoResultsActionElement: React.FC<{ openAssetModal: () => void }> = ({
  openAssetModal,
}) => {
  return (
    <NoDataOverlay title="No Locations Found">
      Refine your search parameters or{" "}
      <AddLocationTextButton onClick={openAssetModal}>
        add a location
      </AddLocationTextButton>
    </NoDataOverlay>
  )
}

const NoDataOverlay: React.FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <>
      <NoData
        style={{
          maxWidth: 400,
          height: "auto",
          paddingBottom: 16,
          opacity: 0.6,
        }}
      />
      <Typography variant="body1Bold" color={extraColors.hint}>
        {title}
      </Typography>
      <Typography
        variant="small1"
        color={extraColors.hint}
        sx={{ textAlign: "center", marginTop: "12px" }}
      >
        {children}
      </Typography>
    </>
  )
}

const EditLocationsButton: React.FC<{
  disabled: boolean
  onClick: () => void
}> = ({ disabled, onClick }) => {
  return (
    <EditButton variant="destructive" onClick={onClick} disabled={disabled}>
      Edit Locations
    </EditButton>
  )
}
