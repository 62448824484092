import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import LinkIcon from "@mui/icons-material/Link"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Box, Typography, styled } from "@mui/material"
import type { AlertLevel, Category, Geotag } from "@phc/common"
import type React from "react"
import { Link } from "react-router"
import { dayjsUTC } from "../../../utils/helpers"
import { trackEvent } from "../../../utils/mixpanel"
import { extraColors } from "../../../utils/theme"
import type { JustificationConfidence } from "../../Shared/AlertConfidence"
import { alertColors } from "../../Shared/AlertPill"
import { ConfidenceIndicator } from "../../Shared/ConfidenceIndicator"
import { ThreatCategory } from "../../Shared/ThreatCategory"
import { CardContainer, LeftBorder } from "../LocationCard/Shared"
import { GeotagsContainer } from "./GeotagsContainer"

const ContentContainer = styled("div")({
  display: "flex",
  gap: 12,
  flexDirection: "column",
  width: "100%",
  padding: "12px",
})

const Row = styled("div")({
  display: "grid",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 15,
  gridTemplateColumns: "auto auto",
})

const LinkIconStyled = styled(LinkIcon)({
  fill: extraColors.disabled,
  height: "70%",
  marginRight: -10,
  cursor: "pointer",
  verticalAlign: "middle",
  "&:hover": {
    fill: extraColors.purple,
    transform: "scale(1.1)",
  },
})

const BorderText = styled(Typography)({
  position: "absolute",
  transform: "translate(-3%, 20%)",
  transformOrigin: "left top",
  writingMode: "vertical-rl",
  whiteSpace: "nowrap",
})

export interface IntelligenceCardProps {
  title: string

  date: string
  className?: string
  id: string
  threat: Category["title"]
  geotags: Geotag[] | undefined
  confidence?: JustificationConfidence
  alertLevel?: AlertLevel
  sourceURL?: string
  slug?: string
}

export const IntelligenceCard = ({
  alertLevel,
  title,
  date,
  className,
  sourceURL,
  id,
  threat,
  geotags,
  children,
  confidence,
  slug,
}: IntelligenceCardProps & { children: React.ReactNode }) => {
  const formattedDate = dayjsUTC(date).local().format("MM/D/YY")
  const alertColor = alertLevel && {
    background: alertColors[alertLevel].background,
    text: alertColors[alertLevel].text,
  }

  return (
    <CardContainer className={className}>
      <LeftBorder color={alertColor?.background || extraColors.light} />
      <BorderText>
        {alertColor ? (
          <WarningAmberIcon
            sx={{
              color: alertColor.text,
              fontSize: "18px",
              marginBottom: "8px",
            }}
          />
        ) : (
          <ArticleOutlinedIcon
            sx={{
              color: extraColors.medium,
              fontSize: "18px",
              marginBottom: "8px",
            }}
          />
        )}
        <Typography
          variant="small1"
          color={alertColor?.text || extraColors.medium}
          textTransform="uppercase"
          fontWeight={600}
        >
          {alertLevel?.toLowerCase() ?? "Analysis"}
        </Typography>
      </BorderText>
      <ContentContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="7px"
        >
          <ThreatCategory contentId={id} threat={threat} />
          <Typography variant="small1" color={extraColors.hint}>
            {formattedDate}
          </Typography>
        </Box>
        <GeotagsContainer geotags={geotags} />
        <Row>
          <Typography
            variant="body2Bold"
            component={slug ? Link : "h4"}
            to={
              slug
                ? {
                    pathname: slug,
                  }
                : undefined
            }
          >
            {title}
          </Typography>
        </Row>
        <Box
          style={{
            position: "relative",
          }}
        >
          {children}
        </Box>
        {sourceURL && (
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              justifyContent: "space-between",
              alignItems: "self-end",
              marginTop: "auto",
            }}
          >
            <Link
              to={sourceURL}
              target="_blank"
              onClick={() => {
                trackEvent("CLICK_ALERT_SOURCE", {
                  title,
                  link: sourceURL,
                })
              }}
              style={{
                display: "flex",
              }}
            >
              <Typography
                component="span"
                variant="tiny"
                color={extraColors.hint}
                alignSelf="center"
              >
                SOURCE
              </Typography>
              <LinkIconStyled fontSize="inherit" />
            </Link>
            {confidence && (
              <ConfidenceIndicator
                confidenceInteger={confidence.parsedConfidence}
              />
            )}
          </Box>
        )}
      </ContentContainer>
    </CardContainer>
  )
}
