import { Typography, styled } from "@mui/material"
import type { Confidence } from "@phc-health/connect-query"
import type React from "react"
import { Link, generatePath } from "react-router"
import { ROUTES } from "../../utils/constants"
import { extraColors } from "../../utils/theme"

const MIN_BAR_HEIGHT = 3

const LinkStyled = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  color: extraColors.medium,
  marginRight: 6,
}))

const ConfidenceContainer = styled("div")({
  alignSelf: "flex-end",
  display: "inline-flex",
  alignItems: "baseline",
})

const ConfidenceBar = styled("div")<{
  height: number
  color: string
}>(({ height, color }) => ({
  height,
  backgroundColor: color,
  marginLeft: 2,
  width: 2,
}))

const ConfidenceBarsContainer = styled("div")({
  display: "flex",
  alignItems: "baseline",
})

export const ConfidenceIndicator: React.FC<{
  /** float 0-1, -1 means no data */
  confidence?: Confidence
  /** int 1-5 */
  confidenceInteger?: number
  className?: string
  hideLink?: boolean
  hideText?: boolean
  style?: React.CSSProperties
}> = ({ confidence, className, confidenceInteger, hideLink, style }) => {
  if (!confidence?.score && confidenceInteger == null) return null
  if (confidence?.score === -1) return null

  const score =
    !confidence?.score || confidence.score < 0.2 ? 0.2 : confidence.score
  const confidenceFinal = confidenceInteger ?? Math.trunc((score * 10) / 2)

  return (
    <ConfidenceContainer className={className} style={style}>
      {!hideLink && (
        <LinkStyled
          target="_blank"
          to={generatePath(ROUTES.FAQ_POST, {
            slug: "source-confidence-a-credibility-assessment",
          })}
          sx={{ textDecoration: "none" }}
        >
          <Typography
            variant="tiny"
            sx={{
              textDecoration: "none",
              color: extraColors.hint,
              textTransform: "uppercase",
            }}
          >
            Confidence
          </Typography>
        </LinkStyled>
      )}
      <ConfidenceBars confidence={confidenceFinal} />
    </ConfidenceContainer>
  )
}

const ConfidenceBars: React.FC<{ confidence: number }> = ({ confidence }) => {
  return (
    <ConfidenceBarsContainer>
      {Array.from({ length: 5 }).map((_, idx) => (
        <ConfidenceBar
          key={idx}
          height={MIN_BAR_HEIGHT + idx * 3}
          color={idx < confidence ? extraColors.navy : extraColors.disabled}
        />
      ))}
    </ConfidenceBarsContainer>
  )
}
