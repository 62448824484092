import DOMPurify from "dompurify"

export const processAnswerContent = (htmlContent: string) => {
  // Sanitize the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlContent)
  const parser = new DOMParser()
  const doc = parser.parseFromString(sanitizedHTML, "text/html")
  const h3Elements = doc.querySelectorAll("h3")
  let sourcesList: HTMLUListElement | null = null
  for (const h3 of h3Elements) {
    if (h3.textContent === "Sources") {
      const nextElement = h3.nextElementSibling
      if (nextElement && ["OL", "UL"].includes(nextElement.tagName)) {
        sourcesList = nextElement as HTMLUListElement | HTMLOListElement
        h3.remove()
        nextElement.remove()
        break
      }
    }
  }

  const answerContent = doc.body.innerHTML
  const sources = sourcesList
    ? Array.from(sourcesList.querySelectorAll("li"))
    : []

  return { answerContent, sources }
}
