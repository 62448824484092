import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import {
  gridClasses,
  GridRow,
  useGridApiRef,
  type GridColDef,
} from "@mui/x-data-grid-pro"
import type { Geotag } from "@phc/common"
import type React from "react"
import { useCallback, useEffect, useMemo } from "react"
import { generatePath, Link } from "react-router"
import { BaseGrid } from "../components/BaseGrid/BaseGrid"
import { SelectFilter } from "../components/BaseGrid/SelectFilter"
import { useCapServiceContext } from "../components/CriticalThreats/capServiceContext"
import { MainContent } from "../components/MainContent"
import { GeotagsContainer } from "../components/Map/Cards/GeotagsContainer"
import { PageContainer } from "../components/PageContainer"
import { Tag } from "../components/Shared/Tag"
import { ROUTES } from "../utils/constants"
import { convertStringToTitleCase, dayjsUTC } from "../utils/helpers"
import { extraColors } from "../utils/theme"

const ALL_ANALYSIS_TEXT = "All analysis"

const MobileRowRender = (props: { row: Row }) => {
  const row = props.row
  return (
    <Box display="grid" tabIndex={0} width="100%" gap="10px" padding={"16px"}>
      <Box display="flex" justifyContent="space-between">
        <Tag sx={{ fontSize: 13 }}>{row.category}</Tag>
        <Typography variant="body2" color={extraColors.hint}>
          {dayjsUTC(row.publishedAt).local().format("MM/DD/YYYY")}
        </Typography>
      </Box>
      <Typography variant="body2">{row.title}</Typography>
      <GeotagsContainer geotags={row.geotags} />
    </Box>
  )
}

const makeAnalysisColumns = (isMobile = false) =>
  [
    {
      field: "publishedAt" as const,
      headerName: "Date",
      headerAlign: "left",
      type: "date",
      minWidth: 100,
      display: "flex",
      renderCell: ({ value }) => {
        const date = value as string
        return (
          <Typography variant="body2" sx={{ color: extraColors.hint }}>
            {dayjsUTC(date).local().format("MM/DD/YYYY")}
          </Typography>
        )
      },
    },
    {
      field: "title" as const,
      headerName: "Title",
      minWidth: 200,
      flex: 4,
      display: "flex",
      renderCell: ({ value, row }) => (
        <div>
          <Typography variant="body2" pb={1}>
            {value}
          </Typography>
          <GeotagsContainer geotags={row.geotags} />
        </div>
      ),
    },
    // On mobile, span 2 columns and render mobile view
    {
      field: "category" as const,
      headerName: "Category",
      minWidth: 150,
      flex: 1,
      display: "flex",
      colSpan: isMobile ? 2 : 1,
      renderCell: ({ value, row }) =>
        isMobile ? (
          <MobileRowRender row={row} />
        ) : (
          <Tag sx={{ fontSize: 13 }}>{value}</Tag>
        ),
    },
    { field: "slug" as const, filterable: false },
    {
      field: "geotags" as const,
      filterable: false,
      valueGetter: ({ row }: { row: Row }) =>
        row.geotags.map(g => g.name).join(", "),
    },
  ] satisfies GridColDef<Row>[]

interface Row {
  id: string
  title: string
  publishedAt: Date
  category: string | undefined
  slug: string
  geotags: Geotag[]
}

export const Analyses: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { useAnalyses } = useCapServiceContext()
  const { data, isLoading } = useAnalyses()
  const gridApiRef = useGridApiRef()
  const columns = useMemo(() => makeAnalysisColumns(isMobile), [isMobile])
  useEffect(() => {
    if (isMobile) {
      gridApiRef.current.setColumnIndex("category", 0)
    } else {
      gridApiRef.current.setColumnIndex("category", 2)
    }
  }, [gridApiRef, isMobile])

  const rows = useMemo(
    () =>
      data?.map(item => {
        const category = item.categories
          ? item.categories[0]?.title?.toUpperCase()
          : ""

        //if the _id starts with "drafts." it's a draft
        const isDraft = item._id.startsWith("drafts.")
        //add draft to the title if it's a draft
        const title = isDraft ? `${item.title ?? ""} (Draft)` : item.title

        return {
          id: item._id,
          title: title ?? "",
          publishedAt: new Date(item.publishedAt ?? item.updatedAt ?? ""), // Add a fallback value here
          category,
          slug: item.slug?.current ?? "",
          geotags: item.geotags ?? [],
        } satisfies Row
      }),
    [data]
  )

  // get categories, convert to title case and filter our empty values
  const categoryList = useMemo(
    () =>
      [
        ...new Set(
          rows?.map(row => convertStringToTitleCase(row.category ?? ""))
        ),
      ].filter(val => val !== ""),
    [rows]
  )

  const getRowHeight = useCallback(() => "auto", [])

  return (
    <>
      <MainContent
        headerProps={{
          title: "Analysis",
        }}
        disableLeftGutter
      >
        <PageContainer>
          <BaseGrid
            sx={{
              [`.${gridClasses.row}`]: {
                padding: "9px 0px",
              },
            }}
            apiRef={gridApiRef}
            search={true}
            filter={false}
            checkbox={false}
            columnVisibilityModel={{
              slug: false,
              title: !isMobile,
              geotags: false,
            }}
            loading={isLoading && !data}
            rows={rows ?? []}
            columns={columns}
            getRowHeight={getRowHeight}
            initSortModel={{
              field: "publishedAt",
              sort: "desc",
            }}
            noTextOverlay="No analysis found"
            subNav={
              <SelectFilter
                items={categoryList}
                ALL_ITEMS_TEXT={ALL_ANALYSIS_TEXT}
                filterField={
                  "category" satisfies (typeof columns)[number]["field"]
                }
              />
            }
            striped
            slots={{
              row: props => {
                const row = props.row as Row
                return (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={generatePath(ROUTES.ANALYSIS_POST, {
                      slug: row.slug || "",
                    })}
                  >
                    <GridRow {...props} />
                  </Link>
                )
              },
            }}
          />
        </PageContainer>
      </MainContent>
    </>
  )
}
