import { Typography } from "@mui/material"
import { forwardRef } from "react"
import { Link } from "react-router"
import { ROUTES, stringifySearchParams } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"
import { Tag } from "./CategoryTag"

interface LocationTagProps {
  id: string
  title: string
  subtitle: string
  style?: React.CSSProperties
}

export const LocationTag = forwardRef<HTMLDivElement, LocationTagProps>(
  ({ id, title, subtitle, style }, ref) => {
    return (
      <Tag ref={ref} style={style}>
        <Typography
          component={Link}
          to={{
            pathname: ROUTES.LOCATION_DETAILS,
            search: stringifySearchParams({
              id,
              title,
              subtitle,
            }),
          }}
          variant="tiny"
          color={extraColors.dark}
          sx={{ textDecoration: "none" }}
        >
          {title}
        </Typography>
      </Tag>
    )
  }
)

LocationTag.displayName = "LocationTag"
