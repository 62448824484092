import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import { IconButton, Typography, styled } from "@mui/material"
import type React from "react"
import { Link } from "react-router"
import { ROUTES } from "../../../utils/constants"
import { extraColors } from "../../../utils/theme"
import Button from "../../Shared/Button"
import { getMapBreakpoint } from "../Shared"

const TitleContainer = styled("div")(({ theme }) => ({
  pointerEvents: "all",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  [getMapBreakpoint(theme)]: {
    justifyContent: "unset",
  },
}))

const DrawerButton = styled(IconButton)<{ open: number }>(
  ({ theme, open }) => ({
    alignSelf: "center",
    width: 20,
    height: 20,
    color: extraColors.purple,
    "&:hover": {
      color: extraColors.purpleDark,
      background: "transparent",
    },
    borderRadius: "100%",
    marginLeft: open ? 0 : -10,
    marginTop: open ? 0 : 6,
    [getMapBreakpoint(theme)]: {
      margin: "0px 12px 0px 0px",
    },
  })
)

const ButtonTitleContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}))

const ManageButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2Bold,
  marginRight: "auto",
  paddingLeft: 0,
  textTransform: "uppercase",
  color: extraColors.purple,
  "&:hover": {
    color: extraColors.purpleDark,
  },
  [getMapBreakpoint(theme)]: {
    paddingBottom: 0,
  },
}))

const HeaderTitle = styled("div")({
  display: "flex",
  flexDirection: "row",
})

const HeaderContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  pointerEvents: "all",
})

export const DrawerHeader: React.FC<{
  toggleDrawer: () => void
  open: boolean
}> = ({ toggleDrawer, open }) => {
  return (
    <TitleContainer>
      <ButtonTitleContainer>
        {open && <Typography variant="h3Bold">Watched Locations</Typography>}
        <DrawerButton onClick={toggleDrawer} open={+open}>
          {open ? (
            <OpenIcon isMobile={false} />
          ) : (
            <ClosedIcon isMobile={false} />
          )}
        </DrawerButton>
      </ButtonTitleContainer>
      {open && (
        <ManageButton component={Link} to={ROUTES.ASSETS}>
          Manage
        </ManageButton>
      )}
    </TitleContainer>
  )
}

export const MobileDrawerHeader: React.FC<{
  toggleDrawer: () => void
  open: boolean
  title: string
  hideManage?: boolean
}> = ({ toggleDrawer, open, title, hideManage }) => {
  return (
    <HeaderContainer>
      <HeaderTitle>
        <DrawerButton onClick={toggleDrawer} open={+open}>
          {open ? <OpenIcon isMobile /> : <ClosedIcon isMobile />}
        </DrawerButton>
        <Typography variant="h3Bold" fontWeight={700}>
          {title}
        </Typography>
      </HeaderTitle>
      {!hideManage && (
        <ManageButton component={Link} to={ROUTES.ASSETS}>
          Manage
        </ManageButton>
      )}
    </HeaderContainer>
  )
}

const ClosedIcon: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  if (isMobile) {
    return <KeyboardDoubleArrowUp />
  }

  return <KeyboardDoubleArrowLeftIcon />
}

const OpenIcon: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  if (isMobile) {
    return <KeyboardDoubleArrowDown />
  }

  return <KeyboardDoubleArrowRight />
}
