import { callUnaryMethod } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { useCallback } from "react"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useGetAuth0OrgByEmail() {
  const transport = useTransport(ACCESS_API)
  return useCallback(
    (userId: string) =>
      callUnaryMethod(
        transport,
        AccessManagementServiceQuery.lookupAuth0Organization,
        { userId }
      ),
    [transport]
  )
}
