import { styled } from "@mui/material"
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  type GridSlotProps,
} from "@mui/x-data-grid-pro"
import type React from "react"
import { useCallback } from "react"
import { useSearchParams } from "../../hooks/useSearchParams"
import { extraColors } from "../../utils/theme"
import { SearchInput } from "../Shared/SearchInput"

const ToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
  [theme.breakpoints.down("lg")]: {
    display: "grid",
    gap: "10px",
    width: "100%",
    justifyContent: "unset",
  },
}))

const SearchContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "10px",
  width: "100%",
  gridTemplateColumns: "repeat(auto-fit, minmax(240px, auto))",
  justifyContent: "start",
  [theme.breakpoints.down("md")]: {
    display: "grid",
    gap: "10px",
    width: "100%",
    justifyContent: "unset",
  },
}))

const ToolbarExport = styled(GridToolbarContainer)(() => ({
  minWidth: "70px",
  background: "transparent",
  height: "fit-content",
  borderRadius: 2,
  padding: "0px 30px",
  width: "fit-content",
  marginRight: 12,
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& button": {
    color: extraColors.navy,
  },
}))

const ToolbarFilterButton = styled(GridToolbarFilterButton)(() => ({
  minWidth: "77px",
  background: "transparent",
  color: extraColors.navy,
  border: `1px solid ${extraColors.navy}`,
  padding: "7px 20px",
  "&:hover": {
    backgroundColor: "transparent",
  },
}))

const EndContainer = styled("div")({
  float: "left",
  display: "flex",
  flexDirection: "row",
})

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    placeholder?: string
    filter?: boolean
    search?: boolean
    exported?: boolean
    actionButton?: React.ReactNode
    subNav?: React.ReactNode
  }
}

export const GridCustomToolbar: React.FC<GridSlotProps["toolbar"]> = ({
  placeholder,
  filter,
  search,
  exported,
  actionButton,
  subNav,
}) => {
  const { setSearchParams, params, searchText } = useSearchParams()
  const dataGridRef = useGridApiContext()

  const onChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dataGridRef.current.setQuickFilterValues(
        e.target.value.split(" ").filter(Boolean)
      )
      setSearchParams({
        ...params,
        searchText: e.target.value || undefined,
      })
    },
    [dataGridRef, params, setSearchParams]
  )

  return (
    <ToolbarContainer>
      <SearchContainer>
        {search && (
          <SearchInput
            onChange={onChangeSearch}
            searchText={searchText}
            placeholder={placeholder ? String(placeholder) : ""}
          />
        )}
        {subNav}
        {filter && <ToolbarFilterButton />}
      </SearchContainer>
      <EndContainer>
        {exported && (
          <ToolbarExport>
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{
                allColumns: true,
              }}
            />
          </ToolbarExport>
        )}
        {actionButton && <div>{actionButton}</div>}
      </EndContainer>
    </ToolbarContainer>
  )
}
