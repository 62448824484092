import { CircularProgress, Dialog, Typography, styled } from "@mui/material"

import type React from "react"

import { createConnectQueryKey } from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { useQueryClient } from "@tanstack/react-query"
import PQueue from "p-queue"
import { trackEvent } from "../../../utils/mixpanel"
import { extraColors } from "../../../utils/theme"
import Button from "../../Shared/Button"
import { useRemoveAsset } from "./hooks/useRemoveAsset"

const DialogStyled = styled(Dialog)({
  ".MuiPaper-root": {
    height: "fit-content",
  },
})

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
})

interface RemoveAssetDialogProps {
  assetIds: string[]
  clearRemoveAssetIds: (assetIds: string[]) => void
  onClose: () => void
}

export const RemoveAssetDialog: React.FC<RemoveAssetDialogProps> = ({
  clearRemoveAssetIds,
  assetIds,
  onClose,
}) => {
  const client = useQueryClient()
  const { mutateAsync, isPending } = useRemoveAsset({
    hideToast: true,
    skipQueryInvalidation: true,
  })

  const removeListingsConfirmed = async () => {
    const queue = new PQueue({ concurrency: 100 })
    const promises = assetIds.map(assetId => {
      return async () => {
        await mutateAsync({
          assetId,
        }).catch(console.error)
        trackEvent("MANAGE_LOCATION", {
          action: "delete",
          locationId: assetId,
        })
      }
    })
    await queue.addAll(promises)
    clearRemoveAssetIds(assetIds)
    await client.invalidateQueries({
      queryKey: createConnectQueryKey({
        schema: AssetServiceQuery.listAssets,
        cardinality: "infinite",
      }),
    })

    onClose()
  }

  const isMultiple = assetIds.length > 1
  return (
    <DialogStyled open>
      <Typography variant="h3Bold" style={{ color: extraColors.medium }}>
        Delete location{isMultiple && "s"}
      </Typography>
      <Typography variant="body1" style={{ marginTop: 12 }}>
        Are you sure you want to delete{" "}
        {isMultiple
          ? `these ${assetIds.length.toLocaleString()} locations`
          : "this location"}
        ? You will lose all settings and data.
      </Typography>
      <ButtonContainer>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => clearRemoveAssetIds(assetIds)}
        >
          Cancel
        </Button>
        <Button
          variant="destructive"
          disabled={isPending}
          onClick={() => {
            removeListingsConfirmed().catch(console.error)
          }}
        >
          {isPending ? <CircularProgress size={20} color="info" /> : "Delete"}
        </Button>
      </ButtonContainer>
    </DialogStyled>
  )
}
