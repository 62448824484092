import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../../utils/constants"

export const useRemoveAsset = ({
  hideToast = false,
  skipQueryInvalidation = false,
}: { hideToast?: boolean; skipQueryInvalidation?: boolean } = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const successMessage = "Your location has been removed."
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.removeAsset, {
    transport,
    onError: err => {
      !hideToast &&
        enqueueSnackbar("Unable to remove this location.", {
          variant: "error",
        })
      console.error(err)
    },
    retry: 5,
    onSuccess: () => {
      !hideToast &&
        enqueueSnackbar(successMessage, {
          variant: "success",
        })
    },
    onSettled: async () => {
      if (skipQueryInvalidation) return
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey({
          schema: AssetServiceQuery.listAssets,
          cardinality: "infinite",
        }),
      })
    },
  })
}
