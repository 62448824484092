import { styled } from "@mui/material"
import type React from "react"
import { Navigate } from "react-router"
import Breadcrumbs from "../components/Breadcrumbs"
import { MainContent } from "../components/MainContent"
import OrganizationAccountDetails from "../components/OrganizationAccountDetails"
import OrganizationAccountStatus from "../components/OrganizationAccountStatus"
import { useOrganization } from "../hooks/useOrganization"
import { usePermissions } from "../hooks/usePermissions"
import { useSearchParams } from "../hooks/useSearchParams"
import { ROUTES } from "../utils/constants"

const Cards = styled("div")({
  marginTop: 32,
  display: "grid",
  gap: 64,
})

const ManageOrganization: React.FC = () => {
  const permissions = usePermissions()
  const { organizationName, organizationId } = useSearchParams()
  const organization = useOrganization(organizationId ?? "")

  if (!permissions.isLoading && !permissions.organizations.read) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  return (
    <>
      <MainContent headerProps={{ title: organizationName ?? "" }}>
        <Breadcrumbs
          organizationId={organizationId ?? ""}
          organizationName={organizationName ?? ""}
          currentPage="Manage Organization"
          linkFirst
        />
        <Cards>
          <OrganizationAccountDetails organization={organization.data} />
          <OrganizationAccountStatus organization={organization.data} />
        </Cards>
      </MainContent>
    </>
  )
}

export default ManageOrganization
