import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useCallback } from "react"
import { useBulkAssetContext } from "./BulkAssetUploadContext"
import { useBuildErrorCSV } from "./useBuildErrorCSV"
import { useBulkCreateAssets } from "./useBulkCreateAssets"

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 10,
})

export const BulkDialogControls: React.FC = () => {
  const buildErrorCSV = useBuildErrorCSV()
  const { bulkCreateAssets } = useBulkCreateAssets()
  const {
    completedJobs,
    newAssets,
    handleModalClose,
    setUploadIsComplete,
    preparedAssetErrors,
    clearUpload,
    error,
  } = useBulkAssetContext()

  const onUploadCSV = useCallback(() => {
    bulkCreateAssets().catch(console.error)
    // Create a downloadable CSV file with the bad rows and
    // their error messages to help customers fix their uploads
    buildErrorCSV(preparedAssetErrors)
    setUploadIsComplete(true)
  }, [
    buildErrorCSV,
    bulkCreateAssets,
    preparedAssetErrors,
    setUploadIsComplete,
  ])

  // If there are only errored rows, we still let customers
  // submit the CSV so they can get to the next modal which will give
  // them a downloadable CSV annotated with errors
  const uploadIsDisabled =
    !!error ||
    (!newAssets?.length && !preparedAssetErrors.length) ||
    completedJobs > 0

  return (
    <ButtonContainer>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          clearUpload()
          handleModalClose()
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        disabled={uploadIsDisabled}
        onClick={onUploadCSV}
      >
        Upload CSV
      </Button>
    </ButtonContainer>
  )
}
