import type { ButtonProps } from "@mui/material"
import { Button as MuiButton } from "@mui/material"
import type React from "react"
import type { ForwardRefExoticComponent } from "react"
import type { Path } from "react-router"

const Button: React.FC<
  ButtonProps & {
    component?: ForwardRefExoticComponent<any>
    to?: Omit<Path, "hash"> | string
  }
> = props => <MuiButton {...props} disableRipple />

export default Button
