import {
  AppBar,
  Button,
  Toolbar as MuiToolbar,
  Typography,
  styled,
} from "@mui/material"

import { ArrowBackIos } from "@mui/icons-material"
import type { CriticalThreatWithReferences } from "@phc/common"
import type React from "react"
import type { ReactNode } from "react"
import { useNavigate } from "react-router"
import { extraColors } from "../../utils/theme"
import { CriticalThreatTag } from "../CriticalThreats/CriticalThreatTag"
import { UserNavigation } from "./UserNavigation"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: extraColors.subtle,
  [theme.breakpoints.down("md")]: {
    paddingBottom: 10,
  },
}))

const Toolbar = styled(MuiToolbar)(() => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  "&": { padding: 0 },
}))

const ToolbarContainer = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}))

const TitleText = styled("span")(({ theme }) => ({
  ...theme.typography.h2Bold,
  display: "flex",
  gap: 8,
  alignItems: "center",
}))

const TitleBackContainer = styled("div")({
  display: "flex",
})

const ButtonStyled = styled(Button)(({ theme }) => ({
  ...theme.typography.small1Bold,
  padding: 0,
  minWidth: "unset",
  transition: "transform 250ms ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}))

const TitleRow = styled("div")({
  display: "flex",
})

const CriticalThreatTagStyled = styled(CriticalThreatTag)({
  marginLeft: 10,
  alignSelf: "center",
  height: 28,
  width: 28,
})

export interface HeaderProps {
  title: ReactNode
  documentTitle?: string
  subtitle?: string
  showBackButton?: boolean
  relatedCriticalThreat?: CriticalThreatWithReferences
}

export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  showBackButton,
  relatedCriticalThreat,
}) => {
  const navigate = useNavigate()
  return (
    <StyledAppBar
      position="static"
      elevation={0}
      style={{
        background: extraColors.subtle,
      }}
      data-testid="header"
    >
      <Toolbar color="white">
        <TitleBackContainer>
          {showBackButton && (
            <ButtonStyled onClick={() => void navigate(-1)}>
              <ArrowBackIos
                sx={{ color: extraColors.purpleMiddle, height: 20 }}
              />
            </ButtonStyled>
          )}
          <Typography variant="h2Bold">
            <TitleRow>
              <TitleText>{title}</TitleText>
              <CriticalThreatTagStyled
                relatedCriticalThreat={relatedCriticalThreat}
                size="large"
              />
            </TitleRow>
            <Typography
              variant="body1"
              textTransform="uppercase"
              color={extraColors.medium}
              fontWeight={500}
            >
              {subtitle}
            </Typography>
          </Typography>
        </TitleBackContainer>
        <ToolbarContainer>
          <UserNavigation />
        </ToolbarContainer>
      </Toolbar>
    </StyledAppBar>
  )
}
