import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { styled, Typography } from "@mui/material"
import { AssetType } from "@phc-health/connect-query"
import { useState } from "react"
import { enumKeys } from "../../../../utils/helpers"
import { extraColors } from "../../../../utils/theme"
import { assetCSVSchema } from "./assetSchema"

const DetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: extraColors.subtle,
  borderRadius: 6,
  marginTop: 18,
})

const DetailsHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  background: extraColors.subtle,
  color: extraColors.blueMiddle,
  padding: "4px 4px 4px 8px",
  borderRadius: 6,
  alignItems: "center",
})

const SchemaColumnDetailsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 12px 12px 12px",
}))

const ColumnValuesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
})

export const CSVSchemaDetails: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <DetailsContainer>
      <DetailsHeader
        onClick={() => setIsExpanded(prev => !prev)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="body1Bold" sx={{ color: extraColors.blueMiddle }}>
          Template Columns
        </Typography>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </DetailsHeader>
      {isExpanded && <SchemaColumnDetails />}
    </DetailsContainer>
  )
}

const SchemaColumnDetails = () => {
  return (
    <SchemaColumnDetailsContainer>
      <Typography variant="body1Bold" sx={{ margin: "12px 0 4px" }}>
        Column Names
      </Typography>
      <AssetRowColumnNames />
      <Typography variant="body1Bold" sx={{ margin: "12px 0 4px" }}>
        Column Values
      </Typography>
      <AssetRowColumnValues />
    </SchemaColumnDetailsContainer>
  )
}

const AssetRowColumnNames = () => {
  return (
    <Typography
      variant="body2"
      style={{
        fontFamily: "monospace",
        background: "#ccc",
        padding: "1px 8px",
        marginBottom: "12px",
      }}
    >
      {Object.keys(assetCSVSchema.shape).join(", ")}
    </Typography>
  )
}

const AssetRowColumnValues = () => {
  const rowShape = assetCSVSchema.shape

  return (
    <ColumnValuesContainer>
      <ColumnTitleDesc
        title={rowShape.Name._def.description}
        desc="Unique name for the location"
      />
      <AssetTypeList />
      <ColumnTitleDesc
        title={rowShape.Address._def.description}
        desc="Full address"
      />
      <ColumnTitleDesc
        title={rowShape.Latitude._def.description}
        desc="Number between -90 and 90"
      />
      <ColumnTitleDesc
        title={rowShape.Longitude._def.description}
        desc="Number between -180 and 180"
      />
      <ColumnTitleDesc
        title={rowShape["Threat Radius"]._def.description}
        desc="Number (in miles)"
      />
      <ColumnTitleDesc
        title="Groups"
        desc="Comma separated list of existing asset group names"
      />
    </ColumnValuesContainer>
  )
}

const ColumnTitleDesc: React.FC<{
  title: string | undefined
  desc?: string
}> = ({ title, desc }) => (
  <Typography variant="body2">
    <Typography
      variant="body2Bold"
      display="inline"
      sx={{ color: extraColors.blueMiddle }}
    >
      {title}:
    </Typography>{" "}
    {desc}
  </Typography>
)

const AssetTypeList = () => {
  return (
    <>
      <ColumnTitleDesc title={assetCSVSchema.shape.Type._def.description} />
      <ul
        style={{
          maxHeight: 95,
          overflow: "auto",
          marginTop: 0,
        }}
      >
        {enumKeys(AssetType)
          .sort()
          .map(type => (
            <Typography
              component="li"
              key={type}
              variant="body2"
              fontFamily="monospace"
              sx={{
                background: "#ddd",
                width: "fit-content",
                margin: "2px 0",
              }}
            >
              {type}
            </Typography>
          ))}
      </ul>
    </>
  )
}
