import { create } from "@bufbuild/protobuf"
import type { Asset, AssetGroup } from "@phc-health/connect-query"
import {
  AssetGroupSchema,
  AssetSchema,
  AssetType,
  GeotagSchema,
} from "@phc-health/connect-query"
import { getIdsFromGeocoderResult, getMapboxGeocodeResult } from "@phc/common"
import { MAPBOX_ACCESS_TOKEN } from "../../../../utils/env"
import { convertGeocoderResultToLocation } from "../../../../utils/helpers/assetHelper"
import type { AssetCSVRow } from "./assetSchema"

export const LOCAL_STORAGE_PREPARED_ASSETS_KEY = "preparedAssets"
export const BULK_ASSET_GROUP = "Bulk"

interface PreparedAsset {
  asset?: Asset
  csvAsset: AssetCSVRow
  error?: string
}

export const makeAssetFromCSVAsset = async (
  csvAsset: AssetCSVRow
): Promise<PreparedAsset> => {
  const existingAsset = findAssetInLocalStorage(csvAsset)
  if (existingAsset) {
    return { asset: existingAsset, error: undefined, csvAsset }
  }

  let csvAssetMapboxLocation
  try {
    csvAssetMapboxLocation = await reverseGeocodeToPHCLocation(csvAsset)
    if (!csvAssetMapboxLocation)
      throw new Error(
        `No matching location found for ${csvAsset.Name || "unnamed location"}`
      )
  } catch (err) {
    let error
    if (err instanceof Error) {
      error = err.message
    }

    return {
      csvAsset,
      error: error || (err as string),
    }
  }

  const newAsset = create(AssetSchema, {
    name:
      csvAsset.Name ||
      csvAssetMapboxLocation.mapboxLocation?.text ||
      csvAssetMapboxLocation.displayName,
    assetTypes: csvAsset.Type
      ? [csvAsset.Type]
      : [AssetType.ASSET_TYPE_UNSPECIFIED],
    baseEvent: {
      geotags: [csvAssetMapboxLocation],
    },
    assetGroups: getGroups(csvAsset),
    threatRadius: csvAsset["Threat Radius"],
  })

  updateLocalStorage(newAsset)

  return { asset: newAsset, error: undefined, csvAsset }
}

// Look for exact match in local storage to potentially skip geocoding request.
// If the asset is using an address instead of lat/lng, we have to geocode no matter what in order to
// diff place names in comparable format, so in that case, skip this check.
const findAssetInLocalStorage = (csvAsset: AssetCSVRow) => {
  const localStorageAssets = getLocalStorageAssets()

  if (csvAsset.Address || !localStorageAssets) return

  const groups = getGroups(csvAsset)
  const existingGroupNames = sortGroupNames(groups)
  return localStorageAssets.find((a: Asset) => {
    const namesMatch = a.name === csvAsset.Name
    const groupNamesMatch = existingGroupNames.localeCompare(
      sortGroupNames(a.assetGroups)
    )
    const assetTypesMatch = csvAsset.Type === a.assetTypes[0]
    const threatRadiiMatch = a.threatRadius === csvAsset["Threat Radius"]

    if (!csvAsset.Longitude || !csvAsset.Latitude) {
      throw new Error(
        "Latitude and Longitude required if Address is not provided"
      )
    }

    const centroidsMatch =
      a.baseEvent?.geotags[0]?.wktCentroid ===
      `POINT (${csvAsset.Longitude} ${csvAsset.Latitude})`

    return (
      namesMatch &&
      groupNamesMatch &&
      assetTypesMatch &&
      threatRadiiMatch &&
      centroidsMatch
    )
  })
}

const updateLocalStorage = (newAsset: Asset) => {
  // save newAsset to local storage so we don't have to re-geocode in case location is re-uploaded
  const localStorageAssets2 = window.localStorage.getItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY
  )
  const assets = localStorageAssets2
    ? (JSON.parse(localStorageAssets2) as Asset[])
    : []
  // replace existing asset if it exists (in case edits were made with new upload)
  const assetIdx = assets.findIndex((a: Asset) => a.name === newAsset.name)
  if (assetIdx > -1) {
    assets[assetIdx] = newAsset
  } else {
    assets.push(newAsset)
  }
  window.localStorage.setItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY,
    JSON.stringify(assets)
  )
}

const getLocalStorageAssets = () => {
  const localStorageAssets = window.localStorage.getItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY
  )
  return localStorageAssets
    ? (JSON.parse(localStorageAssets) as Asset[])
    : undefined
}

const reverseGeocodeToPHCLocation = async (asset: AssetCSVRow) => {
  if (!(asset.Longitude && asset.Latitude) && !asset.Address) return undefined

  const isAddress = asset.Address
  const query = asset.Address
    ? asset.Address
    : `${asset.Longitude || ""},${asset.Latitude || ""}`

  const result = await getMapboxGeocodeResult({
    query,
    accessToken: MAPBOX_ACCESS_TOKEN,
    types: "address,country,region,district,place",
  })

  if (result?.features.length === 0 || !result?.features[0]) {
    throw new Error(`No matching location found for ${query}`)
  }
  const [feature] = await getIdsFromGeocoderResult(
    { result: result.features[0] },
    MAPBOX_ACCESS_TOKEN
  )

  if (!feature) {
    throw new Error(`No matching location feature found for ${query}`)
  }

  const location = convertGeocoderResultToLocation({
    result: result.features[0],
  })

  // remove potentially wrong geocoding data
  location.address = ""

  const updatedCentroid =
    asset.Longitude && asset.Latitude
      ? `POINT (${asset.Longitude} ${asset.Latitude})`
      : feature.properties.centroid

  return create(GeotagSchema, {
    locationId: feature.properties.location_code,
    wktCentroid: isAddress ? feature.properties.centroid : updatedCentroid,
    mapboxLocation: location,
  })
}

// Try to match existing groups, otherwise create new ones
// Also add the "Bulk" group to every bulk-uploaded asset
const getGroups = (asset: AssetCSVRow): AssetGroup[] => {
  const groups = asset.Groups.concat(BULK_ASSET_GROUP)
    .map(g => create(AssetGroupSchema, { name: g })) // dedupe by group name
    .filter((group, idx, arr) => {
      return arr.findIndex(g => g.name === group.name) === idx
    })
  return groups
}

const sortGroupNames = (groups: AssetGroup[]) => {
  return groups
    .map(g => g.name)
    .sort()
    .join("")
}
