import {
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"

import type React from "react"

import { useListAssetGroups, useListAssets } from "../../hooks/useAssetService"

import { styled } from "@mui/material/styles"
import { UploadMode, useBulkAssetContext } from "./BulkAssetUploadContext"
import { BulkDialogControls } from "./BulkDialogControls"
import { CSVDropzone } from "./CSVDropzone"
import { CSVSchemaDetails } from "./CSVSchemaDetails"
import { FileDownloadLink } from "./FileDownloadLink"
import { FileUploadResults } from "./FileUploadResults"
import { GroupCleanerSBX } from "./GroupCleanerSBX"
import CSV_PATH from "./location_bulk_upload_template.csv?url"
import { FileUploadDetails } from "./UploadedFileDetails"
import { useBulkCreateAssets } from "./useBulkCreateAssets"
import { useParseLocationsFromCSV } from "./useParseLocationsFromCSV"

const StyledContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 8,
  padding: "8px 0",
  maxWidth: 400,
})

const DialogStyled = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    height: "fit-content",
    maxHeight: "100%",
    width: "fit-content",
    maxWidth: 600,
  },
})) as typeof Dialog

export const AssetBulkDialog: React.FC = () => {
  const { isLoading, setIsLoading, handleModalClose, uploadIsComplete } =
    useBulkAssetContext()

  const existingAssets = useListAssets({
    includeGlobal: false,
    excludeGroups: true,
    excludeNotifications: true,
  })
  const { isFetching: groupsAreFetching } = useListAssetGroups()
  const { bulkIsPending, removeIsPending } = useBulkCreateAssets()

  const isComponentLoading =
    existingAssets.isFetching ||
    groupsAreFetching ||
    bulkIsPending ||
    removeIsPending ||
    isLoading

  const { handleCSV } = useParseLocationsFromCSV({
    assets: existingAssets.data.assets,
    isComponentLoading,
  })

  if (uploadIsComplete) {
    return <FileUploadResults />
  }

  return (
    <DialogStyled
      open
      onClose={handleModalClose}
      aria-labelledby={"asset-modal-title"}
    >
      <Typography variant="h3Bold">Bulk upload locations</Typography>
      <StyledContent>
        <UploadModeRadioGroup />
        <CSVDropzone
          handleCSV={handleCSV}
          setIsLoading={setIsLoading}
          isPending={bulkIsPending || removeIsPending}
        />
        <FileDownloadLink path={CSV_PATH} />
        <FileUploadDetails />
        <CSVSchemaDetails />
      </StyledContent>
      <GroupCleanerSBX />
      <BulkDialogControls />
    </DialogStyled>
  )
}

const UploadModeRadioGroup: React.FC = () => {
  const { uploadMode, setUploadMode, clearUpload } = useBulkAssetContext()
  return (
    <RadioGroup
      value={uploadMode}
      name="upload mode"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadMode(event.currentTarget.value as UploadMode)
        clearUpload()
      }}
      sx={{
        padding: "1px",
      }}
    >
      <FormControlLabel
        value={UploadMode.APPEND}
        control={<Radio />}
        label="Add locations to existing list"
      />
      <FormControlLabel
        value={UploadMode.SYNC}
        control={<Radio />}
        label="Replace ALL existing locations with uploaded list"
      />
    </RadioGroup>
  )
}

/**
 * Error geo coding:
 * [
    "Error: No feature found for: -122.464936,37.673739",
    "Error: No feature found for: -122.217683,37.475146",
    "Error: No feature found for: -122.416424,37.636877",
    "Error: No feature found for: -122.283784,37.558918",
    "Error: No feature found for: -122.470173,37.670553",
    "Error: No feature found for: -122.135466,37.460057",
    "Error: No feature found for: -122.48288,37.70032",
    "Error: No feature found for: -121.774363,36.915542",
    "Error: No feature found for: -121.968761,36.974784",
    "Error: No feature found for: -122.031931,37.048364",
    "Error: No feature found for: -87.126274,30.601174",
    "Error: No feature found for: -75.672877,36.037805",
    "Error: No feature found for: -70.347774,43.634242",
    "Error: No feature found for: -122.42,37.63539"
]
 */
