import { ButtonBase, styled, Typography } from "@mui/material"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { extraColors } from "../../utils/theme"
import { AskResult } from "./AskResult"
import { useAskHistory, useIsAsking, type AskHistoryEntry } from "./useAskApi"

const Content = styled("div")(({ theme }) => ({
  display: "grid",
  gap: 24,
  gridTemplateColumns: "auto 1fr",
  height: "100%",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}))

const HistoryContainer = styled("div")(({ theme }) => ({
  maxWidth: 280,
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}))

const HistoryEntryButton = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  ...theme.typography.small1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  display: "block",
  padding: "6px 12px",
  height: "30px",
  background: selected ? extraColors.purpleSubtle : extraColors.subtle,
  textAlign: "left",
  fontFamily: "inherit",
  ["&:hover"]: {
    background: extraColors.purpleSubtle,
  },
}))

export const AskHistory = () => {
  const isMobile = useIsMobile()
  const { askHistory } = useAskHistory()
  const [selectedHistoryISO, setSelectedHistoryISO] = useState<string>()
  const selectedHistory = useMemo(
    () =>
      (selectedHistoryISO &&
        askHistory.find(h => h.date === selectedHistoryISO)) ||
      // default to most recent query
      [...askHistory].reverse()[0],
    [askHistory, selectedHistoryISO]
  )
  const historyByDayMap = [...askHistory]
    .reverse()
    .reduce<Record<string, AskHistoryEntry[]>>((acc, curr) => {
      const date = new Date(curr.date).toLocaleDateString()
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(curr)
      return acc
    }, {})
  const sortedByDate = Object.entries(historyByDayMap).sort(
    ([a], [b]) => new Date(b).getTime() - new Date(a).getTime()
  )

  // reset selectedHistory when asking
  const isAsking = useIsAsking()
  useEffect(() => {
    if (isAsking) {
      setSelectedHistoryISO(undefined)
    }
  }, [isAsking])
  return (
    <Content>
      <HistoryContainer>
        <Typography
          variant="body2Bold"
          display="block"
          color={extraColors.medium}
          mb={1}
        >
          History
        </Typography>
        {sortedByDate.map(([day, entries]) => (
          <div
            key={day}
            style={{
              display: "grid",
              gap: 6,
              marginBottom: 12,
            }}
          >
            <Typography
              variant="small1"
              color={extraColors.hint}
              display="block"
            >
              {day}
            </Typography>
            {entries.map(({ date, ragResponse: result }) => (
              <Fragment key={date}>
                <HistoryEntryButton
                  selected={!isAsking && selectedHistory?.date === date}
                  onClick={() => {
                    setSelectedHistoryISO(date)
                  }}
                >
                  {result?.rag_context.query}
                </HistoryEntryButton>
                {isMobile && selectedHistory?.date === date && (
                  <AskResult selectedHistory={selectedHistory} />
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </HistoryContainer>
      {!isMobile && selectedHistory && (
        <AskResult selectedHistory={selectedHistory} />
      )}
    </Content>
  )
}
