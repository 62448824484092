import * as Papa from "papaparse"
import type { z } from "zod"
import { useBulkAssetContext } from "./BulkAssetUploadContext"
import { assetSchemaArray, type AssetCSVRow } from "./assetSchema"

export const useParseBulkCSV = () => {
  const { setTotalUploadedRowCount, setPreparedAssetErrors } =
    useBulkAssetContext()

  const parseBulkCSV = async (file?: File) => {
    if (!file) return

    const { result, json } = await parseCSVToSchema(file)

    setTotalUploadedRowCount(json.data.length)

    if (result.success) {
      return result.data
    }

    const { errorsByRow } = processParsingErrors(result.error.errors, json)

    setPreparedAssetErrors(prev =>
      prev.concat(Array.from(errorsByRow.values()))
    )

    const rowsWithoutErrors = json.data.filter(
      (_, idx) => !errorsByRow.get(idx + 2)
    )

    // If all of the rows have errors, no need to try to parse good rows out
    if (!rowsWithoutErrors.length) return

    // Take the rows without errors and try to parse them so we can upload them
    const errorFreeResult = assetSchemaArray.safeParse(rowsWithoutErrors)

    if (errorFreeResult.success) {
      return errorFreeResult.data
    }

    throw new Error("Unable to generate error details")
  }

  return { parseBulkCSV }
}

const parseCSVToSchema = async (file: File) => {
  const text = await file.text()
  const json = Papa.parse(text, {
    header: true,
    skipEmptyLines: true,
  })

  const result = assetSchemaArray.safeParse(json.data)
  return { result, json }
}

const processParsingErrors = (
  errors: z.ZodIssue[],
  json: Papa.ParseResult<unknown>
) => {
  const errorsByRow = new Map<number, AssetCSVRow>()
  errors.forEach(e => {
    const badIdx = e.path[0]
    const badField = e.path[1] as keyof AssetCSVRow
    if (typeof badIdx !== "number" || typeof badField !== "string") {
      throw new Error(`Bad path - idx: ${badIdx ?? ""}, field: ${badField}`)
    }
    const badRow = json.data[badIdx] as AssetCSVRow
    console.warn(e)

    const errorMsg = `column "${badField}", received "${JSON.stringify(
      badRow[badField]
    )}": ${e.message}`

    errorsByRow.set(badIdx + 2, {
      ...badRow,
      Error: errorMsg,
    })
  })

  return { errorsByRow }
}
