import Button from "@mui/material/Button"
import PQueue from "p-queue"
import type React from "react"
import {
  useListAssetGroups,
  useRemoveAssetGroup,
} from "../../hooks/useAssetService"

export const GroupCleanerSBX: React.FC = () => {
  const {
    data,
    isFetching: groupsAreFetching,
    isLoading: groupsAreLoading,
    isRefetching,
  } = useListAssetGroups()
  const { mutateAsync: removeGroup, isPending } = useRemoveAssetGroup({
    hideToast: true,
  })

  const isLoading =
    isPending || groupsAreFetching || groupsAreLoading || isRefetching

  if (
    import.meta.env.ENV !== "sbx" ||
    (!isLoading && !data?.assetGroups.length)
  ) {
    return
  }

  return (
    <Button
      variant="destructive"
      sx={{ width: "fit-content" }}
      onClick={() => {
        if (!data?.assetGroups) return
        const queue = new PQueue({
          concurrency: 100,
        })
        for (const group of data.assetGroups) {
          queue
            .add(() =>
              removeGroup({
                assetGroupId: group.assetGroupId,
              })
            )
            .catch(console.error)
        }
      }}
      disabled={isLoading}
    >
      Clean Groups
    </Button>
  )
}
