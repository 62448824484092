import { styled } from "@mui/material"
import type React from "react"
import { Link, Navigate } from "react-router"
import UserTable from "../components/AccountManagement/UserTable"
import { MainContent } from "../components/MainContent"
import { PageContainer } from "../components/PageContainer"
import Button from "../components/Shared/Button"
import { usePermissions } from "../hooks/usePermissions"
import { useSearchParams } from "../hooks/useSearchParams"
import { ROUTES } from "../utils/constants"
import { theme } from "../utils/theme"

const TitleContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 27,
})

const Users: React.FC = () => {
  const { organizationName } = useSearchParams()
  const permissions = usePermissions()

  if (
    !permissions.isLoading &&
    !(
      permissions.organizations.users.read ||
      permissions.currentUser.organization.users.read
    )
  ) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  return (
    <>
      <MainContent headerProps={{ title: organizationName ?? "" }}>
        <PageContainer>
          <TitleContainer>
            <Button
              component={Link}
              to={ROUTES.MANAGE_ORGANIZATION}
              sx={{
                ...theme.typography.small1Bold,
              }}
              variant="outlined"
              color="secondary"
              className="row-control-remove-button"
              disabled={!permissions.organizations.read}
            >
              VIEW ORGANIZATION {">"}
            </Button>
          </TitleContainer>
          <UserTable />
        </PageContainer>
      </MainContent>
    </>
  )
}

export default Users
