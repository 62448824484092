import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"
import { styled, Typography } from "@mui/material"
import type React from "react"
import { extraColors } from "../../../../utils/theme"
import Button from "../../../Shared/Button"
import { DialogStyled } from "../Shared"
import { useBulkAssetContext } from "./BulkAssetUploadContext"

const LinkStyled = styled("a")({
  color: extraColors.purple,
  fontWeight: 600,
  textDecoration: "none",
  cursor: "pointer",
})

const DownloadContainer = styled("div")({
  display: "inline",
})

const UploadResultContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
  padding: 8,
  borderRadius: 3,
  gap: 4,
})

const UploadDetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
})

const SuccessContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const CheckCircleOutlineStyled = styled(CheckCircleOutline)({
  height: "16px",
  color: extraColors.status.greenDark,
  marginRight: "-4px",
  alignSelf: "center",
})

const SettingsText = styled(Typography)({
  fontStyle: "italic",
  color: extraColors.medium,
  marginBottom: "8px",
})

const ErrorOutlineStyled = styled(ErrorOutline)({
  height: "16px",
  color: extraColors.status.redDark,
  marginRight: "-4px",
  alignSelf: "center",
})

export const FileUploadResults: React.FC = () => {
  const { handleModalClose } = useBulkAssetContext()

  return (
    <DialogStyled open aria-labelledby={"asset-modal-title"}>
      <UploadDetailsContainer>
        <Typography variant="h3Bold">Bulk upload locations</Typography>

        <Typography variant="body1Bold" sx={{ color: extraColors.medium }}>
          File Results:
        </Typography>

        <SuccesfulUploadResults />
        <FailedUploadResults />

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            handleModalClose()
          }}
          sx={{ width: "100px", alignSelf: "end", marginTop: "16px" }}
        >
          Close
        </Button>
      </UploadDetailsContainer>
    </DialogStyled>
  )
}

const SuccesfulUploadResults: React.FC = () => {
  const { newAssets } = useBulkAssetContext()

  if (!newAssets?.length) return

  return (
    <SuccessContainer>
      <SuccesfulUploadStats />
      <SettingsText variant="small1">
        Your current notifications settings have been applied to your new
        locations.
      </SettingsText>
    </SuccessContainer>
  )
}

const SuccesfulUploadStats: React.FC = () => {
  const { totalUploadedRowCount, newAssets } = useBulkAssetContext()

  if (!newAssets?.length) return

  return (
    <UploadResultContainer
      sx={{
        border: `1px solid ${extraColors.status.green}`,
        backgroundColor: extraColors.status.greenSubtle,
      }}
    >
      <CheckCircleOutlineStyled />
      <Typography variant="body2Bold">
        {`${newAssets.length}/${totalUploadedRowCount} `}
      </Typography>
      <Typography variant="body2">
        {`  locations were successfully added to your watched locations.`}
      </Typography>
    </UploadResultContainer>
  )
}

const FailedUploadResults: React.FC = () => {
  const { preparedAssetErrors } = useBulkAssetContext()

  if (!preparedAssetErrors.length) return

  return (
    <>
      <FailedUploadStats />
      <DownloadContainer>
        <DownloadErrorCSVLink />
        <Typography
          variant="body2"
          sx={{ color: extraColors.medium }}
          display={"inline"}
        >
          containing the locations with errors. Fix the errors in the file and
          re-upload the CSV to finish adding your locations.
        </Typography>
      </DownloadContainer>
    </>
  )
}

const FailedUploadStats: React.FC = () => {
  const { preparedAssetErrors, totalUploadedRowCount } = useBulkAssetContext()

  return (
    <UploadResultContainer
      sx={{
        border: `1px solid ${extraColors.status.redLight}`,
        backgroundColor: extraColors.status.redSubtle,
      }}
    >
      <ErrorOutlineStyled />
      <Typography variant="body2Bold">
        {`${preparedAssetErrors.length}/${totalUploadedRowCount} `}
      </Typography>
      <Typography variant="body2">
        {`  locations failed to be added due to errors in the CSV file.`}
      </Typography>
    </UploadResultContainer>
  )
}

const DownloadErrorCSVLink: React.FC = () => {
  const { downloadableErrorFile } = useBulkAssetContext()

  return (
    <LinkStyled
      onClick={() => downloadErrorFile(downloadableErrorFile)}
      target="_blank"
      rel="noreferrer"
    >
      Download this CSV{" "}
    </LinkStyled>
  )
}

const downloadErrorFile = (downloadableErrorFile?: Blob) => {
  if (!downloadableErrorFile) return
  const url = URL.createObjectURL(downloadableErrorFile)
  const a = document.createElement("a")
  a.href = url
  a.download = "locationErrors"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}
